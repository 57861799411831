import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { ToastService } from 'ng-uikit-pro-standard';
import { ECalendarValue, IDatePickerDirectiveConfig, IDay } from 'ng2-date-picker';
import { InsuranceType } from 'src/app/Enums/insurance.enum';
import { SubSink } from 'src/app/SubSink';
import { TwocChatService } from 'src/app/core/2c-chat.service';
import { PatientsService } from 'src/app/core/Patient/patients.service';
import { AppDataService } from 'src/app/core/app-data.service';
import { CcmDataService } from 'src/app/core/ccm-data.service';
import { EmitEvent, EventBusService, EventTypes } from 'src/app/core/event-bus.service';
import { SecurityService } from 'src/app/core/security/security.service';
import { TwoCTimezoneService } from 'src/app/core/two-ctime-zone.service';
import { PatientDto } from 'src/app/model/Patient/patient.model';
import { PatinetCommunicationGroup } from 'src/app/model/PatientEngagement/communication.model';
import { tcmStatus2Enum } from 'src/app/model/Tcm/tcm.enum';
import { AddTcmEncounterDto } from 'src/app/model/Tcm/tcm.model';
import { HttpResError } from 'src/app/model/common/http-response-error';
import { TcmDataService } from 'src/app/tcm-data.service';

@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.scss']
})
export class PatientDetailsComponent implements OnInit {
  @Input() showEmrActionWidget = false;
  @Input() hideChatBtn = false;
  @Input() isPatientSummaryView = false;
  @Input() isHesServiceView= false;
  followUpDataObj = {
    patientId: 0,
    followUpDate: '',
    recentPcpAppointment: '',
    recentHospitalizationDate: '',
    lastTcmStatus: tcmStatus2Enum.None,
    lastTcmEncounterId: 0,
  };
  PatientData: any;
  PatientAge: number;
  PatientId: number;
  private subs = new SubSink();
  gettingChatGroup: boolean;
  selectedDate: string;
  insuranceTypeEnum = InsuranceType;
  @Output() patientInfoEmitter: EventEmitter<PatientDto> = new EventEmitter<PatientDto>();
  public datePickerConfig2: IDatePickerDirectiveConfig = {
    allowMultiSelect: false,
    returnedValueType: ECalendarValue.StringArr,
    format: "MM/DD/YYYY"
  };
  isUpdatingPatient= false;
  haveGenerativeAIService= false;
  addTcmEncounterDto = new AddTcmEncounterDto();
  creatingTcm: boolean;
  constructor(private route: ActivatedRoute,
    private patientsService: PatientsService,
    private twocChatService: TwocChatService,
    private securityService: SecurityService,
    private eventBus: EventBusService,
    private tcmSevice: TcmDataService,
    private appData: AppDataService,
    private router: Router,
    private ccmService: CcmDataService,
    @Inject(ToastService) private toaster: ToastService,
    ) { }

  ngOnInit() {
    this.haveGenerativeAIService = this.securityService.hasClaim('GenerativeAIService');
    this.PatientId = +this.route.snapshot.paramMap.get('id');
    if (!this.PatientId) {
      this.PatientId = +this.route.pathFromRoot[3].snapshot.paramMap.get("id");
    }
    this.getFollowUpDate();
    this.getPatientDetail();
  }
  getPatientDetail() {
    if (this.PatientId) {
      this.subs.sink = this.patientsService
        .getPatientDetail(this.PatientId)
        .subscribe(
          (res: any) => {
            if (res) {
              this.appData.summeryViewPatient = res;
              res.dateOfBirth = res.dateOfBirth.slice(0, 10);
              this.PatientData = res;
              
              if(this.PatientData.lastCcmDate){
                this.PatientData.lastCcmDate =  moment(this.PatientData.lastCcmDate).format('MM/DD/YYYY hh:mm A');
              }
              if(this.PatientData.lastAppLaunchDate){
                this.PatientData.isActiveMobileUser = false;
                this.PatientData.lastAppLaunchDate =  moment.utc(this.PatientData.lastAppLaunchDate).tz(TwoCTimezoneService?.currentTimeZone || moment.tz.guess()).format('MM/DD/YYYY hh:mm A');
                const today = moment();
                var duration =today.diff(this.PatientData.lastAppLaunchDate, 'days');
                if(duration < 30){
                  this.PatientData.isActiveMobileUser = true;
                }
                this.PatientData.lastAppLaunchDate =  moment.utc(this.PatientData.lastAppLaunchDate).tz(TwoCTimezoneService?.currentTimeZone || moment.tz.guess()).format('D MMM YY,\\ h:mm A');
              }
              if (this.PatientData.homePhone) {
                this.PatientData.homePhone = this.PatientData.homePhone.replace(
                  /^(\d{0,3})(\d{0,3})(\d{0,4})/,
                  '($1)$2-$3'
                );
              }
              if (this.PatientData.personNumber) {
                this.PatientData.personNumber = this.PatientData.personNumber.replace(
                  /^(\d{0,3})(\d{0,3})(\d{0,4})/,
                  '($1)$2-$3'
                );
              }
              if (this.PatientData.emergencyContactPrimaryPhoneNo) {
                this.PatientData.emergencyContactPrimaryPhoneNo = this.PatientData.emergencyContactPrimaryPhoneNo.replace(
                  /^(\d{0,3})(\d{0,3})(\d{0,4})/,
                  '($1)$2-$3'
                );
              }
              if (this.PatientData.emergencyContactSecondaryPhoneNo) {
                this.PatientData.emergencyContactSecondaryPhoneNo = this.PatientData.emergencyContactSecondaryPhoneNo.replace(
                  /^(\d{0,3})(\d{0,3})(\d{0,4})/,
                  '($1)$2-$3'
                );
              }
              this.PatientAge = this.calculateAge(
                this.PatientData.dateOfBirth
              );
              this.patientInfoEmitter.emit(this.PatientData);
            }
          },
          error => {
            //  console.log(error);
          }
        );
    }
  }
  public calculateAge(birthdate: any): number {
    return moment().diff(birthdate, 'years');
  }
  getChatGroup() {
    // this.gettingChatGroup = true;
    // this.subs.sink = this.twocChatService
    //   .GetPersonalChatGroup(
    //     this.securityService.securityObject.appUserId,
    //     this.PatientData.userId
    //   )
    //   .subscribe(
    //     (res: ChatGroupDto) => {
    //       this.gettingChatGroup = false;
    //       // this.router.navigateByUrl(`/chat/messages?channel=${res.channelName}`);
    //       const event = new EmitEvent();
    //       event.name = EventTypes.OpenCommunicationModal;
    //       event.value = res;
    //       this.eventBus.emit(event);
    //     },
    //     (err: HttpResError) => {
    //       this.gettingChatGroup = false;
    //       this.toaster.error(err.message, err.error || err.error);
    //     }
    //   );
    const event = new EmitEvent();
    event.name = EventTypes.OpenCommunicationModal;
    const chatGroup = new PatinetCommunicationGroup();
    chatGroup.id = this.PatientData.id;
    chatGroup.name = `${this.PatientData.firstName} ${this.PatientData.lastName}`;
    chatGroup.lastCommunication = null
    event.value = chatGroup;
    this.eventBus.emit(event);
  }
  dateChanges(date: IDay, type){
    if (type === 'pcpAppointment' && date.date) {
      this.followUpDataObj.recentPcpAppointment = date.date.format("MM/DD/YYYY")
      this.updateFollowUpDate();
    }
    if(type == 'hospitalizationDate' && date.date){
        this.followUpDataObj.recentHospitalizationDate = date.date.format("MM/DD/YYYY")
        this.updateFollowUpDate();
    }
  }
  updateFollowUpDate() {
      this.followUpDataObj.patientId = this.PatientId;
      this.subs.sink = this.ccmService
        .changefollowUpDate(this.followUpDataObj)
        .subscribe(
          (res) => {
            this.toaster.success('Date Updated Successfully'); 
          },
          (err) => {
            this.toaster.error(err.message, err.error || err.error);
          }
        );
  }
  OpenTcmEncounter() {
    if (this.followUpDataObj.lastTcmEncounterId && this.followUpDataObj.lastTcmStatus < 3) {
      this.router.navigateByUrl(`/tcm/dischargeOverview/${this.PatientId}/${this.followUpDataObj.lastTcmEncounterId}`)
    } else {
      this.createTcmEncounter()
    }
  }
  getFollowUpDate() {
    this.subs.sink = this.ccmService.getfollowUpDate(this.PatientId).subscribe(
      (res: any) => {
        // this.lastTcmStatus = res.lastTcmStatus;
        // this.lastTcmEncounterId = res.lastTcmEncounterId;
        Object.assign(this.followUpDataObj, res);
        if(this.followUpDataObj.recentHospitalizationDate){
          this.followUpDataObj.recentHospitalizationDate = moment(this.followUpDataObj.recentHospitalizationDate).format('MM/DD/YYYY');
        }
        if(this.followUpDataObj.recentPcpAppointment){
          this.followUpDataObj.recentPcpAppointment = moment(this.followUpDataObj.recentPcpAppointment).format('MM/DD/YYYY');
        }
      },
      (err: HttpResError) => {
        this.toaster.error(err.error, err.error);
      }
    );
  }
  createTcmEncounter() {
    if ((this.followUpDataObj.lastTcmStatus > tcmStatus2Enum.InProcess || this.followUpDataObj.lastTcmStatus == tcmStatus2Enum.None)) {
      this.creatingTcm = true;
      this.addTcmEncounterDto.patientId = this.PatientId;
      this.addTcmEncounterDto.hospitalizationDate = this.followUpDataObj.recentHospitalizationDate;
      this.tcmSevice.createTcm(this.addTcmEncounterDto).subscribe(
        (res) => {
          this.creatingTcm = false;
          this.router.navigateByUrl(`/tcm/dischargeOverview/${this.PatientId}/${res}`);
        },
        (error: HttpResError) => {
          this.creatingTcm = false;
        }
      );
    }
  }
  OpenQuickViewModal() {
    const emitObj = new EmitEvent();
    emitObj.name = EventTypes.TriggerGlobalIframe;
    emitObj.value = `cura/notes-generator/${this.PatientData.id}`;
    this.eventBus.emit(emitObj);
  }
}
