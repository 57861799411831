import { PatientCommunicationHistoryDto } from "./PatientEngagement/communication.model";
import { DAST20ScreeningToolDto } from "./ScreeningTools/dast20.model";
import { GAD7ScreeningToolDto } from "./ScreeningTools/gad7.model";
import { PHQScreeningToolDto } from "./ScreeningTools/phq.modal";
import { WSASScreeningToolDto } from "./ScreeningTools/wasa.model";
import {
  BloodGlucoseList,
  BloodPressureList,
  WeightDataDto,
} from "./rpm.model";

export class ClinicalNotesDataDto {
  to: string;
  from: string;
  messageState = 2;
  includeMessages = false;
  includeDeviceData = false;
  includeRpmCarePlan = false;
  includeBhiCarePlan = false;
  includeBhiScreeningTool = false;
  patientId: number;
}
export class ClinicalNotesData {
  communicationDtos: PatientCommunicationHistoryDto[] = [];
  bpData: BloodPressureList[] = [];
  bgData: BloodGlucoseList[] = [];
  wtData: WeightDataDto[] = [];
  transcripts: Transcript[] = [];
  bhiScreeningTools: BHIScreeningTools;
  rpmCarePlan: string;
  bhiCarePlan: string;
  bhiCarePlanSelected = false;
  rpmCarePlanSelected = false;
}
export class Transcript{
  id: string;
  transcript: string;
}
export class BHIScreeningTools {
  dastDtos: DAST20ScreeningToolDto[] = [];
  gadDtos: GAD7ScreeningToolDto[] = [];
  phqDtos: PHQScreeningToolDto[] = [];
  wsasDtos: WSASScreeningToolDto[] = [];
}
export class AICallLogsDto {
  from: string;
  to: string;
  serviceType = [0, 1, 3];
  searchParam: string;
  filterBy: number;
  facilityId: number;
}
export class AICallLogsList {
  aiClinicalNoteId: string;
  callCharges: number;
  id: number;
  logDate: string;
  patientId: number;
  patientName: string;
  serviceType: number;
}
export class AddAICallLogsAndGeneratedDataDto {
  patientId: number;
  serviceType: number;
  previewNote: string;
  generatedNote: string;
}
