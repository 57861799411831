import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpErrorHandlerService } from "../shared/http-handler/http-error-handler.service";
import { catchError } from "rxjs";
import {
  AddEditDepartment,
  DeleteDepartment,
  EditPatientDepartment,
} from "../model/facility-department.model";
const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};
@Injectable({
  providedIn: "root",
})
export class FacilityDepartmentService {
  baseUrl = localStorage.getItem("switchLocal")
    ? environment.localBaseUrl
    : environment.baseUrl;

  constructor(
    private http: HttpClient,
    private httpErrorService: HttpErrorHandlerService
  ) {}

  getDepartmentsByFacilityId(facilityId) {
    return this.http
      .get(this.baseUrl + `Departments/${facilityId}`, httpOptions)
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  getDepartmentById(id) {
    return this.http
      .get(this.baseUrl + `Departments/GetDepartmentById/${id}`, httpOptions)
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  addEditDepartment(AddEditDepartment: AddEditDepartment) {
    return this.http
      .post(
        this.baseUrl + `Departments/AddEditDepartment`,
        AddEditDepartment,
        httpOptions
      )
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  deleteDepartment(deleteDepartmentDto: DeleteDepartment) {
    return this.http
      .put(
        this.baseUrl +
          `Departments/DeleteDepartment?departmentId=${deleteDepartmentDto.departmentId}&ShiftPatientToDepartmentId=${deleteDepartmentDto.shiftPatientToDepartmentId}`,

        httpOptions
      )
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  editPatientDepartment(editPatientDepartmentDto: EditPatientDepartment) {
    return this.http
      .put(
        this.baseUrl +
          `Departments/EditPatientDepartment?newDepartmentId=${editPatientDepartmentDto.newDepartmentId}&patientId=${editPatientDepartmentDto.patientId}`,
        httpOptions
      )
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  getDepartmentColorCodesByFacilityId(facilityId){
    return this.http
    .put(
      this.baseUrl +
        `Departments/GetDepartmentColorCodesByFacilityId/${facilityId}`,
      httpOptions
    )
    .pipe(catchError(this.httpErrorService.handleHttpError));
  }
}
