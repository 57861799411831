import { CustomFormStatus, CustomFormType } from "src/app/Enums/custom-form.enum";

export class AddEditCustomForm {
    id: number;
    title: string;
    moduleIds: any;
    chronicConditionIds = [];
    phsCareEpisodeIds = [];
    status: CustomFormStatus;
    publishedDate: string;
    customFormQuestions = new Array<AddEditCustomFormQuestion>();
    formType: CustomFormType;
    cptCode: string;
    icdCodes: string;
    encounterClaimNote: string;
    scoreableScript: string;
    encounterRequired: boolean;
  }
  export class AddEditCustomFormQuestion {
    id: number;
    section: string;
    description: string;
    isMandatory: boolean;
    questionType: number;
    customFormId: number;
    toolTip: string;
    sequenceNo: number;
    customFormQuestionOptions = new Array()
    collapsed= true; //extended
  }
  export class AddEditCustomFormQuestionOption {
    id: number;
    text: string;
    weight: number;
    flag: number;
    customFormQuestionId: number;
    sequenceNo: number;
    narration: string;
    questionType: number; //extended
  }
  export class FilterCustomForm {
    moduleIds = [];
    careEpisodeIds = [""];
    chronicConditionIds = [""];
    searchParam = "";
    formType= -1;
    status: number;
  }
