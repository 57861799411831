import { Component, OnInit } from "@angular/core";
import { UserType } from "../Enums/UserType.enum";
import { LocaleConfig } from "ngx-daterangepicker-material";
import { TimePeriod } from "ngx-daterangepicker-material/daterangepicker.component";
import { DataFilterService } from "../core/data-filter.service";
import { TwoCModulesEnum } from "../model/AppModels/app.model";
import { ChurnReport, PatientChurnAnalysisReport, StatusHistory, StatusHistoryFilter } from "../model/Patient/patient.model";
import { PatientsService } from "../core/Patient/patients.service";
import { HttpResError } from "../model/common/http-response-error";
import { FacilityService } from "../core/facility/facility.service";
import { FacilityDto } from "../model/Facility/facility.model";
import { ToastService } from "ng-uikit-pro-standard";
import moment from "moment";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { ChartOptions } from "chart.js";
import { SecurityService } from "../core/security/security.service";
import { ECalendarValue, IDatePickerDirectiveConfig, IDay } from "ng2-date-picker";
import { BhiStatusEnum } from "../Enums/bhi.enum";
import { CcmStatus, RpmStatus } from "../Enums/filterPatient.enum";

@Component({
  selector: "app-churn-analysis",
  templateUrl: "./churn-analysis.component.html",
  styleUrls: ["./churn-analysis.component.scss"],
})
export class ChurnAnalysisComponent implements OnInit {
  public options: LocaleConfig = {
    format: "MM/DD/YYYY",
    cancelLabel: "Clear",
  };
  public datePickerConfig2: IDatePickerDirectiveConfig = {
    allowMultiSelect: false,
    returnedValueType: ECalendarValue.StringArr,
    format: "YYYY-MM",
    max: moment().endOf("month").format("YYYY-MM-DD"),
  };
  public ReportChartColors: Array<any> = [
    {
      backgroundColor: [
        "#F7464A",
        "#46BFBD",
        "#FDB45C",
        "#949FB1",
        "#008000",
        "#808080",
        "#800000",
        "#FFFF00",
        "#808000",
        "#00FF00",
        "#00FFFF",
        "#008080",
        "#0000FF",
        "#273746",
        "#FF00FF",
        "#800080",
        "#CD5C5C",
        "#F08080",
        "#FA8072",
        "#E9967A",
        "#FFA07A",
        "#FCF3CF",
        "#F4D03F",
        "#8E44AD",
        "#C0392B",
        "#F39C12",
        "#BA4A00",
        "#58D68D",
      ],
      hoverBackgroundColor: ["#7ddc77", "#3f5c8a", "#FFC870", "#FF5A5E", "#5AD3D1", "#FFC870", "#A8B3C5"],
      borderWidth: 1,
    },
  ];
  public chartLabels: Array<any> = [
    "Red",
    "Blue",
    "Yellow",
    "Green",
    "Purple",
    "Orange",
    "Blue",
    "Yellow",
    "Green",
    "Purple",
    "Orange",
    "Green",
    "Purple",
  ];
  twoCModulesList = this.filterDataService.getEnumAsList(TwoCModulesEnum);
  daterange: any = {};
  selectedDateRange: any;
  isLoading = false;
  UserTypeEnum = UserType;
  firstLoad = true;
  patientChurnAnalysisReportDto = new PatientChurnAnalysisReport();
  facilityList = new Array<FacilityDto>();
  churnReportList = new Array<ChurnReport>();
  count = 10;
  isLoadingFacilities: boolean;
  isLoadingReport: boolean;
  columns: { name: string; prop: string }[];
  ExcelData: any[];
  statusHistoryDto = new StatusHistoryFilter();
  statusHistoryList = new Array<StatusHistory>();
  tempStatusHistoryList = new Array<StatusHistory>();
  twoCModulesEnum = TwoCModulesEnum;
  bhiStatusEnum = BhiStatusEnum;
  rpmStatusEnum = RpmStatus;
  ccmStatusEnum = CcmStatus;

  // chartLabels: any[] = [];
  chartDataSet: any[] = [];
  chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true, // Display legend
        position: "top", // Position it at the top
        labels: {
          color: "#4A4A4A", // Custom legend label color
          font: {
            size: 14, // Font size for legend
          },
        },
      },
      tooltip: {
        enabled: true, // Enable tooltips
        backgroundColor: "#f8f9fa", // Light background
        titleColor: "#000", // Tooltip title color
        bodyColor: "#333", // Tooltip body color
        borderWidth: 1, // Border for tooltips
        borderColor: "#ccc", // Border color
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide grid lines on x-axis
        },
        ticks: {
          color: "#4A4A4A", // Custom tick color
          font: {
            size: 12, // Tick font size
          },
        },
        title: {
          display: true,
          text: "Months",
          color: "#4A4A4A",
          font: {
            size: 14, // Font size for axis label
            weight: "bold",
          },
        },
      },
      y: {
        grid: {
          color: "rgba(200,200,200,0.2)", // Light grid lines
        },
        ticks: {
          color: "#4A4A4A", // Custom tick color
          font: {
            size: 12, // Tick font size
          },
        },
        title: {
          display: true,
          text: "Count",
          color: "#4A4A4A",
          font: {
            size: 14, // Font size for axis label
            weight: "bold",
          },
        },
      },
    },
    elements: {
      line: {
        tension: 0.4, // Smooth curves for lines
        borderWidth: 2, // Line thickness
        borderJoinStyle: "round", // Rounded line joins
      },
      point: {
        radius: 6, // Size of points
        hoverRadius: 8, // Size of points on hover
        backgroundColor: "#fff", // White fill for points
        borderWidth: 3, // Thickness of the point border
        borderColor: "#007bff", // Blue border for points
        hoverBorderColor: "#ff5722", // Orange border on hover
      },
    },
  };
  public chartColors: Array<any> = [
    {
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255,99,132,1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ];
  maxDate: Date;
  isLoadingStatusHistory: boolean;
  constructor(
    private filterDataService: DataFilterService,
    private patientsService: PatientsService,
    private facilityService: FacilityService,
    private toaster: ToastService,
    public securityService: SecurityService
  ) {}

  ngOnInit(): void {
    if (this.securityService.securityObject.userType === UserType.FacilityUser) {
      this.patientChurnAnalysisReportDto.facilityId = +this.securityService.getClaim("FacilityId").claimValue;
    }
    this.twoCModulesList = this.twoCModulesList.filter(
      (twoC) => twoC.value == TwoCModulesEnum.CCM || twoC.value == TwoCModulesEnum.BHI || twoC.value == TwoCModulesEnum.RPM
    );
    this.maxDate = moment().endOf("month").toDate();
    this.fillLastThreeMonthsDate();
    this.getFacilityList();
  }
  fillLastThreeMonthsDate() {
    this.patientChurnAnalysisReportDto.from = moment().subtract(2, "months").startOf("month").format("MM/DD/YYYY");
    this.patientChurnAnalysisReportDto.to = moment().endOf("month").format("MM/DD/YYYY");
  }
  selectedDate(value: TimePeriod, datepicker?: any) {
    if (value?.startDate && value?.endDate) {
      this.patientChurnAnalysisReportDto.from = value.startDate.format("MM/DD/YYYY");
      this.patientChurnAnalysisReportDto.to = value.endDate.format("MM/DD/YYYY");
      // this.getPatientChurnAnalysisReport();
    }
  }
  clearDate() {
    if (!this.firstLoad) {
      this.daterange = {};
      this.selectedDateRange = null;
      this.patientChurnAnalysisReportDto.from = "";
      this.patientChurnAnalysisReportDto.to = "";
      this.getPatientChurnAnalysisReport();
    }
  }
  getPatientChurnAnalysisReport() {
    this.isLoadingReport = true;
    this.patientsService.getPatientChurnAnalysisReport(this.patientChurnAnalysisReportDto).subscribe(
      (res: ChurnReport[]) => {
        this.firstLoad = false;
        this.churnReportList = res;

        if (this.churnReportList.length > 0) {
          this.columns = Object.keys(this.churnReportList[0]).map((key) => ({
            name: key,
            prop: key,
            sortable: key !== "Month", // Make 'Month' column non-sortable
          }));
          this.prepareChartData();
        }
        this.isLoadingReport = false;
      },
      (error) => {
        this.isLoadingReport = false;
      }
    );
  }
  getFacilityList() {
    this.isLoadingFacilities = true;
    this.facilityService.getFacilityList().subscribe(
      (res: any) => {
        this.facilityList = res;
        this.isLoadingFacilities = false;
      },
      (error: HttpResError) => {
        this.isLoadingFacilities = false;
        this.toaster.error(error.error, error.message);
      }
    );
  }

  makeExcelForChurnReport() {
    this.ExcelData = [];
    this.CreateExcelData(); // Dynamically populate ExcelData based on churnReportList

    const wb = XLSX.utils.book_new();
    wb.Props = {
      Title: "SheetJS Tutorial",
      Subject: "Test",
      Author: "Talha Ikram",
      CreatedDate: new Date(),
      Company: "Premier Solutions",
    };

    const sheetName = "Churn Analysis Report";
    wb.SheetNames.push(sheetName);

    const ws = XLSX.utils.json_to_sheet<any>(this.ExcelData, {
      skipHeader: false, // Include headers based on keys
    });

    // Adjust column widths dynamically based on the number of properties
    const wscols = Object.keys(this.ExcelData[0] || {}).map(() => ({ wch: 20 }));
    ws["!cols"] = wscols;

    wb.Sheets[sheetName] = ws;

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    // Utility function to convert binary string to buffer
    function s2ab(s: any) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    }

    const fileName = `${TwoCModulesEnum[this.patientChurnAnalysisReportDto.serviceType]}-Churn Analysis Report-${moment().format(
      "MM-DD-YYYY"
    )}.xlsx`;
    FileSaver.saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), fileName);
  }

  CreateExcelData() {
    if (this.churnReportList.length > 0) {
      // Dynamically determine keys from the first object in churnReportList
      const keys = Object.keys(this.churnReportList[0]);

      this.churnReportList.forEach((item: any) => {
        const rowData: any = {};
        keys.forEach((key) => {
          rowData[key] = this.checkIfNull(item[key]); // Populate dynamic properties
        });
        this.ExcelData.push(rowData);
      });
    }
  }
  checkIfNull(data: any): string {
    return data !== null && data !== undefined ? data.toString() : "";
  }
  prepareChartData() {
    // Extract months for x-axis labels
    this.chartLabels = this.churnReportList.map((item) => item.Month);

    // Extract keys dynamically for counts (excluding the `month` property)
    const countKeys = Object.keys(this.churnReportList[0]).filter((key) => key !== "Month");

    // Prepare datasets
    this.chartDataSet = countKeys.map((key) => ({
      label: key,
      data: this.churnReportList.map((item) => item[key]),
      borderColor: this.getRandomColor(),
      backgroundColor: "rgba(0, 0, 0, 0)", // No background fill for the area under the line
      borderWidth: 2, // Line thickness
      tension: 0.4, // Curve the lines (0 is straight, 0.4 is moderately rounded)
      pointStyle: "circle", // Rounded points
      radius: 6, // Point size
      fill: false, // Ensure no area fill under the line
    }));
  }

  // Helper function to generate random colors for each dataset
  getRandomColor(): string {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  filterByMonth(date: IDay, type: string) {
    const dateObj = date.date.toDate();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // getMonth() is 0-indexed
    const day = dateObj.getDate().toString().padStart(2, "0");
    const year = dateObj.getFullYear();

    const formattedDate = `${month}-${day}-${year}`;
    if (type === "from") {
      const selectedFromDate = moment(formattedDate, "MM-DD-YYYY").startOf("month");
      const toDate = this.patientChurnAnalysisReportDto.to
        ? moment(this.patientChurnAnalysisReportDto.to, "MM/DD/YYYY").startOf("month")
        : null;

      if (toDate && selectedFromDate.isAfter(toDate)) {
        this.toaster.warning("The 'From' month cannot be greater than the 'To' month.");
        return;
      }
      this.patientChurnAnalysisReportDto.from = selectedFromDate.format("MM/DD/YYYY");
    } else if (type === "to") {
      const selectedToDate = moment(formattedDate, "MM-DD-YYYY").endOf("month");

      const formatSelectedDate = selectedToDate.format("MM-DD-YYYY");

      const fromDate = this.patientChurnAnalysisReportDto.from
        ? moment(this.patientChurnAnalysisReportDto.from, "MM/DD/YYYY").startOf("month")
        : null;

      if (fromDate && fromDate.isAfter(selectedToDate.startOf("month"))) {
        this.toaster.warning("The 'To' month cannot be earlier than the 'From' month.");
        return;
      }

      this.patientChurnAnalysisReportDto.to = moment(formatSelectedDate, "MM-DD-YYYY").format("MM/DD/YYYY");
    }
  }
  resetFilters() {
    this.patientChurnAnalysisReportDto = new PatientChurnAnalysisReport();
    if (this.securityService.securityObject.userType === UserType.FacilityUser) {
      this.patientChurnAnalysisReportDto.facilityId = +this.securityService.getClaim("FacilityId").claimValue;
    }
    this.fillLastThreeMonthsDate();
    this.churnReportList = new Array<ChurnReport>();
  }
  fillFromAndToDate(duration: string,month?: string, ) {
    if (duration == "1Month") {
      this.statusHistoryDto.from = moment(month, "MMM-YYYY").startOf("month").format("MM/DD/YYYY");
      this.statusHistoryDto.to = moment(month, "MMM-YYYY").endOf("month").format("MM/DD/YYYY");
    } else if (duration == "3Months") {
      const startDate = moment().subtract(2, "months").startOf("month"); // Go back 2 months to get 3 month range
      this.statusHistoryDto.from = startDate.format("MM/DD/YYYY");
      this.statusHistoryDto.to = moment().endOf("month").format("MM/DD/YYYY");
    }
    this.statusHistoryDto.serviceType = this.patientChurnAnalysisReportDto.serviceType;
    this.getStatusHistory();
  }
  getStatusHistory() {
    this.isLoadingStatusHistory = true;
    this.patientsService.GetStatusHistory(this.statusHistoryDto).subscribe(
      (res: any) => {
        this.statusHistoryList = res;
        this.tempStatusHistoryList = res;
        this.isLoadingStatusHistory = false;
      },
      (err: HttpResError) => {
        this.isLoadingStatusHistory = false;
        this.toaster.error(err.error, err.message);
      }
    );
  }
  resetStatusHistory() {
    this.statusHistoryDto = new StatusHistoryFilter();
    this.statusHistoryList = new Array<StatusHistory>();
  }
  filterPatientInStatusHistory(searchParam: string) {
    this.statusHistoryList = this.tempStatusHistoryList.filter((item) => item.patientName.toLowerCase().includes(searchParam.toLowerCase()));

  }
}
