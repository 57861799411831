import { AWServiceStatus } from "../Enums/aw.enum";
import { BhiStatusEnum } from "../Enums/bhi.enum";
import { CcmStatus, PatientStatus, RpmStatus, SortOrder } from "../Enums/filterPatient.enum";
import { HESStatusEnum } from "../Enums/hes.enum";
import { PcmStatus } from "../Enums/pcm.enum";
import { PRCMStatusEnum } from "./Prcm/Prcm.model";
import { tcmStatus2Enum } from "./Tcm/tcm.enum";

export class LandingPageParamsDto {
	searchParam = '';
	facilityId = 0;
	payerIds: number[] = [];
	filterBy = 1;
	patientStatus = [0];
	sortBy = '';
	sortOrder: SortOrder = 0;
	pageSize = 10;
	pageNumber = 1;
}

export class PatientForPendingPageListDto {
	fullName: string;
	id = 0;
	patientEmrId: string;
	dateOfBirth: Date | string;
	profileStatus: boolean;
	isDeletedState: boolean;
	patientStatus: PatientStatus;
	ccmStatus: CcmStatus;
	hhcEndDate = "";
	hhcEndDateClass = "";
	pcmStatus: PcmStatus;
	bhiStatus: BhiStatusEnum;
	prCMStatus: PRCMStatusEnum;
	tcmStatus: tcmStatus2Enum;
	hesStatus: HESStatusEnum;
	lastTcmEncounterId: number;
	rpmStatus: RpmStatus;
	awServiceStatus: AWServiceStatus;
	telephonyCommunication: boolean;
	unAssociatedCommunication: number
	checked: boolean
	lastAppLaunchDate: string
	isActiveMobileUser: boolean;
	ccmLastStatusChangedByNameAndDate: string
	rpmLastStatusChangedByNameAndDate: string
	bhiLastStatusChangedByNameAndDate: string


	creatingTcm: boolean; // extended

}
