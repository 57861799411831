import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnDebounceDirective } from '../directives/on-debounce.directive';
import { NumericDirective } from '../directives/decimal-numbers.directive';
import { HasClaimDirective } from '../directives/has-claim.directive';
import { DebounceClickDirective } from 'src/app/debounce-click.directive';
import { IsOutsideDirective } from '../is-outside.directive';
import { IsDevelopmentDirective } from '../directives/is-development.directive';
import { EmailValidatorDirective } from './email-validator.directive';
import { DateTimeValidatorDirective } from './date-time-validator.directive';
import { WebUrlValidatorDirective } from './webUrl-validator.directive';

@NgModule({
schemas: [CUSTOM_ELEMENTS_SCHEMA],

  declarations: [OnDebounceDirective, NumericDirective, HasClaimDirective, IsDevelopmentDirective, DebounceClickDirective, IsOutsideDirective, EmailValidatorDirective, DateTimeValidatorDirective, WebUrlValidatorDirective],
  imports: [
    CommonModule,
  ],
  exports: [
    OnDebounceDirective,
    NumericDirective,
    HasClaimDirective, IsDevelopmentDirective,
    DebounceClickDirective,
    IsOutsideDirective,
    EmailValidatorDirective,
    DateTimeValidatorDirective,
    WebUrlValidatorDirective
  ]
})
export class SharedDirectivesModule { }
