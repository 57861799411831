import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ToastService } from 'ng-uikit-pro-standard';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpErrorHandlerService } from '../shared/http-handler/http-error-handler.service';
import { EmitEvent, EventBusService, EventTypes } from './event-bus.service';
import { SecurityService } from './security/security.service';
import { AddCommunicationDto, ChangeCommunicationFlagsDto, GetCommunicationGroupParam, MarkCommunicationViewedDto, MarkPatientGroupFlagsDto, PatientCommunicationHistoryDto } from '../model/PatientEngagement/communication.model';
import { PemMapNumberDto } from '../model/PatientEngagement/pem.model';
import { PatientDto } from '../model/Patient/patient.model';
import { AddCcmEncounterDto } from '../model/admin/ccm.model';
import { RPMEncounterDto } from '../model/rpm.model';
import { RPMServiceType } from '../Enums/rpm.enum';
import { Subject } from 'rxjs';
import { BHIServiceTypeEnum, BhiEncounterDto, BhiPatientsListDto } from '../model/Bhi/bhi.model';
import { End_PrCMServiceType, PRCMEncounterDto } from '../model/Prcm/Prcm.model';
import moment from 'moment';
import { HesEncounterDto } from '../model/Hes/hes.model';
import { HESTypeEnum } from '../Enums/hes.enum';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  baseUrl = localStorage.getItem('switchLocal') ? environment.localBaseUrl:  environment.baseUrl;
  callInterval: NodeJS.Timeout;
  chatCountChanges = new Subject();
  constructor(
    private http: HttpClient,
    private eventBus: EventBusService,
    private securityService: SecurityService,
    @Inject(ToastService) private toaster: ToastService,
    private httpErrorService: HttpErrorHandlerService,
  )  { }

  GetPatientGroups(facilityId: number, pData: GetCommunicationGroupParam) {
    let qStr = ``
    if (pData.PageNumber) {
      qStr += `PageNumber=${pData.PageNumber}&`
    }
    if (pData.PageSize) {
      qStr += `PageSize=${pData.PageSize}&`
    }
    if (pData.UnRead) {
      qStr += `Unread=${pData.UnRead}&`
    }
    if (pData.Critical) {
      qStr += `Critical=${pData.Critical}&`
    }
    if (pData.Following) {
      qStr += `Following=${pData.Following}&`
    }
    // if (facilityId ) {
    //   qStr += `FacilityId=${facilityId}&`
    // }
    if (pData.SortBy) {
      qStr += `SortBy=${pData.SortBy.toLowerCase()}&SortOrder=${pData.SortOrder || 0}`
    }
    return this.http.get(this.baseUrl + `PatientCommunication/GetPatientGroups/${facilityId}?${qStr}` , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  GetCommunicationSummaryData(facilityId: number) {
    return this.http.get(this.baseUrl + `PatientCommunication/GetCommunicationSummaryData/${facilityId}` , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  GetAnonymousMessages(facilityId: number) {
    return this.http.get(this.baseUrl + `PatientCommunication/GetAnonymousMessages/${facilityId}` , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  GetCommunicationHistory(patientId: number, month = 0, year = 0) {
    let qStr = ``
    if (true) {
      qStr += `PageNumber=${1}&`
    }
    if (true) {
      qStr += `PageSize=${100000}&`
    }
    if (month) {
      qStr += `month=${month}&`
    }
    if (true) {
      qStr += `year=${year}&`
    }
    return this.http.get(this.baseUrl + `PatientCommunication/GetCommunicationHistory/${patientId}?${qStr}` , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  AddPatientCommunication(pData: AddCommunicationDto) {
    return this.http.post(this.baseUrl + `PatientCommunication`, pData , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  MarkCommunicationViewed(pData: MarkCommunicationViewedDto) {
    return this.http.post(this.baseUrl + `PatientCommunication/MarkCommunicationViewed`, pData , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  MarkPatientGroupFlags(pData: MarkPatientGroupFlagsDto) {
    return this.http.post(this.baseUrl + `PatientCommunication/MarkPatientGroupFlags`, pData , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  MapPatientPhoneNumber(obj: PemMapNumberDto) {
    return this.http.post(this.baseUrl + `PatientCommunication/MapUserPhoneNumberByPatientId`, obj , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  ChangePatientsCommunicationFlags(obj: ChangeCommunicationFlagsDto) {
    return this.http.put(this.baseUrl + `PatientCommunication/ChangePatientsCommunicationFlags`, obj , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  OpenCCMEncounterModel(patient: PatientDto, note = '', commHistory: PatientCommunicationHistoryDto[] = [], viewType: string) {
    const encounterObj = new AddCcmEncounterDto()
    encounterObj.ccmServiceTypeId = 27;
    var selectedMessages = commHistory.filter(x => x.selected);
    var associatedMessages = selectedMessages.filter(x => x.isAssociatedWithEncounter == 1)
    if(viewType == 'chatModal'){
      if(associatedMessages.length){
          this.toaster.warning('Some of the selected messages are already part of encounters.');
        return;
      }else{
        var isMessageInDifferentMonth = this.areObjectsInSameMonth(selectedMessages);
        if(!isMessageInDifferentMonth){
          this.toaster.warning('The messages you have selected are from different months.');
          return;
        }else{
        var isMessageIn60DaysOld = this.verifyTimestamp(selectedMessages);
        if(isMessageIn60DaysOld){
          this.toaster.warning('Some of the selected messages are 2 months old and cannot be part of encounters.');
          return;
        }else{
         var messageIds = selectedMessages.map(x => x.id);
        }
        }
      }
    }else{
      var messageIds = selectedMessages.map(x => x.id);
    }
    encounterObj['duration'] = messageIds.length || 1 as any;
    encounterObj.patientCommunicationIds = messageIds;
    encounterObj.note = note
    const emitObj = new EmitEvent();
    emitObj.name = EventTypes.OpenCCMQuickEncounter;
    emitObj.value = {
      type: EventTypes.OpenCCMQuickEncounter.toString(),
      data: {
        patient: patient,
        encounterObj
      },
      config: {
        hideTimer: true
      }
    };
    this.eventBus.emit(emitObj);
  }
  OpenBHIEncounterModel(patient: any, note = '', commHistory: PatientCommunicationHistoryDto[] = [], viewType: string) {
    const encounterObj = new BhiEncounterDto()

    var selectedMessages = commHistory.filter(x => x.selected);
    var associatedMessages = selectedMessages.filter(x => x.isAssociatedWithEncounter == 1)
    if(viewType == 'chatModal'){
    if(associatedMessages.length){
        this.toaster.warning('Some of the selected messages are already part of encounters.');
      return;
    }else{
      var isMessageInDifferentMonth = this.areObjectsInSameMonth(selectedMessages);
      if(!isMessageInDifferentMonth){
        this.toaster.warning('The messages you have selected are from different months.');
        return;
      }else{
      var isMessageIn60DaysOld = this.verifyTimestamp(selectedMessages);
      if(isMessageIn60DaysOld){
        this.toaster.warning('Some of the selected messages are 2 months old and cannot be part of encounters.');
        return;
      }else{
       var messageIds = selectedMessages.map(x => x.id);
      }
      }
    }
    }else{
      var messageIds = selectedMessages.map(x => x.id);
    }
    encounterObj.duration = messageIds.length || 1 as any;
    encounterObj.patientCommunicationIds = messageIds;
    encounterObj.note = note;
    const emitObj = new EmitEvent();
    emitObj.name = EventTypes.OpenBHIQuickEncounter;
    emitObj.value = {
      type: EventTypes.OpenBHIQuickEncounter.toString(),
      data: {
        patient: patient,
        encounterObj,
      },

    };
    this.eventBus.emit(emitObj);
  }
  OpenRPMEncounterModel(patient: PatientDto, note = '', commHistory: PatientCommunicationHistoryDto[] = [], viewType: string) {
    const encounterObj = new RPMEncounterDto()
    encounterObj.rpmServiceType = RPMServiceType.Text;

    var selectedMessages = commHistory.filter(x => x.selected);
    var associatedMessages = selectedMessages.filter(x => x.isAssociatedWithEncounter == 1)
    if(viewType == 'chatModal'){
    if(associatedMessages.length){
        this.toaster.warning('Some of the selected messages are already part of encounters.');
      return;
    }else{
      var isMessageInDifferentMonth = this.areObjectsInSameMonth(selectedMessages);
      if(!isMessageInDifferentMonth){
        this.toaster.warning('The messages you have selected are from different months.');
        return;
      }else{
      var isMessageIn60DaysOld = this.verifyTimestamp(selectedMessages);
      if(isMessageIn60DaysOld){
        this.toaster.warning('Some of the selected messages are 2 months old and cannot be part of encounters.');
        return;
      }else{
       var messageIds = selectedMessages.map(x => x.id);
      }
      }
    }
    }else{
      var messageIds = selectedMessages.map(x => x.id);
    }
    encounterObj.duration = messageIds.length || 1 as any;
    encounterObj.patientCommunicationIds = messageIds;
    encounterObj.note = note;
    const emitObj = new EmitEvent();
    emitObj.name = EventTypes.OpenRPMQuickEncounter;
    emitObj.value = {
      type: EventTypes.OpenRPMQuickEncounter.toString(),
      data: {
        patient: patient,
        encounterObj,
        config: {
          hideTimer: true
        }
      },

    };
    this.eventBus.emit(emitObj);
  }
  OpenHESEncounterModel(patient: PatientDto, note = '', commHistory: PatientCommunicationHistoryDto[] = [], viewType: string) {
    const encounterObj = new HesEncounterDto()
    encounterObj.hesType = HESTypeEnum.CHI;

    var selectedMessages = commHistory.filter(x => x.selected);
    var associatedMessages = selectedMessages.filter(x => x.isAssociatedWithEncounter == 1)
    if(viewType == 'chatModal'){
    if(associatedMessages.length){
        this.toaster.warning('Some of the selected messages are already part of encounters.');
      return;
    }else{
      var isMessageInDifferentMonth = this.areObjectsInSameMonth(selectedMessages);
      if(!isMessageInDifferentMonth){
        this.toaster.warning('The messages you have selected are from different months.');
        return;
      }else{
      var isMessageIn60DaysOld = this.verifyTimestamp(selectedMessages);
      if(isMessageIn60DaysOld){
        this.toaster.warning('Some of the selected messages are 2 months old and cannot be part of encounters.');
        return;
      }else{
       var messageIds = selectedMessages.map(x => x.id);
      }
      }
    }
    }else{
      var messageIds = selectedMessages.map(x => x.id);
    }
    encounterObj.duration = messageIds.length || 1 as any;
    encounterObj.endTime = moment().format('hh:mm A');
    encounterObj.startTime = moment().subtract((messageIds.length || 1), 'minutes').format('hh:mm A');
    encounterObj.patientCommunicationIds = messageIds;
    encounterObj.note = note;
    const emitObj = new EmitEvent();
    emitObj.name = EventTypes.OpenHESQuickEncounter;
    emitObj.value = {
      type: EventTypes.OpenHESQuickEncounter.toString(),
      data: {
        patient: patient,
        encounterObj,
        config: {
          hideTimer: true
        }
      },

    };
    this.eventBus.emit(emitObj);
  }
  OpenPRCMEncounterModel(patient: PatientDto, note = '', commHistory: PatientCommunicationHistoryDto[] = [], viewType: string) {
    const encounterObj = new PRCMEncounterDto()
    encounterObj.prCMServiceTypeId = End_PrCMServiceType['Initial Assessment'];

    var selectedMessages = commHistory.filter(x => x.selected);
    var associatedMessages = selectedMessages.filter(x => x.isAssociatedWithEncounter == 1)
    if(viewType == 'chatModal'){
    if(associatedMessages.length){
        this.toaster.warning('Some of the selected messages are already part of encounters.');
      return;
    }else{
      var isMessageInDifferentMonth = this.areObjectsInSameMonth(selectedMessages);
      if(!isMessageInDifferentMonth){
        this.toaster.warning('The messages you have selected are from different months.');
        return;
      }else{
      var isMessageIn60DaysOld = this.verifyTimestamp(selectedMessages);
      if(isMessageIn60DaysOld){
        this.toaster.warning('Some of the selected messages are 2 months old and cannot be part of encounters.');
        return;
      }else{
       var messageIds = selectedMessages.map(x => x.id);
      }
      }
    }
    }else{
      var messageIds = selectedMessages.map(x => x.id);
    }
    encounterObj.endTime = moment().format('hh:mm A');
    encounterObj.startTime = moment().subtract((messageIds.length || 1), 'minutes').format('hh:mm A');
    encounterObj.patientCommunicationIds = messageIds;
    encounterObj.note = note;
    const emitObj = new EmitEvent();
    emitObj.name = EventTypes.OpenPRCMQuickEncounter;
    emitObj.value = {
      type: EventTypes.OpenPRCMQuickEncounter.toString(),
      data: {
        patient: patient,
        encounterObj,
        config: {
          hideTimer: true
        }
      },

    };
    this.eventBus.emit(emitObj);
  }
  areObjectsInSameMonth(objectList: any[]): boolean {
    // Check if the list is empty
    if (objectList.length === 0) {
      return false;
    }
    // Get the month of the first object
    const firstObjectMonth = new Date(objectList[0].timeStamp).getMonth();

    // Check if all other objects have the same month
    for (let i = 1; i < objectList.length; i++) {
      const currentObjectMonth = new Date(objectList[i].timeStamp).getMonth();

      if (currentObjectMonth !== firstObjectMonth) {
        return false; // Different month found
      }
    }

    return true; // All objects are in the same month
  }
  verifyTimestamp(list: any[]): boolean {
    const currentTimestamp = new Date().getTime(); // Get current timestamp in milliseconds
    const sixtyDaysInMillis = 60 * 24 * 60 * 60 * 1000; // 60 days in milliseconds

    for (const item of list) {
      if (item.timeStamp) {
        const itemTimestamp = new Date(item.timeStamp).getTime();
        const differenceInMillis = currentTimestamp - itemTimestamp;

        if (differenceInMillis > sixtyDaysInMillis) {
          return true; // If any object is older than 60 days, return true
        }
      }
    }
    return false; // Return false if no object is older than 60 days
  }
  getUnReadPatientGroupsCountByFacilityId(facilityId) {
    return this.http.get(this.baseUrl + `PatientCommunication/GetUnReadPatientGroupsCountByFacilityId/${facilityId}` , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  getCallRecordingTranscriptById(transId) {
    return this.http.get(this.baseUrl + `PatientCommunication/GetCallRecordingTranscriptById?callRecordingTranscriptId=${transId}` , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  getUnusedMessagesByPatientId(patientId: number) {
    return this.http.get(this.baseUrl + `PatientCommunication/GetUnusedMessagesByPatientId/${patientId}` , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  checkPreviousMessagesPresent(patientId: number) {
    return this.http.get(this.baseUrl + `PatientCommunication/CheckPreviousMessagesPresent/${patientId}` , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  markPreviousMessagesArchived(messageIds: number[]) {
    return this.http.get(this.baseUrl + `PatientCommunication/MarkPreviousMessagesArchived?messageIds=${messageIds}` , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
}
