import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject, debounceTime } from 'rxjs';
import { EmitEvent, EventBusService, EventTypes } from 'src/app/core/event-bus.service';
import { TriggerIntellisenseWidgetDTO } from 'src/app/model/Tools/intellisense.model';
import { SpeechToTextService } from './../../core/Tools/speech-to-text.service';

@Component({
  selector: 'app-two-c-text-area',
  templateUrl: './two-c-text-area.component.html',
  styleUrls: ['./two-c-text-area.component.scss']
})
export class TwoCTextAreaComponent implements OnInit {
  @Input() id!: string;
  @Input() height = '50px';
  @Input() value!: string;
  @Input() PatientId!: number;
  @Input() isChatArea = false;
  @Output() EnterPressedEmitter: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('TCFieldRef') TCFieldRef: ElementRef<HTMLDivElement>;
  speechState = false;
  savedRange: Range;
  interimResult: string;
  
  
  @Output() valueChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() valueChangedDebounce: EventEmitter<string> = new EventEmitter<string>();
  private valueChangeSubject: Subject<string> = new Subject<string>();
  constructor(private eventBus: EventBusService, private cdr: ChangeDetectorRef,
    private speechToText: SpeechToTextService) { }

  ngOnInit(): void {
    // this.speechToText.initSpeechRecognition();
    this.eventBus.on(EventTypes.PhraseSelectedEvent).subscribe((res) => {
      this.FillSelectedPhraseText(res);
    });
    this.eventBus.on(EventTypes.SpeechTextEvent).subscribe((res: { text: string, isFinal: boolean }) => {
      if (this.speechState) {
        if (res.isFinal) {
          this.FillSelectedPhraseText({ phraseText: res.text }, true);
          setTimeout(() => {
            this.interimResult = '';
          }, 200);
        } else {
          this.interimResult = res.text;
        }
        
        this.cdr.detectChanges();
        // this.insertTextAtPosition(res, this.pos)
      }
    });
    this.eventBus.on(EventTypes.SpeechRecognitionStateEvent).subscribe((res) => {
      if (res == false) {
        this.speechState = false
      }
    });
    // this.speechToText.initSpeechRecognition();
    if (this.value) {
      this.FillValue(this.value)
    }
    // Subscribe to the subject with a debounce time
    this.valueChangeSubject.pipe(
      debounceTime(1200)  // Debounce time of 1200 milliseconds
    ).subscribe(newValue => {
      this.valueChangedDebounce.emit(newValue);
      // You can perform other actions here if needed
    });
  }
  saveCursorPosition(): void {
    let selection = document.getSelection();
    if (!selection?.rangeCount) return; // No selection available

    if (selection && selection.rangeCount > 0) {
      this.savedRange = selection.getRangeAt(0);
    }
    // const containerText = this.savedRange.startContainer.textContent;
    // const startPos = Math.max(this.savedRange.startOffset - 2, 0); // Look 2 characters back
    // const endPos = this.savedRange.startOffset;
    // console.log("Position saved at " + containerText.substring(startPos, endPos))
  }
  triggerPopOverCheck(eData: any) {
    // setTimeout(() => {
    // }, 500);
    // this.triggerPopOverCheckDElayed(eData);
    this.checkForDoublePeriod(eData);
  }
  checkForDoublePeriod(event: KeyboardEvent): void {
    this.saveCursorPosition()
    if (event.key === '.') {
      const selection = window.getSelection();
      const cursorDetail = selection.getRangeAt(0).getBoundingClientRect();
      let top = ''
      if (this.isChatArea) {
        let topp = (cursorDetail.y + document.documentElement.scrollTop) / 2;
        let result = topp + (topp * 20 / 100);
        top = `${result}px`;
      } else {
        top = `${cursorDetail.y + document.documentElement.scrollTop}px`;
      }
      if (selection?.rangeCount) {
        const dataVal = new TriggerIntellisenseWidgetDTO();
        const range = selection.getRangeAt(0);
        const startPos = Math.max(range.startOffset - 2, 0); // Look 2 characters back
        const endPos = range.startOffset;
        const transform = `translate(${cursorDetail.x}px , 0px)`;

        const containerText = range.startContainer.textContent;

        if (containerText && containerText.substring(startPos, endPos) === '..') {
          console.log('Double period detected.');
          // Remove the two periods
          range.startContainer.textContent =
            containerText.substring(0, startPos) +
            containerText.substring(endPos);

          // Update the cursor position to be where the periods were removed
          const newRange = document.createRange();
          const sel = window.getSelection();
          newRange.setStart(range.startContainer, startPos);
          newRange.collapse(true);
          sel?.removeAllRanges();
          sel?.addRange(newRange);
          this.saveCursorPosition()
          dataVal.ViewType = 'block';
          dataVal.top = top;
          dataVal.transform = transform;
          // this.selectionAnchorOffset = wSelection.anchorOffset;
          this.TriggerGlobalIntellisenseWIdget(dataVal);
        } else {
          dataVal.ViewType = 'none';
          this.TriggerGlobalIntellisenseWIdget(dataVal);
        }

      }
    }
    this.EmitEditorValue()
  }

  TriggerGlobalIntellisenseWIdget(eData: TriggerIntellisenseWidgetDTO) {
    const event = new EmitEvent();
    eData.patientId = this.PatientId;
    event.name = EventTypes.TriggerGlobalIntellisenseWIdget;
    event.value = eData;
    this.eventBus.emit(event);
  }

  FillSelectedPhraseText = (resData: any, isSpeechText = false) => {
    const phraseText = resData.phraseText;
    const selection = window.getSelection();
    if (this.savedRange) {
      selection?.removeAllRanges(); // Clear current selection
      selection?.addRange(this.savedRange); // Restore the saved selection

      // Check if the current range is within the editableDiv
      if (!this.TCFieldRef?.nativeElement.contains(selection?.anchorNode)) {
        console.error('Saved range is not within the editableDiv');
        return;
      }

      // Create a new text node and insert it
      // const textNode = document.createTextNode(phraseText);
      const textNode = document.createElement("div");
      textNode.innerText = phraseText;
      this.savedRange.insertNode(textNode);

      // Move the cursor after the inserted text
      this.savedRange.setStartAfter(textNode);
      this.savedRange.setEndAfter(textNode);
      selection?.removeAllRanges(); // Clear and update the range to position the cursor
      selection?.addRange(this.savedRange);

      // Scroll into view
      this.scrollToCursor()

      // Optionally, save the new range if you want to allow for multiple insertions
      this.saveCursorPosition();
    }
    this.EmitEditorValue()
    this.cdr.detectChanges();
  }
  scrollToCursor(): void {
    // Create a temporary span (marker) at the cursor position
    const marker = document.createElement('span');
    // You can set the span to be invisible if you prefer
    marker.style.opacity = '0';
    this.savedRange.insertNode(marker);

    marker.scrollIntoView({ behavior: 'smooth', block: 'center' });

    // Remove the marker from the DOM
    marker.parentNode?.removeChild(marker);

    // Optionally adjust the saved range after removing the marker
    // if it affects your application logic
  }
  EmitEditorValue() {
    this.valueChanged.emit(this.TCFieldRef.nativeElement?.innerText || "");
    this.value = this.TCFieldRef.nativeElement?.innerText
    this.valueChangeSubject.next(this.value);
  }
  FillValue(text: string) {
    if (this.TCFieldRef?.nativeElement) {
      this.TCFieldRef.nativeElement.innerText = text;
      this.cdr.detectChanges();
    } else {
      setTimeout(() => {
        if (this.TCFieldRef?.nativeElement) {
          this.TCFieldRef.nativeElement.innerText = text;
          this.cdr.detectChanges();
        }
      }, 1000);
    }
  }
  startSpeech() {
    this.speechState = true;
    this.speechToText.start();
    this.CreateDefaultRange()
    // this.TCFieldRef.nativeElement.focus();
  }
  stopSpeech() {
    if (this.speechState) {
      this.speechState = false;
      this.speechToText.stop();
    }
  }
  EnterPressed() {
    this.EnterPressedEmitter.emit(this.TCFieldRef.nativeElement?.innerText || "")
  }
  GotFocus($event) {
    console.log($event)
  }

  CreateDefaultRange() {
    if (!this.savedRange && this.TCFieldRef.nativeElement) {
      const range = document.createRange();
      const textNode = this.TCFieldRef.nativeElement.lastChild as Node;
      if (textNode) {
        range.setStartAfter(textNode);
      } else {
        // If there's no text, just set the range to start at the element
        range.setStart(this.TCFieldRef.nativeElement, 0);
      }
      range.collapse(true); // Collapse the range to the end point, essential for setting cursor at the end
      this.savedRange = range;
    }
  }
  OnPaste(event: ClipboardEvent) {
    setTimeout(() => {
      this.EmitEditorValue()
    }, 100);
  }
}
