<div style="overflow-y:auto" mdbModal #hesEncounterModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  id="AlertModal" aria-labelledby="myBasicModalLabel" aria-hidden="true" (close)="resetEncounterValues()" >
  <div class="modal-dialog modal-dialog-scrollable modal-xl flat-modal p-4" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="hesEncounterModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 *ngIf="!onlyEditCarePlanView && addHesEncounterDto.id == 0" class="text-dynamic-2c modal-title font-500 mr-3">Add Encounter</h4>
        <h4 *ngIf="!onlyEditCarePlanView && addHesEncounterDto.id != 0" class="text-dynamic-2c modal-title font-500 mr-3">Edit Encounter</h4>
        <h4 *ngIf="onlyEditCarePlanView" class="text-dynamic-2c modal-title font-500 mr-3">Care Plan</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-6" [class.col-sm-12]="onlyEditCarePlanView">
            <div>
              <h6 class="note-heading" for="call-38">HES Care Plan</h6>
            </div>
            <app-two-c-text-area *ngIf="addHesEncounterDto.patientId" #myFIeldRefPNT [value]="carePlanNote" [height]="'400px'"
              [PatientId]="addHesEncounterDto.patientId" (valueChanged)="carePlanNote=$event;"></app-two-c-text-area>
            
              <div class="py-2">
                <button class="btn btn-dynamic-2c btn-sm mx-1" (click)="EditHesCarePlan()">Update Care Plan</button>
                <button class="btn btn-dynamic-2c btn-sm " (click)="DownloadCarePlan()">Download</button>
              </div>
          </div>
          <div class="col-sm-6" *ngIf="!onlyEditCarePlanView">
            <div class="encounter-body px-3">
              
              <div class="row">
    
                <div class="col-sm-6">
                  <div class="input-group form-group">
                    <label class="w-100">Date <sup class="text-danger">*</sup> </label>
                    <input type="text" class="form-control form-control-sm" #dateDPicker56="dpDayPicker"
                      [dpDayPicker]="datePickerConfig2" [(ngModel)]="addHesEncounterDto.encounterDate" [theme]="'dp-material ccm-date-picker'"
                      [mode]="'day'" appDateTimeValidator="date" />
                    <a (click)="dateDPicker56.api.open();" class="input-group-append">
                      <span class="input-group-text text-white bg-dynamic-2c"><i class="las la-calendar fa-lg"></i></span>
                    </a>
                  </div>
                </div>
                <div class="col-lg-3 pr-0 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label class="w-100">Start Time <sup class="text-danger">*</sup> </label>
                    <input type="text" class="form-control form-control-sm" #dateDpTime="dpDayPicker"
                      [dpDayPicker]="timePickerConfig" [(ngModel)]="addHesEncounterDto.startTime" (onSelect)="StartTimeChanged()"
                      [theme]="'dp-material ccm-date-picker'" [mode]="'time'" appDateTimeValidator="time"  />
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                  <div class="form-group">
                    <label class="w-100">End Time <sup class="text-danger">*</sup> </label>
                    <input type="text" readonly class="form-control form-control-sm"
                      [(ngModel)]="addHesEncounterDto.endTime">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="w-100">Duration: <small class="text-info">Enter in Minutes</small></label>
                    <input #durationInput [(ngModel)]="durationNO" OnlyNumbers type="number"  class="form-control form-control-sm" (input)="durationChanged($event.target.value)">
    
                  </div>
                </div>
                <div class="col-sm-6">
                  <label for="endTime">Timer</label>
                  <div class="d-flex">
                    <input type="text" id="stopwatchFieldRPM1"
                    class="form-control form-control-sm text-dynamic-secondary-2c" readonly placeholder="00:00:00">
                    <button *ngIf="stopWatchInterval" class="btn btn-dynamic-2c my-0 mr-0 px-2 btn-sm " (click)="ResetStopWatch()">Stop </button>
                    <button *ngIf="!stopWatchInterval" class="btn btn-dynamic-2c my-0 mr-0 px-2 btn-sm " (click)="startStopWatch()">Start </button>
                  </div>
                </div>
    
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Care Coordinator <sup class="text-danger">*</sup> </label>
                    <ng-select [searchable]="true" [loading]="isLoadingPayersList" class="ng-select-small"
                      [(ngModel)]="addHesEncounterDto.clinicalCoordinatorId" placeholder="Select coordinator"
                      (click)="$event.stopPropagation();">
                      <ng-option *ngFor="let item of (facilityUsersList | filterFUsersByRole: 'Care Coordinator')"
                        [value]="item.id">{{item.lastName}}, 
     {{item.firstName}}
                      </ng-option>
                    </ng-select>
                  </div>
                </div>
                
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Billing Provider <sup class="text-danger">*</sup> </label>
                    <ng-select [searchable]="true" [loading]="isLoadingPayersList" class="ng-select-small"
                      [(ngModel)]="addHesEncounterDto.billingProviderId" placeholder="Select Billing Provider"
                      (click)="$event.stopPropagation();">
                      <ng-option *ngFor="let item of (facilityUsersList | filterFUsersByRole: 'Billing Provider')"
                        [value]="item.id">{{item.lastName}}, {{item.firstName}}
                      </ng-option>
                    </ng-select>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Service Name <sup class="text-danger">*</sup> </label>
                    <ng-select [searchable]="true" class="ng-select-small" [disabled]="addHesEncounterDto.id != 0"
                      [(ngModel)]="addHesEncounterDto.hesType" placeholder="Select Service Type"
                      (click)="$event.stopPropagation();">
                      <!-- <ng-option [value]="item.value" *ngFor="let item of serviceTypes">{{item.name}}</ng-option> -->
                      <ng-option *ngIf="hesClaims.ChiService" [value]="0">CHI</ng-option>
                      <ng-option *ngIf="hesClaims.PinService" [value]="1">PIN</ng-option>
                      <ng-option *ngIf="hesClaims.PinPeerService" [value]="2">PIN Peer</ng-option>
                    </ng-select>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-form form-group">
                    <label for="form12">Notes <sup class="text-danger">*</sup> </label>
                    <!-- <textarea type="text" style="min-height: 200px;" class="form-control form-control-sm" id="form12"
                      rows="6" [(ngModel)]="addHesEncounterDto.note"></textarea> -->
                      <app-two-c-text-area *ngIf="addHesEncounterDto?.patientId" #myFIeldRefHESQuick [value]="addHesEncounterDto.note" [height]="'140px'" [PatientId]="addHesEncounterDto?.patientId" (valueChanged)="addHesEncounterDto.note=$event"></app-two-c-text-area>
                    <!-- <div class="invalid invalid-text">
                        <small *ngIf="encounterTimeForm.get('note').invalid && encounterTimeForm.get('note')?.touched"
                          class="text-danger">Please write some note.</small>
                      </div> -->
                  </div>
                  <div #alert *ngIf="addHesEncounterDto.monthlyStatus === hesMonthlyStatusEnum['Not Started']"
                    class="alert alert-warning alert-dismissible animated fade show" role="alert">
                    <strong >Acknowledge/update monthly status to save</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="modal-footer" *ngIf="!onlyEditCarePlanView">
        <div>
          <div class="d-flex align-items-center  p-1">
            <p class="m-0">Monthly Status:</p>
            <div style="padding: 2px;" class=" ml-3 border rounded" [class.border-success]=" (addHesEncounterDto.monthlyStatus !== hesMonthlyStatusEnum['Not Started'])"
            [class.border-warning]="(addHesEncounterDto.monthlyStatus === hesMonthlyStatusEnum['Not Started'])">
              <div class="btn-group cursor-pointer"  mdbDropdown>
                <mdb-badge (click)="disabledAddEncounterButton=false" style="line-height: 14px;"  mdbDropdownToggle success="true" >{{PatientEncounterMonthlyStatusTExt}}</mdb-badge>
  
                <div class="dropdown-menu status-dropdown" style="bottom: 50px;top: unset;left: -3px;">
                  <div>
                    <!-- <span [ngStyle]="{'backgroupcmMOdelDatand': item.color}"></span> -->
                    <a [class.active]="1 === value" (click)="addHesEncounterDto.monthlyStatus=item.value;PatientEncounterMonthlyStatusTExt=item.name;" *ngFor="let item of hesMonthlyStatusEnumList" class="dropdown-item">{{item.name}}</a>
                  </div>
  
                </div>
              </div>
            </div>
  
          </div>

        </div>
        <div >
          <button *ngIf="addHesEncounterDto.id === 0"
            [disabled]="IsaddingEncounterLoading || (addHesEncounterDto.monthlyStatus === hesMonthlyStatusEnum['Not Started']) || !durationNO  || !addHesEncounterDto.note || !addHesEncounterDto.startTime || !addHesEncounterDto.endTime
                     || !addHesEncounterDto.clinicalCoordinatorId || !addHesEncounterDto.billingProviderId || addHesEncounterDto.hesType == null "
            type="submit" (click)="addHesEncounter(hesEncounterModal)" mdbBtn class="btn btn-dynamic-2c btn-sm m-o">
            <span *ngIf="IsaddingEncounterLoading" class="spinner-border spinner-border-sm" role="status"
              aria-hidden="true"></span>
            Add Encounter</button>
          <button *ngIf="addHesEncounterDto.id != 0"
            [disabled]="IsaddingEncounterLoading || (addHesEncounterDto.monthlyStatus === hesMonthlyStatusEnum['Not Started']) || !durationNO  || !addHesEncounterDto.note || !addHesEncounterDto.startTime || !addHesEncounterDto.endTime
                     || !addHesEncounterDto.clinicalCoordinatorId || !addHesEncounterDto.billingProviderId"
            type="submit" (click)="editHesEncounter(hesEncounterModal)" mdbBtn class="btn btn-dynamic-2c btn-sm m-o">
            <span *ngIf="IsaddingEncounterLoading" class="spinner-border spinner-border-sm" role="status"
              aria-hidden="true"></span>
            Edit Encounter</button>
        </div>
      </div>
    </div>
  </div>
</div>
