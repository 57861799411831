<div class="login">
  <div class="login-body">
    <div class="w-100">
      <div class="text-center mb-5">
        <!-- <img src="/assets/img/logo3.svg" style="max-width: 300px" alt="2C health Solutions"> -->
      <img src="{{brandingService.logoPath}}logo-1024-265.png" style="max-width: 300px" alt="{{brandingService.appTitle}}">

      </div>
      <div *ngIf="!resetCode && !verificationCodeView" class="login-box">
        <!-- <strong>Sign In</strong> -->
        <p>Did you forget your password?</p>
        <div>
          <div class="alert alert-success" *ngIf="successAlertMessage">
            {{successAlertMessage}}
          </div>
          <div class="alert alert-warning" *ngIf="errorAlertMessage">
            {{errorAlertMessage}}
          </div>
          <div class="form-group">
            <label for="defaultresetPassFormEmail">Email or User Name</label>
            <input #userNameF="ngModel" type="text" id="defaultresetPassFormEmail" [(ngModel)]="userName" #userName1
              [ngModelOptions]="{standalone: true}" class="form-control" placeholder="E-mail or User Name"
              [ngClass]="{'is-invalid': !userName && userNameF.touched}">

            <span class="invalid-feedback">
              <span *ngIf="!userName && userNameF.touched">
                Please enter your username.
              </span>
            </span>
          </div>
          <div class="form-group">
            <label for="defaultresetPassFormEmail">Send a password reset code to:</label>
            <br>
            <div *ngIf="userPhoneNumber" class="form-check form-check-inline mb-2">
              <input [disabled]="!isPhoneVerified" type="radio" class="form-check-input" id="materialInline1" name="sms"
                [(ngModel)]="sendCodeMethod" [value]="'phone'" mdbInput>
              <label class="form-check-label"  for="materialInline1">{{userPhoneNumber}}</label>
            </div>

            <!-- Material inline 2 -->
            <div *ngIf="userEmail" class="form-check form-check-inline mb-2">
              <input [disabled]="!isEmailVerified" type="radio" class="form-check-input" id="materialInline2" name="email"
                [(ngModel)]="sendCodeMethod" [value]="'email'" mdbInput>
              <label class="form-check-label" for="materialInline2">{{userEmail}}</label>
            </div>
          </div>
          <div class="d-flex justify-content-end align-items-center mb-3">
            <a (click)="goBack();" class="links w-100">Go Back</a>
            <button type="submit" [disabled]="!userNameF.value || !sendCodeMethod || sendingCode" class="btn btn-md btn-dynamic-2c ml-0"
              (click)="forgetEmail();userNameF.reset();">
              <span *ngIf="sendingCode" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
              Submit</button>
          </div>


        </div>
      </div>
      <div *ngIf="!resetCode && verificationCodeView" class="login-box">
        <form>
          <div class="alert alert-success" *ngIf="successAlertMessage">
            {{successAlertMessage}}
          </div>
          <div class="alert alert-warning" *ngIf="errorAlertMessage">
            {{errorAlertMessage}}
          </div>
          <div class="form-group mb-5">
            <label for="twofacCOdeid">Enter Verification Code</label>
            <input #verificationCOdeRef="ngModel" autofocus type="text" class="form-control" [(ngModel)]="verificationCode" name="auth" id="twofacCOdeid"
              required="">
          </div>
          <div class="d-flex justify-content-end align-items-center mb-3">
            <a (click)="verificationCOdeRef.reset(); resetView();" class="links w-100">Go Back</a>
            <button type="submit" [disabled]="!verificationCode || verifingCode" class="btn btn-md btn-dynamic-2c ml-0" (click)="VerifyUserCode()">
              <span *ngIf="verifingCode" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
              Submit</button>
          </div>


        </form>
      </div>
      <div *ngIf="resetCode" class="login-box">
        <!-- <strong>Sign In</strong> -->
        <p>Enter your new password?</p>
        <div [formGroup]="resetPassForm">
          <div class="alert alert-success" *ngIf="successAlertMessage">
            {{successAlertMessage}}
            <!-- <a class="link text-info" [routerLink]="['/login']"
              routerLinkActive="router-link-active"><strong>Please Login</strong></a> -->
          </div>
          <div class="alert alert-warning" *ngIf="errorAlertMessage">
            {{errorAlertMessage}}
          </div>
          <div class="form-group">
            <label for="defaultresetPassFormPassword">Password</label>
            <input type="password" id="defaultresetPassFormPassword" formControlName="password" class="form-control"
              placeholder="Password" [ngClass]="{'is-invalid': (resetPassForm.get('password')?.touched
                                            || resetPassForm.get('password')?.dirty)
                                            && !resetPassForm.get('password')?.valid }">
            <span class="invalid-feedback">
              <span *ngIf="!!resetPassForm.get('password').errors?.['required']">
                Enter your password.
              </span>
            </span>
          </div>
          <div class="form-group">
            <label for="defaultresetPassFormEmail">Confirm Password</label>
            <input type="password" id="defaultresetPassFormEmail" formControlName="confirmPassword" class="form-control"
              placeholder="verify Password"
              [ngClass]="{'is-invalid': (!resetPassForm.get('confirmPassword')?.value || resetPassForm.get('confirmPassword')?.value !== resetPassForm.get('password')?.value) && (resetPassForm.get('confirmPassword')?.touched)}">
            <span class="invalid-feedback"
              *ngIf="!resetPassForm.get('confirmPassword')?.value || (resetPassForm.get('confirmPassword')?.value !== resetPassForm.get('password')?.value)">
              Password must be mached.
            </span>
          </div>
          <div class="d-flex justify-content-between align-items-center mb-3">
            <button type="submit"
              [disabled]="!resetPassForm.valid || resetPassForm.get('confirmPassword')?.value !== resetPassForm.get('password')?.value"
              (click)="resetPassword()" class="btn btn-md btn-dynamic-2c ml-0 w-100">Submit</button>
          </div>


        </div>
      </div>

    </div>
  </div>

</div>
