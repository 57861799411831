import { Directive, ElementRef, HostListener } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appWebUrlValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: WebUrlValidatorDirective, multi: true }]
})
export class WebUrlValidatorDirective implements Validator {

  private regex: RegExp = new RegExp(/^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}[\/\w .-]*\/?$/);


  constructor(private el: ElementRef) {}

  // @HostListener('input', ['$event']) onInputChange(event: any) {
  //   let initialValue = this.el.nativeElement.value;

  //   // Restrict characters based on the regex
  //   this.el.nativeElement.value = initialValue.replace(/[^a-zA-Z0-9@._-]/g, '');
  //   this.el.nativeElement.value = initialValue.replace(/[^a-zA-Z0-9@._-/:\/_-]/g, '');

  //   if (initialValue !== this.el.nativeElement.value) {
  //     event.stopPropagation();
  //   }
  // }

  validate(control: AbstractControl): ValidationErrors | null {
    const isValid = this.regex.test(control.value);
    const inputElement: HTMLInputElement = this.el.nativeElement;
    if (control.value && !isValid && inputElement.nodeName == 'INPUT') {
      inputElement.classList.add('border-danger')
    }
    if (control.value && isValid && inputElement.nodeName == 'INPUT') {
      inputElement.classList.remove('border-danger')
    }
    return isValid ? null : { invalidWebUrl: true }
  }
}
