export class State {
  id = 0 ;
  code = '';
  name = '';
  country = null;
  CountryId = '';
  password = '';
}


export enum LaunchModeEnum {
  AppUrl = 0,
  SinglePatient = 1,
  GlobalContext = 2,
}

export class UserSettings {
  static soundMuted = false;
}