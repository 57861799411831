<div class="d-flex align-items-center flex-wrap justify-content-between mb-2">
    <h1 class="page-title flex-sm-grow-1 mb-0">Churn Analysis</h1>
    <div class="d-flex align-items-end flex-wrap justify-content-end">
        <div class="d-flex align-items-end justify-content-end">
            <div class="input-group mr-1 mb-0" style="width: 150px;">
                <label f class="mb-0 mr-1 " or="namea"><strong> From</strong></label>
                <div class="input-group mb-0">
                    <input readOnly style="height:32px;width:100px;" type="text"
                        value="{{patientChurnAnalysisReportDto.from | date: 'MMM yyyy'}}" class="form-control bg-white"
                        autocomplete="off">
                    <div class="input-group-append ">
                        <span style="cursor: pointer; border-radius: 0 4px 4px 0; border-color: #ced4da;"
                            [dpDayPicker]="datePickerConfig2" [theme]="'dp-material ccm-date-picker'" [mode]="'month'"
                            (onSelect)="filterByMonth($event, 'from')" class="input-group-text bg-white">
                            <i class="las la-calendar fa-lg"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="input-group mr-1 mb-0" style="width: 150px;">
                <label class="mb-0 mr-1 w-100" or="namea"><strong> To</strong></label>
                <div class="input-group mb-0">
                    <input readOnly style="height:32px;width:100px;" type="text"
                        value="{{patientChurnAnalysisReportDto.to | date: 'MMM yyyy'}}" class="form-control bg-white"
                        autocomplete="off">
                    <div class="input-group-append ">
                        <span style="cursor: pointer;border-radius: 0 4px 4px 0;border-color: #ced4da;"
                            [dpDayPicker]="datePickerConfig2" [theme]="'dp-material ccm-date-picker'" [mode]="'month'"
                            (onSelect)="filterByMonth($event, 'to')" class="input-group-text bg-white">
                            <i class="las la-calendar fa-lg"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="form-group ml-0 mb-0 mr-1" style="width: 150px;">
                <label f class="mb-0 mr-1 " or="namea"><strong> Service</strong></label>
                <ng-select style="min-width:150px;" class="ng-select-small " [clearable]="false" appendTo="body"
                    [(ngModel)]="patientChurnAnalysisReportDto.serviceType" [multiple]="false" [searchable]="true"
                    placeholder="Services">
                    <ng-option *ngFor="let item of twoCModulesList" [value]="item.value">{{item.name}}</ng-option>
                </ng-select>
            </div>
            <div *hasClaim="'IsAppAdmin'" class="form-group ml-0 mb-0 mr-1" style="width: 150px;">
                <label f class="mb-0 mr-1 " or="namea"><strong> Facilities</strong></label>
                <ng-select style="min-width:150px;" class="ng-select-small "
                    [(ngModel)]="patientChurnAnalysisReportDto.facilityId" [clearable]="false" appendTo="body"
                    [loading]="isLoadingFacilities" [multiple]="false" [searchable]="true" placeholder="Facility">
                    <ng-option *ngFor="let item of facilityList" [value]="item.id">{{item.facilityName}}</ng-option>
                </ng-select>
            </div>

            <button
                [disabled]="isLoadingReport || !patientChurnAnalysisReportDto.from || !patientChurnAnalysisReportDto.to || patientChurnAnalysisReportDto.facilityId == null || patientChurnAnalysisReportDto.serviceType == null"
                (click)="getPatientChurnAnalysisReport()" class="btn btn-dynamic-2c btn-sm mr-1  ml-0 align-items-center"
                style="margin: 0px;height: 30px;">Load <span *ngIf="isLoadingReport"
                    class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                </button>
            <button (click)="resetFilters()" class="btn btn-dynamic-secondary-2c btn-sm my-0 flex-shrink-0"
                style="margin: 0px;height: 30px;">Reset </button>
        </div>
    </div>
</div>


<div class="table-data-list mt-3">
    <div class="d-flex justify-content-between align-items-end mb-2">
        <div class="dataTables_length " id="example_length">
            <label class="mb-0">Show
                <select [(ngModel)]="count" name="example_length" aria-controls="example" class="">
                    <option [value]="10">10</option>
                    <option [value]="25">25</option>
                    <option [value]="50">50</option>
                    <option [value]="100">100</option>
                </select> entries</label>
        </div>
        <div class="d-inline-flex">
            <button  class="btn btn-dynamic-2c btn-sm m-0 mr-1" title="Last Three Months History" [disabled]="!patientChurnAnalysisReportDto.from || !patientChurnAnalysisReportDto.to || patientChurnAnalysisReportDto.facilityId == null || patientChurnAnalysisReportDto.serviceType == null" (click)="fillFromAndToDate('3Months');historyModal.show()">
                <i class="fas fa-eye mr-1"></i>
                View All
            </button>
            <button [disabled]="!churnReportList.length || isLoadingReport" type="button" class="btn btn-dynamic-2c btn-sm m-0"
                (click)="makeExcelForChurnReport()">Download
            </button>
        </div>
    </div>
    
    <div *ngIf="isLoadingReport" class="d-flex justify-content-center text-center">
        <mdb-progress-bar class="progress primary-color-dark-s mb-0" mode="indeterminate"></mdb-progress-bar>
    </div>

    <ngx-datatable class="material" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="40"
        [externalPaging]="false" [scrollbarV]="false" [scrollbarH]="true" [rows]="churnReportList" [limit]="count">

        <!-- Row Index Column -->
        <ngx-datatable-column name="#" [width]="30">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex">
                {{ rowIndex + 1 }}
            </ng-template>
        </ngx-datatable-column>

        <!-- Dynamically Generated Columns -->
        <ngx-datatable-column [width]="120" *ngFor="let column of columns" [name]="column.name" [prop]="column.prop"
            [sortable]="column.sortable">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                <div class="d-flex justify-content-between align-items-center">
                    {{ value }}

                    <button *ngIf="column.name == 'Month'" title="View History" class="btn btn-dynamic-2c btn-icon m-0 ml-2"
                        (click)="fillFromAndToDate('1Month',value);historyModal.show()">
                        <i class="las la-eye"></i>
                    </button>
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</div>
<div class="row">
    <div class="col-xl-12 pb-3 pt-3 border-left border-right border-top border-bottom mt-1"
        *ngIf="churnReportList?.length">
        <h5 class="bg-dynamic-2c p-2 mb-2"
            style="font-size: 18px;font-weight: 600;color:#fff;border-left:1px solid #fff;border-right:1px solid #fff;">
            Analysis Graph</h5>
        <div *ngIf="churnReportList?.length" class="panel-body p-0" style="display: block">
            <div *ngIf="isLoadingEgvs">
                <div class="d-flex justify-content-center text-center">
                    <mdb-progress-bar class="progress primary-color-dark-s mb-0"
                        mode="indeterminate"></mdb-progress-bar>
                </div>
            </div>
            <div class="bg-white" style="position: relative;">
                <canvas baseChart [type]="'line'" style="min-height: 185px;" [datasets]="chartDataSet"
                    [labels]="chartLabels" [options]="chartOptions" [legend]="true" [colors]="ReportChartColors">
                </canvas>
            </div>
        </div>
    </div>
</div>



<div mdbModal #historyModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="myBasicModalLabel" aria-hidden="true" (close)="resetStatusHistory()">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="historyModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title w-100" id="myModalLabel">
                    History
                </h4>
            </div>
            <div class="modal-body">
                <div class="form-group mb-3">
                    <label>Search</label>
                    <input type="text" class="form-control" (input)="filterPatientInStatusHistory($event.target.value)"
                        placeholder="Search by Name">
                </div>
                <div class="d-flex justify-content-center text-center">
                    <mdb-progress-bar *ngIf="isLoadingStatusHistory" class="progress primary-color-dark-s mb-0"
                        mode="indeterminate"></mdb-progress-bar>
                </div>
                <ngx-datatable #table class="material z-depth-0" appRecalculateNgxTable [columnMode]="'force'"
                    [headerHeight]="50" [footerHeight]="50" [rowHeight]="40" [scrollbarV]="false" [scrollbarH]="true"
                    [rows]="statusHistoryList" [offset]="0" [limit]="10">
                    <ngx-datatable-column name="#" [maxWidth]=30>
                        <ng-template ngx-datatable-cell-template let-value="value" let-row="row"
                            let-rowIndex="rowIndex">
                            {{rowIndex + 1}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Patient Name" prop="patientName">
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Status" prop="status">
                        <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                            <span *ngIf="patientChurnAnalysisReportDto.serviceType == twoCModulesEnum.CCM">
                                {{ccmStatusEnum[row.status]}}
                            </span>
                            <span *ngIf="patientChurnAnalysisReportDto.serviceType == twoCModulesEnum.RPM">
                                {{rpmStatusEnum[row.status]}}
                            </span>
                            <span *ngIf="patientChurnAnalysisReportDto.serviceType == twoCModulesEnum.BHI">
                                {{bhiStatusEnum[row.status]}}
                            </span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Change Date" prop="updatedDateTime">
                        <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                            {{row.updatedDateTime | dateFormatPipe}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Reason" prop="reason">
                    </ngx-datatable-column>
                </ngx-datatable>
            </div>
            <div class="modal-footer">
                <button type="button" class="waves-light btn btn-sm btn-dynamic-secondary-2c" aria-label="Close"
                    (click)="historyModal.hide()" mdbWavesEffect>Close</button>
            </div>
        </div>
    </div>
</div>