<div mdbModal #ScoreHealthFormModal="mdbModal" class="modal fade" tabindex="-1" (close)="showFormsListView=true"
  role="dialog" aria-labelledby="myScoreHealthFormModalLabel" aria-hidden="true" (close)="resetFormsList()">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="ScoreHealthFormModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100 text-centerd" myModalLabel>Health Form</h4>
      </div>
      <div class="modal-body">
        <ng-container *ngTemplateOutlet="healthFormsListTemplate"></ng-container>
      </div>
      <div class="modal-footer d-flex justify-content-between align-content-center">
        <div *ngIf="selectedOptionToAddress?.length && isAllAddressed()">
          <div *ngIf="!showFormsListView" class="btn-group cursor-pointer" mdbDropdown>
            <mdb-badge style="line-height: 14px;" mdbDropdownToggle success="true">
              Action
            </mdb-badge>

            <div class="dropdown-menu status-dropdown" style="bottom: 50px;top: unset;left: -3px;">
              <div>
                <a [disabled]="!selectedOptionToAddress.length" (click)="addressMultipleOptions()"
                  class="dropdown-item">Create Encounter Selected</a>
                <a [disabled]="!selectedOptionToAddress.length" (click)="addressedOptions()"
                  class="dropdown-item">Archive Selected</a>
              </div>

            </div>
          </div>
        </div>
        <!-- <div>
          <button [disabled]="!selectedOptionToAddress.length" *ngIf="!showFormsListView" type="button" (click)="addressMultipleOptions()" class="relative btn btn-sm btn-dynamic-2c">Create Encounter Selected</button>
          <button [disabled]="!selectedOptionToAddress.length" *ngIf="!showFormsListView" type="button" (click)="addressedOptions()" class="relative btn btn-sm btn-dynamic-2c">Archive Selected</button>
        </div> -->
        <div>
          <!-- <button *ngIf="!showFormsListView" (click)="showFormsListView=true" type="button" class="relative btn btn-sm btn-dynamic-2c">Back</button> -->
          <button *ngIf="!showFormsListView" type="button" class="btn btn-sm btn-dynamic-secondary-2c m-0"
            aria-label="Close" (click)="showFormsListView=true">Back</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div mdbModal #HealthScoreModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myHealthScoreModalLabel" aria-hidden="true" (close)="resetFormDetails()">
  <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="HealthScoreModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100 text-centerd" myModalLabel>Health Score Form <span
            class="badge badge-info mr-1">{{CustomFormStatusEnum[phsFormDto?.status]}} <span
              *ngIf="phsFormDto?.submittedDate">{{phsFormDto?.submittedDate | dateFormatPipe}}</span></span></h4>
      </div>
      <mdb-progress-bar *ngIf="false" class="progress primary-color-dark mb-0" mode="indeterminate"></mdb-progress-bar>
      <div class="modal-body px-4">
        <p *ngIf="false">No record found</p>
        <h4 class="mt-0">{{phsFormDto.title}}</h4>
        <div id="hsf-{{iq}}" [class.disabledDiv]="phsFormDto?.status == CustomFormStatusEnum.Submitted"
          *ngFor="let question of phsFormDto?.phsFormQuestionRecords;let iq = index" class="checkbox-question">
          <p [title]="question.toolTip || ''"><strong>{{iq + 1}}. {{question?.description}}</strong><sup
              *ngIf="question.isMandatory" class="text-danger">*</sup> </p>
          <div *ngIf="question.questionType == CustomFormQuestionTypeEnum['Multi Select']" class="form-group">
            <div *ngFor="let item of question.phsFormOptionRecords;let i = index"
              class="custom-control custom-checkbox ml-4">
              <input type="checkbox" [disabled]="isSignatured" [(ngModel)]="item.isSelected"
                (change)="updateCheckBoxOptionValue($event, item, iq)" class="custom-control-input"
                [id]="'checkbox1'+ question.id + i">
              <label class="custom-control-label" [for]="'checkbox1'+ question.id + i">{{item?.text}}</label>
            </div>
          </div>
          <div
            *ngIf="question.questionType == CustomFormQuestionTypeEnum['Single Select'] || question.questionType == CustomFormQuestionTypeEnum['Linear Scale'] || question.questionType == CustomFormQuestionTypeEnum['Yes/No']"
            class="form-group">
            <div class="form-group" style="margin-left: 20px;">
              <div *ngFor="let item of question?.phsFormOptionRecords;let i = index" class="form-check pl-0">
                <input [disabled]="isSignatured" type="radio" [(ngModel)]="question.radioOptionValue"
                  (ngModelChange)="updateRadioOptionValue(question, item, iq)" [value]="item.id"
                  class="form-check-input" [name]="'describe' + question.id" [id]="'radio1'+ question.id + i">
                <label class="form-check-label" [for]="'radio1'+ question.id + i">{{item?.text}}</label>
              </div>
            </div>
          </div>
          <div *ngIf="question.questionType == CustomFormQuestionTypeEnum.Descriptive" class="form-group">
            <div class="form-group" style="margin-left: 20px;">
              <!-- <label for="">question.radioOptionValue<sup class="text-danger">*</sup></label> -->
              <ng-container *ngIf="selectedPatient?.id">
                <app-two-c-text-area *ngFor="let item of question.phsFormOptionRecords;let i = index"
                  [value]="item.text" [height]="'200px'" [PatientId]="selectedPatient?.id"
                  (valueChanged)="item.text=$event;updateDescriptiveOptionText(question, item, iq)"></app-two-c-text-area>
              </ng-container>
              <!-- <textarea *ngFor="let item of question.phsFormOptionRecords;let i = index" type="text" id="form77q68" [(ngModel)]="item.text" (ngModelChange)="updateDescriptiveOptionText(question, item, iq)" placeholder="Enter Text" rows="4"
              class="form-control" required></textarea> -->
            </div>
          </div>
        </div>
        <div *ngIf="phsFormDto?.cptCode" class=" p-3 border bg-white rounded mb-2">
          <div class="d-flex align-items-center mb-2">
            <div class="d-flex flex-1 mr-2" [class.disabledDiv]="phsFormDto?.status == CustomFormStatusEnum.Submitted">
              <label style="min-width: 90px;" class="mb-0 mt-1 mr-3">Billing Provider</label>
              <div class="flex-grow-1">
                <ng-select appendTo="body" [(ngModel)]="billingProviderId" [loading]="isLoadingCptCharges"
                  class="ng-select-small ng-select-small-myforms" [multiple]="false" [searchable]="true"
                  [clearable]="false" placeholder="Billing Provider">
                  <ng-option [value]="item.id"
                    *ngFor="let item of (facilityUsersList | filterFUsersByRole: 'Billing Provider')">{{item.lastName}},
                    {{item.firstName}}</ng-option>
                </ng-select>
              </div>
            </div>
            <div class="d-flex flex-1 ml-2">
              <label style="min-width: 90px;" class="mb-0 mt-1 mr-3">ICD Codes</label>
              <div class="flex-grow-1">
                <ng-select [(ngModel)]="icdCodesArray" [loading]="LoadingData" [typeahead]="searchWatch" appendTo="body"
                  class="ng-select-small ng-select-small-myforms" [multiple]="true" [searchable]="true"
                  [clearable]="false" placeholder="ICD Codes">
                  <ng-option [value]="item.code" *ngFor="let item of chronicDiseasesByUrl">{{item.code}}
                    {{item.detail}}</ng-option>
                </ng-select>
              </div>
            </div>
            <div class="d-flex flex-1 ml-2">
              <label style="min-width: 90px;" class="mb-0 mt-1 mr-3">CPT Code</label>
              <div class="flex-grow-1">
                <ng-select appendTo="body" [(ngModel)]="phsFormDto.cptCode" [loading]="isLoadingCptCharges"
                  class="ng-select-small ng-select-small-myforms" [multiple]="false" [searchable]="true"
                  [clearable]="false" placeholder="CPT Code">
                  <ng-option [value]="item.cptCode" *ngFor="let item of cptCargesList">{{item.cptCode}}
                    ({{item.category}} - {{item.subCategory}})</ng-option>
                </ng-select>
              </div>
            </div>
          </div>
          <div>
            <label for="note" class="mb-0 mt-1 mr-3">Encounter Claim Note</label>
            <div class="flex-grow-1">
              <!-- <textarea [(ngModel)]="phsFormDto.encounterClaimNote" class="md-textarea form-control " rows="2" id="note" appOnDebounce [debounceTime]="1000" type="text"></textarea> -->
              <app-two-c-text-area *ngIf="selectedPatient?.id" #myFIeldRefClaimNaot
                [value]="phsFormDto.encounterClaimNote" [height]="'140px'" [PatientId]="selectedPatient?.id"
                (valueChanged)="phsFormDto.encounterClaimNote=$event"></app-two-c-text-area>
            </div>
          </div>
        </div>
        <div *ngIf="phsFormDto.scoreableScript" class="p-3 border bg-white rounded mb-2">
          <div>
            <label for="note" class="mb-0 mt-1 mr-3">Score <span
                class="badge badge-info">{{phsFormDto.score}}</span></label>
            <div class="flex-grow-1">
              <textarea [(ngModel)]="phsFormDto.scoreableNote" class="md-textarea form-control " rows="2" id="note"
                appOnDebounce [debounceTime]="1000" type="text"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-between align-content-center">
        <!-- <button type="button" class="relative btn btn-sm btn-dynamic-2c">Publish</button> -->
        <!-- <button [class.disabled]="phsFormDto?.status == CustomFormStatusEnum.Submitted || submittingForm || !billingProviderId || !isMandatoryFieldsFilled" (click)="openConfirmSubmitFormModal()" type="button" class="waves-light btn btn-sm btn-dynamic-2c" >Submit</button> -->
        <button [class.disabled]="submittingForm || !billingProviderId || !isMandatoryFieldsFilled"
          (click)="openConfirmSubmitFormModal()" type="button"
          class="waves-light btn btn-sm btn-dynamic-2c">Submit</button>
        <button [class.btn-danger]="scoreChanged" *ngIf="phsFormDto.scoreableScript" (click)="RunTextScript()"
          type="button" class="waves-light btn btn-sm btn-dynamic-2c">Execute Script</button>
        <button (click)="HealthScoreModal.hide()" type="button" class="waves-light btn btn-sm btn-dynamic-secondary-2c"
          aria-label="Close">Close</button>
      </div>
    </div>
  </div>
</div>
<div mdbModal #HealthScoreServiceFormsModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  (close)="selectedCustomFormId=null" aria-labelledby="myHealthScoreServiceFormsModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="HealthScoreServiceFormsModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100 text-centerd" myModalLabel>Health Score Forms</h4>
      </div>
      <mdb-progress-bar *ngIf="false" class="progress primary-color-dark mb-0" mode="indeterminate"></mdb-progress-bar>
      <div class="modal-body px-4">
        <h5 style="font-weight: 500;font-size: 16px;" *ngIf="customFormsList?.length !== 0">Please Select Option</h5>
        <ul class="complete-text-list-score">
          <li *ngFor="let item of customFormsList" (click)="selectedCustomFormId=item.id"><a title="{{item?.title}}"
              [class.active]="item.id==selectedCustomFormId"><span></span>{{item?.title}}</a></li>
        </ul>
        <p *ngIf="!customFormsList?.length">No record found</p>


      </div>
      <div class="modal-footer d-flex justify-content-between align-content-center">
        <button [disabled]="!selectedCustomFormId || addingPHSForm" type="button" (click)="addPHSFormRecord()"
          class="waves-light btn btn-sm btn-dynamic-2c" aria-label="Close">Proceed</button>
      </div>
    </div>
  </div>
</div>
<div mdbModal #viewPdfModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel" [config]="{backdrop: true, ignoreBackdropClick: true}" aria-hidden="true"
  (close)="objectURLStrAW=''">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-body" style="height:calc(100vh - 50px); padding: 0px;">
        <button type="button" class="close pull-right document-close" aria-label="Close" (click)="viewPdfModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <app-pdf-view *ngIf="objectURLStrAW" [documentUrl]="objectURLStrAW"></app-pdf-view>
      </div>
    </div>
  </div>
</div>

<ng-template #healthFormsListTemplate>
  <div class="text-right">
    <button type="button" *ngIf="showFormsListView"
      (click)="getCustomFormsList();HealthScoreServiceFormsModal.show()"
      class="btn btn-sm btn-dynamic-2c mr-0">New Form</button>
  </div>
  <div *ngIf="showFormsListView" class="table-responsive mt-2"
    style="max-height: calc(100vh - 350px);overflow: auto;">
    <mdb-progress-bar *ngIf="isLoadingRecords" class="progress primary-color-dark-s mb-0"
      mode="indeterminate"></mdb-progress-bar>
    <table class="table table-bordered">
      <thead class="bg-dynamic-2c text-white" style="position: sticky;top: 0;z-index: 111;">
        <tr>
          <th min-width="30">#</th>
          <th style="width: 120px;">Form</th>
          <th style="min-width: 110px;">Date</th>
          <th>Progress</th>
          <th style="width: 100px;">Status</th>
          <th style="width: 100px;">Score</th>
          <th style="width: 100px;">Critical</th>
          <th style="width: 100px;">Follow Up</th>
          <th>Action</th>

        </tr>
      </thead>
      <tbody>
        <tr *ngIf="!PHSFormsList?.length && !isLoadingRecords">
          <td colspan="8">No Record Found</td>
        </tr>
        <tr *ngFor="let item of PHSFormsList; let i = index">
          <td width="30">{{i + 1}}</td>
          <td [class.disabledDiv]="item.status != CustomFormStatusEnum.Submitted"
            (click)="getPHSFormById(item.id, 'all')"><a class="text-dynamic-2c" title="{{item.title}}" style="
            font-size: 12px;
              display: block;
              font-weight: 600;
              width: 110px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;">{{item.title}}</a></td>
          <td>{{item.submittedDate | dateFormatPipe}}</td>
          <td class="text-center">
            <div class="d-flex justify-content-between align-items-center">
              <button type="button" (click)="getPHSFormByIdForDetails(item.id);HealthScoreModal.show()" title="View"
                class="btn btn-dynamic-2c btn-icon my-1 ml-0 position-relative">
                <i class="las la-play"></i>
              </button>
              <span class=" ">{{item.progress}}</span>
            </div>
            
          </td>
          <td class="text-center"><span class="badge" [class.badge-success]="item.status == 1"
              [class.badge-warning]="item.status == 0">{{CustomFormStatusEnum[item.status]}}</span></td>
          <td width="30"><span class="border-dark-circle">{{item.score}}</span></td>
          <td class="text-center">
            <span *ngIf="item.criticalCount == 0" class="text-danger cursor-pointer border-danger-circle">
              {{item.criticalCount}}
            </span>
            <span *ngIf="item.criticalCount > 0" (click)="getPHSFormById(item.id, 'critical')"
              class="text-danger cursor-pointer border-danger-circle">
              {{item.criticalCount}}
            </span>
          </td>
          <td class="text-center">
            <span *ngIf="item.followUpCount == 0" class="text-warning cursor-pointer border-warning-circle">
              {{item.followUpCount}}
            </span>
            <span *ngIf="item.followUpCount > 0" (click)="getPHSFormById(item.id, 'followUp')"
              class="text-warning cursor-pointer border-warning-circle">
              {{item.followUpCount}}
            </span>
          </td>
          <td class="text-center">
            <div class="action-button d-flex justify-content-center">
              <button [disabled]="item.status != 1" (click)="copyForm(item.id)" title="Copy"
                class="btn btn-dynamic-2c btn-icon my-0 ml-0 mr-1 position-relative"><i class="las la-copy"></i>
              </button>
              <button [disabled]="item.status == 1" type="button" (click)="sendFormToChat(item.id)" title="Send"
                class="btn btn-dynamic-2c btn-icon my-0 ml-0 mr-1 position-relative">
                <i class="las la-paper-plane"></i>
              </button>
              <button [disabled]="(item.status == 0 && item.cptCode)|| (item.status == 0 && !item.cptCode)"
                type="button" (click)="downloadPHSFormPdf(item.id)" title="Download"
                class="btn btn-dynamic-2c btn-icon my-0 ml-0 mr-1 position-relative">
                <i class="las la-download"></i>
              </button>
              <button type="button" (click)="openConfirmationModal(item.id)" title="Delete"
                class="btn btn-danger btn-icon my-0 mx-0 position-relative">
                <i class="las la-trash"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="isLoadingRecordsById" class="d-flex justify-content-center align-items-center">
    <div class=" logo-animation-custom">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          style="margin: auto; background:transperant; display: block; shape-rendering: auto;width:160px !important;"
          width="160px" height="160px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <circle cx="84" cy="50" r="10" fill="#4eaf48">
            <animate attributeName="r" repeatCount="indefinite" dur="0.4716981132075471s" calcMode="spline"
              keyTimes="0;1" values="10;0" keySplines="0 0.5 0.5 1" begin="0s" />
            <animate attributeName="fill" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="discrete"
              keyTimes="0;0.25;0.5;0.75;1" values="#4eaf48;#134389;#4eaf48;#134389;#4eaf48" begin="0s" />
          </circle>
          <circle cx="16" cy="50" r="10" fill="#4eaf48">
            <animate attributeName="r" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="spline"
              keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s" />
            <animate attributeName="cx" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="spline"
              keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s" />
          </circle>
          <circle cx="50" cy="50" r="10" fill="#134389">
            <animate attributeName="r" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="spline"
              keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.4716981132075471s" />
            <animate attributeName="cx" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="spline"
              keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.4716981132075471s" />
          </circle>
          <circle cx="84" cy="50" r="10" fill="#4eaf48">
            <animate attributeName="r" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="spline"
              keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.9433962264150942s" />
            <animate attributeName="cx" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="spline"
              keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.9433962264150942s" />
          </circle>
          <circle cx="16" cy="50" r="10" fill="#134389">
            <animate attributeName="r" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="spline"
              keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.4150943396226414s" />
            <animate attributeName="cx" repeatCount="indefinite" dur="1.8867924528301885s" calcMode="spline"
              keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84"
              keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.4150943396226414s" />
          </circle>
          <!-- [ldio] generated by https://loading.io/ -->
        </svg>
      </div>
    </div>
  </div>
  <div *ngIf="!showFormsListView && !isLoadingRecordsById">
    <div *ngIf="isAllAddressed()" class="custom-control custom-checkbox mb-2" style="margin-top: 2px;">
      <input class="custom-control-input" type="checkbox" id="selectAllCheckbox"
        (change)="selectAllQuestions($event.target.checked)">
      <label class="custom-control-label" for="selectAllCheckbox">Select All</label>
    </div>
    <div *ngFor="let question of needToAddressQuestionsList; let i = index"
      class="d-flex mb-3 justify-content-between align-items-start">
      <div>
        <div class="d-inline-flex align-items-start">
          <div *ngIf="!question?.option?.isAddressed" class="custom-control custom-checkbox"
            style="margin-top: 2px;">
            <input class="custom-control-input" type="checkbox" [id]="'question' + question.option.id+ i"
              [(ngModel)]="question.checked" (click)="selectOptionToAddress($event, question)">
            <label class="custom-control-label" [for]="'question' + question.option.id+ i"></label>
          </div>
          <span class="circle-15 mt-2 mr-2" [class.bg-danger]="question.option.flag == 3"
            [class.bg-warning]="question.option.flag == 2" style="margin-top: 6px;"></span>
          <div>
            <h4 class="mt-0">{{question.title}}</h4>
            <p>{{question.option.narration}}</p>
          </div>
        </div>
      </div>
      <div style="min-width: 150px;" class="text-right" *ngIf="!question?.option?.isAddressed">
        <button class="btn btn-dynamic-2c btn-sm" (click)="addressSingleOption(question)">Create
          Encounter</button>
        <button class="btn btn-dynamic-2c btn-sm" (click)="addressedOptions(question)">Archive</button>
      </div>
      <div style="min-width: 150px;" class="text-right" *ngIf="question?.option?.isAddressed">
        <div><span class="badge badge-success">{{question?.option?.addressedBy}}</span></div>
        <p style="font-size: 14px;">{{question?.option?.addressedDate | dateFormatPipe}}</p>
      </div>
    </div>
  </div>
</ng-template>
<div class="bg-white p-3" *ngIf="viewType == 'list'" >
  <ng-container *ngTemplateOutlet="healthFormsListTemplate"></ng-container>
</div>