import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TwoCModulesEnum } from 'src/app/model/AppModels/app.model';
import { FilterPreferenceDto } from 'src/app/model/common/filter-preferences.model';
import { AppUserAuth } from 'src/app/model/security/app-user.auth';
import { HttpErrorHandlerService } from 'src/app/shared/http-handler/http-error-handler.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FilterPreferencesService {

  baseUrl = localStorage.getItem('switchLocal') ? environment.localBaseUrl:  environment.baseUrl;
  securityObject: AppUserAuth = new AppUserAuth();

  constructor(private http: HttpClient, private router: Router, private httpErrorService: HttpErrorHandlerService) {}
  
  // GET /api/FilterPreferences
  getFilterPreferences(service?: TwoCModulesEnum, facilityUserId?: number) {
    let params = {};
    if (service !== undefined) params['service'] = service;
    if (facilityUserId !== undefined) params['facilityUserId'] = facilityUserId;

    return this.http.get(`${this.baseUrl}FilterPreferences`, { params });
  }

  // GET /api/FilterPreferences/{id}
  getFilterPreferenceById(id: TwoCModulesEnum) {
    return this.http.get(`${this.baseUrl}FilterPreferences/${id}`);
  }

  // POST /api/FilterPreferences
  createFilterPreference(data: FilterPreferenceDto) {
    return this.http.post(`${this.baseUrl}FilterPreferences`, data);
  }

  // PUT /api/FilterPreferences/{id}
  updateFilterPreference(id: number, data: FilterPreferenceDto) {
    const obj = {
      "id": id,
      "filter": data.filter,
      "name": data.name
    }
    return this.http.put(`${this.baseUrl}FilterPreferences`, obj);
  }

  // DELETE /api/FilterPreferences/{id}
  deleteFilterPreference(id: number) {
    return this.http.delete(`${this.baseUrl}FilterPreferences/${id}`);
  }

}
