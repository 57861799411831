import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetHESPatientsParamsDto, HESAssignRemovecareCoordinatorsDto, HESDashboardParamsDto, HesEncounterDto, HesPdfDownloadFilterDto, HesStatusChangeDto } from '../model/Hes/hes.model';
import { HttpErrorHandlerService } from '../shared/http-handler/http-error-handler.service';
import { SecurityService } from './security/security.service';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    //
  })
};
@Injectable({
  providedIn: 'root'
})
export class  HesService {

  baseUrl = localStorage.getItem('switchLocal') ? environment.localBaseUrl:  environment.baseUrl;

  constructor(private http: HttpClient, private securityService: SecurityService,
    private router: Router, private httpErrorService: HttpErrorHandlerService) {}

  // getPagedBills(month: number, year: number, FacilityId: number, pageNumb: number, pageSize: number) {
  //   return this.http.get(this.baseUrl + 'Billing/GetBills?Month=' + month + '&Year=' + year +
  //   '&FacilityId=' + FacilityId + '&PageNumber=' + pageNumb + '&PageSize=' + pageSize , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  // }
  HesGetPatients2(data: GetHESPatientsParamsDto) {
    let hesStatusVal = "";
    let hesStatusList = data.HESStatus;
    if (!hesStatusList && !hesStatusList.length) {
      hesStatusVal = "";
    } else {
      hesStatusList = hesStatusList.filter((x) => x !== (-1 as any));
      hesStatusVal = hesStatusList.toString();
    }
    let hesStatusMonthlyVal = "";
    let hesStatusMonthlyList = data.hesMonthlyStatus;
    if (!hesStatusMonthlyList && !hesStatusMonthlyList.length) {
      hesStatusMonthlyVal = "";
    } else {
      hesStatusMonthlyList = hesStatusMonthlyList.filter((x) => x !==  (-1 as any));
      hesStatusMonthlyVal = hesStatusMonthlyList.toString();
    }
    let hesTimeRangeVal = "";
    let hesTimeRangeList = data.HESEncounterTime;
    if (!hesTimeRangeList || !hesTimeRangeList.length) {
      hesTimeRangeVal = "";
    } else {
      hesTimeRangeList = hesTimeRangeList.filter(x => x !== (-1 as any));
      hesTimeRangeVal = hesTimeRangeList.toString();
    }
    return this.http
      .get(
        this.baseUrl +
          'Hes/GetPatients2?PageNumber=' +
          data.pageNumber +
          '&PageSize=' +
          data.pageSize +
          '&CustomListId=' +
          data.customListId +
          // '&HESType=' +
          // data.HESType +
          '&HESStatus=-1' +
          '&HESStatus_Fltr=' +
          (hesStatusVal || "") +
          '&HESEncounterTime=' +
          (hesTimeRangeVal || "")+
          '&HESMonthlyStatus=' +
          (hesStatusMonthlyVal || "")+
          '&HESEncounterType=' +
          (data.HESEncounterType > -1 ? data.HESEncounterType : "-1")+
        '&FacilityId=' +
          data.facilityId +
          // '&DiseaseId=' +
          // data.diseaseId +
          '&SortBy=' +
          data.sortBy +
          '&SortOrder=' +
          data.sortOrder +
          // tslint:disable-next-line: max-line-length
          '&SearchParam=' +
          data.searchParam +
          '&ServiceMonth=' +
          data.serviceMonth +
          '&ShowAll=' +
          data.showAll +
          `&CommunicationConsent=${data.communicationConsent}` +
          '&ServiceYear=' +
          data.serviceYear +
          '&Assigned=' +
          data.Assigned +
          '&BillingProviderId=' +
          data.hesBillingProviderId +
          '&CareCoordinatorId=' +
          data.hesCareCoordinatorId +
          '&DateAssignedFrom=' +
          data.dateAssignedFrom +
          "&DepartmentIds=" + 
          data.departmentIds +
          '&DateAssignedTo=' +
          data.dateAssignedTo
          ,
        httpOptions
      )
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  EditPatientHESMonthlyStatus(patientId: number, monthlyStatus: number) {
    var data = {
      patientId: patientId,
      newStatusValue: monthlyStatus,
      appUserId: this.securityService.securityObject.appUserId
    }
    return this.http.put(
        this.baseUrl + "HES/EditPatientHESMonthlyStatus",
        data,
        httpOptions
      )
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  changePatientHesStatus(data: HesStatusChangeDto) {
    return this.http
      .put(this.baseUrl + "HES/UpdateHESStatus", data, httpOptions)
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  getHesEncountersByPatientId(patientId: number, monthId: number, yearId: number) {
    return this.http
      .get(
        this.baseUrl + `HES/GetHesEncountersByPatientId/${patientId}?monthId=${monthId || 0}&yearId=${yearId || 0}`,
        httpOptions
      )
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  UpdateHEsCCandCFforPatients(dObj: HESAssignRemovecareCoordinatorsDto){
    return this.http.put(this.baseUrl + `HES/UpdateHEsCCandCFforPatients`, dObj, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  AddHesEncounter(data: HesEncounterDto) {
    return this.http.post(this.baseUrl + `HES/AddHesEncounter`, data , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  EditHesEncounter(data: HesEncounterDto) {
    return this.http.put(this.baseUrl + `HES/EditHesEncounter`, data , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  DeleteHesEncounter(id: number) {
    return this.http.delete(this.baseUrl + `HES/DeleteHesEncounter/${id}` , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  GetMonthlyPatientsHESData(data: HESDashboardParamsDto) {
    return this.http.get(this.baseUrl + `HES/GetMonthlyPatientsHESData?FacilityId=${data.facilityId}&YearId=${data.yearId}&MonthId=${data.monthId}&CareCoordinatorId=${data.careCoordinatorId || 0}&HESEncounterType=${data.HESEncounterType || -1}`, 
    httpOptions ).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  setHesQualityChecked(encounterId, qualityChecked){
    return this.http.put(this.baseUrl + `HES/SetHesMsQualityChecked?id=${encounterId}&qualityChecked=${qualityChecked}`, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  changePatientHesFlagged(patientId: number) {
    return this.http
      .put(
        this.baseUrl + `HES/ChangePatientHesFlag/${patientId}`,
        httpOptions
      )
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  GetHesMsHistoryToolTip(id: number) {
    return this.http
      .get(this.baseUrl + "HES/GetHESMsHistoryToolTip/" + id, httpOptions)
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  getHesStatusToolTip(id: number) {
    return this.http
      .get(this.baseUrl + "HES/GetHESStatusToolTip/" + id, httpOptions)
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  getPatientHesStatusHistory(patientId: number) {
    return this.http
      .get(
        this.baseUrl + `HES/GetPatientHESStatusHistory/${patientId}`,
        httpOptions
      )
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  GetHesDataByFacilityIdPdf(dObj: HesPdfDownloadFilterDto){
    return this.http.post(this.baseUrl + `HES/GetHESDataByFacilityIdPdf`, dObj, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  GetHesCarePlan(patientId: number) {
    return this.http.get(this.baseUrl + `HES/GetHesCarePlan/${patientId}` , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  EditHesCarePlan(body: any) {
    return this.http.put(this.baseUrl + `HES/EditHesCarePlan`, body , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  GetHesCarePlanPdf(patientId: number) {
    return this.http.get(this.baseUrl + `HES/GetHesCarePlanPdf/${patientId}` , {
      responseType: 'blob'
    });
  }
  AddHesDocument(data: any) {
    return this.http.post(this.baseUrl + `HES/AddHesDocument`, data , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  GetHesDocumentsByPatientId(patientId: number) {
    return this.http.get(this.baseUrl + `HES/GetHesDocumentsByPatientId/${patientId}` , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  AddHesDocumentError(docId: number) {
    return this.http.post(this.baseUrl + `HES/AddHesDocumentError/${docId}`, {} , httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  DeleteHesDocument(docId: number) {
    return this.http.delete(this.baseUrl + `HES/DeleteHesDocument/${docId}`, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  EditHesDateAssigned(patientId: number, date: string , facilityId: number) {
    const data = {
      patientId: patientId,
      dateAssigned: date,
      facilityId: facilityId
    };
    return this.http
      .put(this.baseUrl + "HES/EditHesDateAssigned", data, httpOptions)
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
}
