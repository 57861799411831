import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import moment from 'moment';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { SubSink } from 'src/app/SubSink';
import { PatientsService } from 'src/app/core/Patient/patients.service';
import { AppUiService } from 'src/app/core/app-ui.service';
import { BhiService } from 'src/app/core/bhi.service';
import { DataFilterService } from 'src/app/core/data-filter.service';
import { MrAdminService } from 'src/app/core/mr-admin.service';
import { SecurityService } from 'src/app/core/security/security.service';
import { BHIQualityCheckMOdalDto, BHIServiceTypeEnum, BhiEncounterDto } from 'src/app/model/Bhi/bhi.model';
import { PatientDto } from 'src/app/model/Patient/patient.model';
import { HttpResError } from 'src/app/model/common/http-response-error';
import { AppUserAuth } from 'src/app/model/security/app-user.auth';

@Component({
  selector: 'app-bhi-quality-check',
  templateUrl: "./bhi-quality-check.component.html",
  styleUrls: ["./bhi-quality-check.component.scss"],
})
export class BhiQualityCheckComponent implements OnInit {
  @ViewChild("qualityCheckModal") qualityCheckModal: ModalDirective;
  serviceTypeEnum = BHIServiceTypeEnum;
  currentMonthNum: number = new Date().getMonth() + 1;
  currentYearNum: number = new Date().getFullYear();
  private subs = new SubSink();
  currentUser: AppUserAuth = null;
  selectedPatientId : number;
  isPrDashboardQC= false;
  selectedMonth: number;
  selectedYear: number;
  IsEncountersLoading= false;
  bhiEncountersList: any;
  bhiCompletedTime = '';
  selectedPatient = new PatientDto();
  isCheckingBhiQualityCheck= false
  constructor(private securityService: SecurityService,  private datePipe: DatePipe,
    private mrService: MrAdminService,
    private appUi: AppUiService, private bhiService: BhiService, private toaster: ToastService, private dataFilterService: DataFilterService,
    private patientsService: PatientsService) { }

  ngOnInit(): void {
    if (
      this.securityService.securityObject &&
      this.securityService.securityObject.isAuthenticated
    ) {
      this.currentUser = this.securityService.securityObject;
    }
   this.appUi.openBHIQualityCheckModal.subscribe((res: BHIQualityCheckMOdalDto) => {
    this.qualityCheckModal.config = { backdrop: false, ignoreBackdropClick: false };

    this.selectedPatientId = res.patientId;
    this.isPrDashboardQC = res.isPrDashboard;
    if (res.serviceMonth) {
      this.selectedMonth = res.serviceMonth
    }
    if (res.serviceYear) {
      this.selectedYear = res.serviceYear
    }
    this.getBhiEncountersByPatientId(res.patientId);
   })

  }
  getBhiEncountersByPatientId(patientId) {
    this.IsEncountersLoading = true;
    this.qualityCheckModal.show();
    if (!this.selectedMonth){ 
      this.selectedMonth = this.currentMonthNum;
    }
    if (!this.selectedYear){ 
      this.selectedYear = this.currentYearNum;
    }
    this.bhiService.GetBhiEncountersByPatientId(patientId, this.selectedMonth, this.selectedYear).subscribe(
      (res: any) => {
        this.bhiEncountersList = res.bhiEncountersList;
        this.bhiCompletedTime = res.bhiTimeCompleted;
        this.IsEncountersLoading = false;
      },
      (error: HttpResError) => {
       this.IsEncountersLoading = false;
        this.toaster.error(error.error, error.message);
      }
    );
  }
  SetMsBhiQualityChecked(encounter){
    this.isCheckingBhiQualityCheck = true;
    this.subs.sink = this.bhiService.SetBhiMsQualityChecked(encounter.id, true)
      .subscribe(
        (res: any) => {
          var qualityCheckedEncounter = this.bhiEncountersList.find((en: BhiEncounterDto) => en.id == encounter.id);
          qualityCheckedEncounter.qualityChecked = true;
          qualityCheckedEncounter.qualityCheckedByName = this.currentUser.fullName
          qualityCheckedEncounter.qualityCheckedDate = moment().format("MM/DD/YYYY hh:mm A");

          this.updateBhiQualityCheckStatus();
          this.isCheckingBhiQualityCheck = false;
        },
        (err: HttpResError) => {
          this.isCheckingBhiQualityCheck = false;
          this.toaster.error(err.error, err.message);
        }
      );
  }
  updateBhiQualityCheckStatus(){
    if(this.bhiEncountersList.length){
      const notQualityChecked = this.bhiEncountersList.filter(encounter => encounter.qualityChecked == false);
      if(!notQualityChecked.length){
        this.patientsService.refreshQualityCheckStatusOfBHI.next(0);
      }else{
        const qualityChecked = this.bhiEncountersList.filter((encounter) => encounter.qualityChecked == true);
        if(qualityChecked.length == this.bhiEncountersList.length){
          this.patientsService.refreshQualityCheckStatusOfBHI.next(0);
        }else{
          this.patientsService.refreshQualityCheckStatusOfBHI.next(2);
        }
      }
    }
  }
  resetEncounterList(){
    this.bhiEncountersList = [];
    this.bhiCompletedTime = '';
  }
}
