<div mdbModal #qualityCheckModal="mdbModal" class="modal fade" tabindex="-1" (close)="resetEncounterList()" role="dialog"
  aria-labelledby="myqualityCheckModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
    <div class="modal-content ">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="qualityCheckModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">Monthly Review</h4>
      </div>
      <div class="modal-body">
        <span><p><strong>Total Time:</strong> {{bhiCompletedTime}}</p></span>
        
        <div class="panel z-depth-0 border">
          <div class="panel-header border-bottom">
            <h3>Encounters</h3>
          </div>
          <div class="panel-body p-2">

            <div appCcmLoader>
              <div class="loader-wrap" *ngIf="IsEncountersLoading">
                <div class="text-dynamic-2c la-ball-pulse ccm-loader">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>

            <!-- <ng-container *ngIf="bhiEncountersList;"></ng-container> -->
            <p class="text-center"
            *ngIf="(!bhiEncountersList || !bhiEncountersList.length) && !IsEncountersLoading">
            <strong>No Record Found!</strong>
          </p>
            <ng-container *ngFor="let item of bhiEncountersList">
              <div class="border rounded p-2 mb-2">
                <p class="mb-2"><strong class="d-inline-block" style="width: 100px;">Service Type:</strong>
                  {{serviceTypeEnum[item.bhiServiceType]}}</p>
                <div class="d-flex w-100 justify-content-between">
                  <small class="mr-2"><strong >Created By:</strong> <strong
                      class="text-dynamic-2c">
                      {{item.createdByName}}</strong></small>

                  <small class="font-400 mr-2" ><span ><strong class="mr-2">Date:</strong></span>
                    {{item.encounterDate| dateFormatPipe}}</small>
                </div>
                <div class="d-flex mt-2 w-100 justify-content-between">
                  <small *ngIf="item.startTime" class=" mr-2"><strong class="mr-2">Start Time:</strong>
                    {{item.startTime}}</small>
                  <small *ngIf="item.endTime" class=" mr-2"><strong class="mr-2">End Time:</strong>
                    {{item.endTime}}</small>
                  <small *ngIf="item.duration"><strong class="mr-2">Duration:</strong> {{item.duration}}</small>
                </div>
                <p class="mb-0"><strong class="d-inline-block" style="width: 100px;">Note: </strong> {{item.note}}</p>
                <div class="d-flex w-100 justify-content-between align-items-center">
                  <div>
                    <small *ngIf="item.qualityCheckedByName" class=" mr-3"><strong class="mr-2">Quality Checked By:</strong> {{item.qualityCheckedByName}}</small>
                    <small *ngIf="item.qualityCheckedDate"><strong class="mr-2">Quality Checked Date:</strong> {{item.qualityCheckedDate | dateFormatPipe}}</small>
                  </div>
                  <ng-container  *ngIf="!this.isPrDashboardQC" >
                  <button [disabled]="isCheckingBhiQualityCheck" type="button" *ngIf="!item?.qualityChecked" title="Quality Check"
                      class="waves-light btn btn-danger btn-sm ml-0" aria-label="Close"
                      (click)="SetMsBhiQualityChecked(item)" mdbWavesEffect> 
                      <i class="las la-times fa-lg"></i> Quality Checked
                  </button>
                  <button [disabled]="item?.qualityChecked" type="button" *ngIf="item?.qualityChecked" title="Quality Check"
                    size="sm" mdbBtn class="btn btn-dynamic-2c btn-sm waves-light ml-0" aria-label="Close" mdbWavesEffect> 
                    <i class="las la-check fa-lg"></i> Quality Checked
                  </button>
                </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-between align-items-center">
        <div>
          <button type="button" class="waves-light btn btn-sm btn-dynamic-secondary-2c" aria-label="Close"
            (click)="qualityCheckModal.hide()" mdbWavesEffect>Close</button>
        </div>
      </div>
    </div>
  </div>
</div>