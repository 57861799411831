import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import moment from "moment";
import { ModalDirective, ToastService } from "ng-uikit-pro-standard";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { CustomFormQuestionOptionFlag, CustomFormQuestionType, CustomFormStatus, CustomFormType } from "src/app/Enums/custom-form.enum";
import { HESTypeEnum } from "src/app/Enums/hes.enum";
import { RPMServiceType } from "src/app/Enums/rpm.enum";
import { PatientsService } from "src/app/core/Patient/patients.service";
import { AppUiService } from "src/app/core/app-ui.service";
import { CustomFormsService } from "src/app/core/custom-forms.service";
import { EmitEvent, EventBusService, EventTypes } from "src/app/core/event-bus.service";
import { FacilityService } from "src/app/core/facility/facility.service";
import { HealthScoreService } from "src/app/core/health-score.service";
import { replacePlaceholders } from "src/app/core/helpers/hsf.helper";
import { SecurityService } from "src/app/core/security/security.service";
import { LazyModalDto, TwoCModulesEnum } from "src/app/model/AppModels/app.model";
import { CreateFacilityUserDto } from "src/app/model/Facility/facility.model";
import { HesEncounterDto } from "src/app/model/Hes/hes.model";
import { PatientDto } from "src/app/model/Patient/patient.model";
import { BulkCommunicationOption, CommunicationMethod, PatinetCommunicationGroup } from "src/app/model/PatientEngagement/communication.model";
import { End_PrCMServiceType, PRCMEncounterDto } from "src/app/model/Prcm/Prcm.model";
import { AddCcmEncounterDto } from "src/app/model/admin/ccm.model";
import { HttpResError } from "src/app/model/common/http-response-error";
import { AddEditCustomForm, FilterCustomForm } from "src/app/model/custom-forms/custom-form.model";
import { AddPHSFormEncounterClaimDto, EditPHSFormRecord, EditPHSFormRecordOption, PHSForm, PHSFormStatusEnum, PHSFormsList, PhsFormOptionRecord, PhsFormQuestionRecord } from "src/app/model/health-score/health-score.model";
import { RPMEncounterDto } from "src/app/model/rpm.model";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-health-score-address-modal",
  templateUrl: "./health-score-address-modal.component.html",
  styleUrls: ["./health-score-address-modal.component.scss"],
})
export class HealthScoreAddressModalComponent implements OnInit, OnDestroy {
  @ViewChild('viewPdfModal') viewPdfModal: ModalDirective;
  @ViewChild("ScoreHealthFormModal") ScoreHealthFormModal: ModalDirective;
  @ViewChild("HealthScoreModal") HealthScoreModal: ModalDirective;
  @ViewChild("HealthScoreServiceFormsModal") HealthScoreServiceFormsModal: ModalDirective;
  selectedOptionToAddress= [];
  CustomFormStatusEnum = PHSFormStatusEnum;
  selectedPatient: PatientDto;
  showFormsListView = true;
  isLoadingRecords: boolean;
  PHSFormsList: PHSFormsList[];
  needToAddressQuestionsList: any[];
  isLoadingRecordsById= false;
  serviceType: TwoCModulesEnum;
  private unsubscribe$ = new Subject()
  phsFormDto = new PHSForm();
  CustomFormQuestionTypeEnum = CustomFormQuestionType;
  editPHSFormRecordOptionDto = new EditPHSFormRecordOption();
  isUpdatingRecordOption= false;
  
  searchWatch = new Subject<string>();
  searchParam: string;
  LoadingData: boolean;
  icdCodesArray = new Array<string>();
  chronicDiseasesByUrl: { code: string; detail: string; }[];
  isLoadingPayersList: boolean;
  
  facilityUsersList = new Array<CreateFacilityUserDto>();
  billingProviderId: number
  facilityId: number;
  submittingForm: boolean;
  isMandatoryFieldsFilled: boolean;
  filterCustomFormDto = new FilterCustomForm();
  customFormsList = new Array<AddEditCustomForm>();
  selectedCustomFormId: number;
  addingPHSForm: boolean;
  communicationMethod = CommunicationMethod;
  bulkCommunicationOption = BulkCommunicationOption.HealthForm;
  objectURLStrAW: string;
  constructor(
    public securityService: SecurityService,
    @Inject(ToastService) private toaster: ToastService,
    private route: ActivatedRoute,
    private eventBus: EventBusService,
    private healthScoreService: HealthScoreService,
    private appUi: AppUiService,
    private patientsService: PatientsService,
    private customFormsService: CustomFormsService,
    private facilityService: FacilityService,
  ) {}
  ngOnDestroy(): void {
    this.unsubscribe$.next(null)
    this.unsubscribe$.complete()
  }

  ngOnInit(): void {
    this.facilityId = +this.securityService.getClaim('FacilityId').claimValue;
    this.SubscribeOpenModalRequest();
    this.SearchObserver();
    this.getFacilityUsers();
    if (this.securityService.hasClaim('IsBillingProvider')) {
      this.billingProviderId = this.securityService.securityObject?.id
    }
  }
  SubscribeOpenModalRequest() {
    this.eventBus.on(EventTypes.OpenHealthScoreFormAddressModal).pipe(takeUntil(this.unsubscribe$)).subscribe((res: { type: string; data: {hesFormId: number , patient: PatientDto , serviceType: TwoCModulesEnum} }) => {
        if (res.type == EventTypes.OpenHealthScoreFormAddressModal.toString()) {
          this.selectedPatient = res.data.patient;
          this.serviceType = res.data.serviceType;
          this.getAllPHSForms();
          if (res.data.hesFormId) {
            this.getPHSFormByIdForDetails(res.data.hesFormId);
            this.HealthScoreModal.show()
          } else {
            this.ScoreHealthFormModal.show();
          }
        }
      });
  }
  SearchObserver() {
    this.searchWatch.pipe(debounceTime(1000)).subscribe(x => {
      this.searchParam = x;
      this.getClinicalTableDiseases();
    });
  }
  getAllPHSForms() {
    this.isLoadingRecords = true;
    this.healthScoreService
      // .getAllPHSForms(this.selectedPatient.id, PHSFormStatusEnum.All, this.serviceType)
      .getAllPHSForms(this.selectedPatient.id, PHSFormStatusEnum.All, undefined)
      .subscribe(
        (res: any) => {
          this.PHSFormsList = res;
          this.isLoadingRecords = false;
        },
        (err: HttpResError) => {
          this.toaster.error(err.error);
          this.isLoadingRecords = false;
        }
      );
  }
  resetFormDetails(){
    this.phsFormDto = new PHSForm();
    this.getAllPHSForms();
  }
  getPHSFormById(id: number, type) {
    this.showFormsListView = false;
    this.isLoadingRecordsById = true;
    this.healthScoreService.getPHSFormById(id).subscribe(
      (res: PHSForm) => {
        this.needToAddressQuestionsList = []
        let questionDetails = {
          title: '',
          option: {},
          checked: false
        }
        res.phsFormQuestionRecords.forEach(element => {
          questionDetails = {
            title: '',
            option:{},
            checked: false
          }
          if(type == 'all'){
            var options = element.phsFormOptionRecords.filter((option: any) => (option.flag == CustomFormQuestionOptionFlag.Critical && option.isSelected) || (option.flag == CustomFormQuestionOptionFlag.FollowUp && option.isSelected));
          }
          if(type == 'critical'){
            var options = element.phsFormOptionRecords.filter((option: any) => option.flag == CustomFormQuestionOptionFlag.Critical && option.isSelected);
          }
          if(type == 'followUp'){
            var options = element.phsFormOptionRecords.filter((option: any) =>  option.flag == CustomFormQuestionOptionFlag.FollowUp && option.isSelected);
          }
          if(options.length){
              options.forEach(option => {
                questionDetails.title = element.description;
                questionDetails.option = option;
                questionDetails.checked = false;
                this.needToAddressQuestionsList.push(questionDetails);
              });
          }
        });
        this.isLoadingRecordsById = false;
      },
      (err: HttpResError) => {
        this.toaster.error(err.error);
        this.isLoadingRecordsById = false;
      }
    );
  }
  openEncounterModal(note, duration, optionIds){
    this.selectedOptionToAddress = [];
    this.ScoreHealthFormModal.hide()
    if(this.serviceType == TwoCModulesEnum.CCM){
      this.OpenCCMEncounterModel(note, duration, optionIds);
    }
    if(this.serviceType == TwoCModulesEnum.RPM){
      this.OpenRPMEncounterModel(note, duration, optionIds);
    }
    if(this.serviceType == TwoCModulesEnum.PrCM){
      this.OpenPRCMEncounterModel(note, duration, optionIds);
    }
    if(this.serviceType == TwoCModulesEnum.HES){
      this.OpenHesEncounterModel(note, duration, optionIds);
    }
    
  }
  OpenHesEncounterModel(note = '', duration: number, optionIds) {
    const encounterObj = new HesEncounterDto();
    encounterObj.hesType = HESTypeEnum.CHI;
    encounterObj.duration = duration.toString();
    encounterObj.note = note
    const emitObj = new EmitEvent();
    emitObj.name = EventTypes.OpenHESQuickEncounter;
    emitObj.value = {
      type: EventTypes.OpenHESQuickEncounter.toString(),
      data: {
        patient: this.selectedPatient,
        encounterObj,
        addressedOptionIds: optionIds,
        config: {
          hideTimer: true
        }
      },

    };
    this.eventBus.emit(emitObj);
  }
  OpenPRCMEncounterModel(note = '', duration: number, optionIds) {
    const encounterObj = new PRCMEncounterDto();
    encounterObj.prCMServiceTypeId = End_PrCMServiceType['Initial Assessment'];
    encounterObj.duration = duration.toString();
    encounterObj.note = note
    const emitObj = new EmitEvent();
    emitObj.name = EventTypes.OpenPRCMQuickEncounter;
    emitObj.value = {
      type: EventTypes.OpenPRCMQuickEncounter.toString(),
      data: {
        patient: this.selectedPatient,
        encounterObj,
        addressedOptionIds: optionIds,
        config: {
          hideTimer: true
        }
      },

    };
    this.eventBus.emit(emitObj);
  }
  OpenCCMEncounterModel(note = '', duration: number, optionIds) {
    const encounterObj = new AddCcmEncounterDto()
    encounterObj.ccmServiceTypeId = 54;
    encounterObj['duration'] = duration;
    encounterObj.note = note
    const emitObj = new EmitEvent();
    emitObj.name = EventTypes.OpenCCMQuickEncounter;
    emitObj.value = {
      type: EventTypes.OpenCCMQuickEncounter.toString(),
      data: {
        patient: this.selectedPatient,
        encounterObj,
        addressedOptionIds: optionIds,
      },
      config: {
        hideTimer: true
      }
    };
    this.eventBus.emit(emitObj);
  }
  OpenRPMEncounterModel(note = '', duration: number, optionIds) {
    const encounterObj = new RPMEncounterDto()
    encounterObj.duration = duration.toString();
    encounterObj.rpmServiceType = RPMServiceType["Addressing Health Concern"];
    encounterObj.note = note;
    const emitObj = new EmitEvent();
    emitObj.name = EventTypes.OpenRPMQuickEncounter;
    emitObj.value = {
      type: EventTypes.OpenRPMQuickEncounter.toString(),
      data: {
        patient: this.selectedPatient,
        encounterObj,
        addressedOptionIds: optionIds,
        config: {
          hideTimer: true
        },
      },
    };
    this.eventBus.emit(emitObj);
  }
  addressSingleOption(question: {option, title, checked}){
    var note = ''
    var duration = 0;
    let optionIds = []
    note = `Following health concerns have been noticed:\n ${question.option.narration} \n All above health concerns have been addressed with patient.`
    duration = 6;
    optionIds.push(question.option.id)
    this.openEncounterModal(note, duration, optionIds)
  }
  addressMultipleOptions(){
    var note = '';
    var narration = '';
    var duration = 0;
    let optionIds = []
    this.selectedOptionToAddress.forEach((question) => {
      optionIds.push(question.option.id)
      narration = narration + ' ' +question.option.narration;
    })
    duration = 5 + (0.5 * this.selectedOptionToAddress.length);
    duration = +duration.toFixed();
    note = `Following health concerns have been noticed:\n ${narration} \n All above health concerns have been addressed with patient.`
    this.openEncounterModal(note, duration, optionIds)
  }
  selectOptionToAddress(value, question){
    if (value.target.checked) {
      this.selectedOptionToAddress.push(question);
    } else {
      const index = this.selectedOptionToAddress.findIndex((x) => x.option.id === question.option.id);
      this.selectedOptionToAddress.splice(index, 1);
    }
  }
  selectAllQuestions(checked: boolean): void {
    this.selectedOptionToAddress = []; // Clear the selected options array

    this.needToAddressQuestionsList.forEach(question => {
      if (!question.option.isAddressed) { // Only operate on questions that are not addressed
        question.checked = checked; // Update the checked property

        if (checked) {
          this.selectedOptionToAddress.push(question); // Add to selected options if checked
        } else {
          // Remove from selected options if unchecked
          const index = this.selectedOptionToAddress.findIndex(x => x.option.id === question.option.id);
          if (index !== -1) {
            this.selectedOptionToAddress.splice(index, 1);
          }
        }
      }
    });
  }
  addressedOptions(question){
    var addressedOptionIds = []
    if(question?.option?.id){
      addressedOptionIds = [question?.option?.id]
    }else{
      this.selectedOptionToAddress.forEach((question) => {
        addressedOptionIds.push(question?.option?.id);
      })
    }
    if(addressedOptionIds?.length){
      this.healthScoreService.addressedOptions(addressedOptionIds).subscribe((res: any) => {
        this.toaster.success('Options Archived');
        this.ScoreHealthFormModal.hide();
      }, (err: HttpResError) => {
        this.toaster.error(err.error);
      })
    }
  }
  getPHSFormByIdForDetails(formId: number){
    this.healthScoreService.getPHSFormById(formId).subscribe((res: any) => {
      this.phsFormDto = res;
      if (this.phsFormDto.icdCodes) {
        this.icdCodesArray = (this.phsFormDto.icdCodes || '').split(',');
      }
      if (this.phsFormDto.billingProviderId) {
        this.billingProviderId = this.phsFormDto.billingProviderId
      }
      this.phsFormDto.phsFormQuestionRecords = this.phsFormDto.phsFormQuestionRecords.sort(
        (a, b) => a.sequenceNo - b.sequenceNo
      );
      this.phsFormDto.phsFormQuestionRecords.forEach((question: PhsFormQuestionRecord) => {
        question.phsFormOptionRecords = question.phsFormOptionRecords.sort(
          (a, b) => a.sequenceNo - b.sequenceNo
        );
        if (
          question.questionType != CustomFormQuestionType["Multi Select"] &&
          question.phsFormOptionRecords.some((x) => x.isSelected)
        ) {
          var selectedOption = question.phsFormOptionRecords.find(
            (option: PhsFormOptionRecord) => option.isSelected == true
          );
          if (selectedOption) {
            question.radioOptionValue = selectedOption.id;
          }
        }
      });
      this.checkMandatoryFieldsStatus()
    }, (err: HttpResError) => {
      this.toaster.error(err.error);
    })
  }
  updateCheckBoxOptionValue(value, option: PhsFormOptionRecord, index: number) {
    this.editPHSFormRecordOptionDto = new EditPHSFormRecordOption()
    this.editPHSFormRecordOptionDto.isSelected = value.target.checked;
    this.editPHSFormRecordOptionDto.optionId = option.id;
    this.editPHSFormRecordOptionDto.questionId = option.phsFormQuestionRecordId;
    this.editPHSFormRecordOption();
    if (index || index == 0)  {
      const element = document.getElementById(`hsf-${index + 1}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
    this.EvaluateFormScore()
  }
  updateDescriptiveOptionText(value, option: PhsFormOptionRecord, index: number) {
    this.editPHSFormRecordOptionDto = new EditPHSFormRecordOption()
    this.editPHSFormRecordOptionDto.isSelected = true
    this.editPHSFormRecordOptionDto.optionId = option.id;
    this.editPHSFormRecordOptionDto.text = option.text
    this.editPHSFormRecordOptionDto.questionId = option.phsFormQuestionRecordId;
    this.editPHSFormRecordOption();
    this.EvaluateFormScore()
  }
  updateRadioOptionValue(
    question: PhsFormQuestionRecord,
    option: PhsFormOptionRecord, index: number
  ) {
    question.phsFormOptionRecords.forEach(q => {
      q.isSelected = false
    })
    option.isSelected = true;
    var selectedOption = question.phsFormOptionRecords.find(
      (option: PhsFormOptionRecord) => option.id == question.radioOptionValue
    );
    this.editPHSFormRecordOptionDto = new EditPHSFormRecordOption()
    this.editPHSFormRecordOptionDto.isSelected = true;
    this.editPHSFormRecordOptionDto.optionId = selectedOption.id;
    this.editPHSFormRecordOptionDto.questionId = selectedOption.phsFormQuestionRecordId;
    this.editPHSFormRecordOption();
    if (index || index == 0) {
      const element = document.getElementById(`hsf-${index + 1}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
    this.EvaluateFormScore()
  }
  EvaluateFormScore() {
    var questions = this.phsFormDto.phsFormQuestionRecords.deepClone();
    let totalScore = 0;
    let opts = questions.flatMap(x => x.phsFormOptionRecords.filter(y => y.isSelected == true))
    totalScore = opts.reduce((sum, option) => sum + option.weight, 0);
    this.phsFormDto.score = totalScore || 0
  }
  RunTextScript() {
    this.EvaluateFormScore()
    let data: Record<string, any> = {
      F: { Score: this.phsFormDto.score },
      // Q99: { Score: 90 },
      // Q12: { Score: 75 },
      // Add more data as needed
    };
    let script1 = this.phsFormDto.scoreableScript
    let resultStr = replacePlaceholders(script1,data, 0)
    // const variables = { "F.Score": 85, "Q.Score": 90 };
    // const script = "20 > 80 ? 'Excellent' : 'Needs Improvement';";
    try {
      
      const result = eval(resultStr);
      if (typeof result == 'string') {
        // this.toaster.success("Working fine")
        this.phsFormDto.scoreableNote = result;
      } else {
        this.toaster.warning("Invalid script, Result should be a string!")
      }
    } catch (error) {
      this.toaster.warning(error)
    }
  }
  isAllAddressed(){
    return this.needToAddressQuestionsList.some((question) => question?.option?.isAddressed == false);
  }
  editPHSFormRecordOption() {
    this.isUpdatingRecordOption = true;
    this.healthScoreService
      .editPHSFormRecordOption(this.editPHSFormRecordOptionDto)
      .subscribe(
        (res: any) => {
          // this.toaster.success("Option Updated.");
          this.isUpdatingRecordOption = false;
          this.checkMandatoryFieldsStatus()
        },
        (err: HttpResError) => {
          this.toaster.error(err.error);
          this.isUpdatingRecordOption = false;
        }
      );
  }
  getFacilityUsers() {
    // let roleName = "PRCM Care Manager";
    this.isLoadingPayersList = true;
    this.facilityService.getFacilityUserList(this.facilityId).subscribe(
      (res: []) => {
        this.facilityUsersList = res;
        this.isLoadingPayersList = false;
      },
      (error: HttpResError) => {
        this.isLoadingPayersList = false;
        this.toaster.error(error.error, error.message);
      }
    );
  }
  getClinicalTableDiseases() {
    this.LoadingData = true;
    this.chronicDiseasesByUrl = new Array<{ code: string; detail: string }>();
    this.patientsService.getCLinicalDiseases(this.searchParam).subscribe(
      (res: any) => {
        this.LoadingData = false;
        res[3].forEach(item => {
          this.chronicDiseasesByUrl.push({ code: item[0], detail: item[1] });
        });
        // if (
        //   this.chronicDiseasesByUrl &&
        //   this.chronicDiseasesByUrl.length === 1
        // ) {
        //   this.selectedCronicDisease = this.chronicDiseasesByUrl[0];
        //   this.diseaseSelected(this.selectedCronicDisease);
        // }
        // console.log(this.chronicDiseasesByUrl);
      },
      err => {
        this.LoadingData = false;
      }
    );
  }
  checkMandatoryFieldsStatus(){
    var mandatoryQuestions = this.phsFormDto.phsFormQuestionRecords.filter(question => question.isMandatory == true);
    if (!mandatoryQuestions?.length) {
      this.isMandatoryFieldsFilled = true;
    }
    mandatoryQuestions.forEach((question: PhsFormQuestionRecord) => { 
      var addressedOptions =  question.phsFormOptionRecords.filter(x => x.isSelected == true);
      if(!addressedOptions.length){
        this.isMandatoryFieldsFilled = false;
      }else{
        this.isMandatoryFieldsFilled = true;
      }
    })
  }
  openConfirmSubmitFormModal() {
    const modalDto = new LazyModalDto();
    modalDto.Title = "Confirmation";
    
    if(this.phsFormDto.cptCode){
      modalDto.Text = "Are you sure to add encounter claim?";
      modalDto.callBack = this.SubmitEncounterAbleForm;
    }else{
      modalDto.Text = "Are you sure you want to submit the form?";
      modalDto.callBack = this.SubmitPHSForm;
    }
    // modalDto.data = data;
    this.appUi.openLazyConfrimModal(modalDto);
  }
  SubmitPHSForm = () => {
    this.EvaluateFormScore()
    const obj = new EditPHSFormRecord()
    obj.formId = this.phsFormDto.id;
    obj.isSubmitted = true;
    obj.score = this.phsFormDto.score || 0;
    obj.scoreableNote = this.phsFormDto.scoreableNote || "";;
    this.healthScoreService.editPHSFormRecord(obj).subscribe(
      (res: any) => {
        this.toaster.success("Form Submitted Successfully.");
        this.HealthScoreModal.hide();
      },
      (err: HttpResError) => {
        this.toaster.error(err.error);
      }
    );
  }
  SubmitEncounterAbleForm = () => {
    this.EvaluateFormScore()
    const encounterObj = new AddPHSFormEncounterClaimDto()
    encounterObj.formId = this.phsFormDto.id;
    encounterObj.isSubmitted = true;
    encounterObj.icdCodes = this.icdCodesArray.join(',');
    encounterObj.score = this.phsFormDto.score || 0;
    encounterObj.encounterClaimNote = this.phsFormDto.encounterClaimNote;
    encounterObj.billingProviderId = this.billingProviderId;
    encounterObj.timeCompleted = "";
    encounterObj.scoreableNote = this.phsFormDto.scoreableNote || "";
    encounterObj.serviceDate = moment().format("MM/DD/YYYY");
    encounterObj.encounterClaimDate = moment().format("MM/DD/YYYY");
    this.submittingForm = false;
    this.healthScoreService.AddPHSFormEncounterClaim(encounterObj).subscribe(
      (res: any) => {
        this.submittingForm = false;
        this.phsFormDto.status = PHSFormStatusEnum.Submitted
        this.toaster.success("Form Submitted Successfully.");
        this.HealthScoreModal.hide();
        // if (
        //   this.chronicDiseasesByUrl &&
        //   this.chronicDiseasesByUrl.length === 1
        // ) {
        //   this.selectedCronicDisease = this.chronicDiseasesByUrl[0];
        //   this.diseaseSelected(this.selectedCronicDisease);
        // }
        // console.log(this.chronicDiseasesByUrl);
      },
      err => {
        this.submittingForm = false;
      }
    );
  }
  getCustomFormsList(){
    this.filterCustomFormDto.formType = CustomFormType['Patient Health Score'];
    this.filterCustomFormDto.status = CustomFormStatus.Submitted;
    this.filterCustomFormDto.moduleIds = [this.serviceType];
    this.filterCustomFormDto.moduleIds = [];
    this.customFormsService.getAllCustomForms(this.filterCustomFormDto).subscribe((res: any) => {
      this.customFormsList = res;
    }, (err: HttpResError) => {
      this.toaster.error(err.error)
    })
  }
  addPHSFormRecord(){
    this.addingPHSForm = true;
    const obj = {
      formId: this.selectedCustomFormId,
      patientId: this.selectedPatient?.id
    }
    this.healthScoreService.addPHSFormRecord(obj).subscribe((res: any) => {
      this.toaster.success('Form Generated Successfully');
      this.getAllPHSForms();
      this.HealthScoreServiceFormsModal.hide();
      this.addingPHSForm = false;
    }, (err: HttpResError) => {
      this.toaster.error(err.error);
      this.addingPHSForm = false;
    })
  }
  deletePHSForm(id: number){
    this.healthScoreService.deletePHSFormRecord(id).subscribe((res: any) => {
      this.toaster.success('Form Deleted Successfully');
      this.getAllPHSForms();
    }, (err: HttpResError) => {
      this.toaster.error(err.error);
    })
  }
  openConfirmationModal(id: number) {
    const modalDto = new LazyModalDto();
    modalDto.Title = "Confirmation";
    modalDto.Text = "Do you really want to remove this form?";
    modalDto.callBack = (row) => this.callBackPHSFormDelete(id);
    modalDto.rejectCallBack = this.rejectCallback;
    this.appUi.openLazyConfrimModal(modalDto);
  }
  callBackPHSFormDelete = (id: any) => {
    this.deletePHSForm(id);
  }
  rejectCallback = (data: any) => {
  }
  sendFormToChat(formId: number){
    let nUrl = environment.baseUrl;
    nUrl = nUrl + `phsForm/${this.selectedPatient.userName}/${formId}`;
    const lastIndex = nUrl.lastIndexOf('/api');
  if (lastIndex !== -1) {
    nUrl = nUrl.substring(0, lastIndex) + nUrl.substring(lastIndex + 4);
  }
    this.ScoreHealthFormModal.hide();
    this.getChatGroup(nUrl);
  }
  getChatGroup(message: string) {
    const event = new EmitEvent();
    event.name = EventTypes.OpenCommunicationModal;
    const chatGroup = new PatinetCommunicationGroup();
    chatGroup.inputMessage = message;
    chatGroup.id = this.selectedPatient.id;
    chatGroup.name = `${this.selectedPatient.firstName} ${this.selectedPatient.lastName}`;
    chatGroup.lastCommunication = null
    event.value = chatGroup;
    this.eventBus.emit(event);
  }
  resetFormsList(){
    this.PHSFormsList = new Array<PHSFormsList>();
  }
  downloadPHSFormPdf(formId: number){
    let nUrl = localStorage.getItem('switchLocal') ? environment.localBaseUrl:  environment.baseUrl;
    nUrl =  environment.appUrl;
    nUrl = nUrl + 'success/loading';
    const mWindow = window.open(nUrl);
    this.healthScoreService.getPHSFormRecordByIdPdf(formId).subscribe((res: any) => {
      const file = new Blob([res], { type: 'application/pdf' });
      const objectURL = window.URL.createObjectURL(file);
      mWindow.close();
      this.objectURLStrAW = objectURL;
      this.viewPdfModal.show();
    }, (err: HttpResError) => {
      this.toaster.error(err.error);
    })
  }
}
