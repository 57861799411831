import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError } from "rxjs";
import { environment } from "src/environments/environment";
import { AddHesCpSession, EditHesCpSession, EditHesCpTest, EditHesCpTestOption, HesCpSessionFilter } from "../model/hes-care-plan.model";
import { HttpErrorHandlerService } from "../shared/http-handler/http-error-handler.service";
import { SecurityService } from "./security/security.service";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    //
  }),
};

@Injectable({
  providedIn: "root",
})
export class HesCarePlanService {
  baseUrl = localStorage.getItem("switchLocal") ? environment.localBaseUrl : environment.baseUrl;

  constructor(
    private http: HttpClient,
    private securityService: SecurityService,
    private router: Router,
    private httpErrorService: HttpErrorHandlerService
  ) {}
  getHesCpSessions(hesCpSessionFilterDto: HesCpSessionFilter) {
    return this.http
      .get(
        this.baseUrl +
          `HesCarePlans/GetHesCpSessions?FacilityId=${hesCpSessionFilterDto.facilityId}&PatientId=${hesCpSessionFilterDto.patientId}&Status=${hesCpSessionFilterDto.status || ''}&SessionStartDate=${hesCpSessionFilterDto.sessionStartDate || ''}&SessionEndDate=${hesCpSessionFilterDto.sessionEndDate || ''}`,
        httpOptions
      )
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  getHesCpSessionById(id: number) {
    return this.http
      .get(this.baseUrl + `HesCarePlans/GetHesCpSessionById/${id}`, httpOptions)
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  GetHesCpTestById(testId: number) {
    return this.http
      .get(this.baseUrl + `HesCarePlans/GetHesCpTestById/${testId}`, httpOptions)
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  addHesCpSession(addHesCpSessionDto: AddHesCpSession) {
    return this.http
      .post(this.baseUrl + `HesCarePlans/AddHesCpSession/`, addHesCpSessionDto, httpOptions)
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  editHesCpTestOption(editHesCpTestOptionDto: EditHesCpTestOption) {
    return this.http
      .put(this.baseUrl + `HesCarePlans/EditHesCpTestOption`, editHesCpTestOptionDto, httpOptions)
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  editHesCpTest(editHesCpTestDto: EditHesCpTest) {
    return this.http
      .put(this.baseUrl + `HesCarePlans/EditHesCpTest`, editHesCpTestDto, httpOptions)
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  EditHesCpSession(editHesCpSessionDto: EditHesCpSession) {
    return this.http
      .put(this.baseUrl + `HesCarePlans/EditHesCpSession`, editHesCpSessionDto, httpOptions)
      .pipe(catchError(this.httpErrorService.handleHttpError));
  }
  
  EditHesCarePlan(patientId: number, carePlan: string){
    return this.http.put(this.baseUrl + `HesCarePlans/EditCarePlan`, {patientId: patientId, carePlan: carePlan}, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  GetHesCarePlan(patientId: number) {
    return this.http.get(this.baseUrl + `HesCarePlans/GetCareplan/${patientId}`, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  GenerateHesCarePlan(sessionId: number, providerNode: string) {
    return this.http.get(this.baseUrl + `HesCarePlans/GenerateHesCarePlan/${sessionId}?providerNode=${providerNode}`, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  FillEncounterIdInSession(sessionId: number, encounterId: number) {
    return this.http.put(this.baseUrl + `HesCarePlans/FillEncounterIdInSession?sessionId=${sessionId}&encounterId=${encounterId}`, httpOptions).pipe(catchError(this.httpErrorService.handleHttpError));
  }
  GenerateHesSessionReport(sessionId: number) {
    return this.http.get(this.baseUrl + `HesCarePlans/GenerateHesSessionReportPdf/${sessionId}?returnPrompt=false`,  { responseType: 'blob' }).pipe(catchError(this.httpErrorService.handleHttpError));
  }
}
