export class SubCategory {
  subCatShortName: string;
  subCatDisplayName: string;
}

export class CptCategoriesLookupDto {
  catShortName: string;
  catDisplayName: string;
  subCategories: SubCategory[];
}
export class GetInvoiceDetailByDeviceDto {
  facilityId: number;
  month: number;
  year: number;
  category= 0;
}
export class GetInvoiceDetailByDevice{
  patientName: string;
  deviceSerialNumber: number;
  chargeAmount: number;
  amount: number;
  appliedDate: number;
}
export class MonthlyServicesInvoiceList {
  id: number;
  quantity: number;
  unitAmount: number;
  serviceName: string;
  totalAmount: number;
  invoiceId: null;
  invoice: null;
  createdOn: string;
  createdUser: null;
  updatedOn: string;
  updatedUser: null;
  isActiveState: boolean;
  isDeletedState: boolean;
}
