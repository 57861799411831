import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { ToastService } from 'ng-uikit-pro-standard';
import { LocaleConfig } from "ngx-daterangepicker-material";
import { TimePeriod } from 'ngx-daterangepicker-material/daterangepicker.component';
import { Subject, debounceTime } from 'rxjs';
import { UserType } from 'src/app/Enums/UserType.enum';
import { BulkCommunicationService } from 'src/app/communication/bulk-communication.service';
import { PatientsService } from 'src/app/core/Patient/patients.service';
import { SecurityService } from 'src/app/core/security/security.service';
import { PatientDetailsForAdmin, SearchPatient } from 'src/app/model/Patient/patient.model';
import { TempPatientCommunication, TempPatientCommunicationLogs, TempPatientCommunicationStatus } from 'src/app/model/PatientEngagement/bulk-communication.model';
import { HttpResError } from 'src/app/model/common/http-response-error';

@Component({
  selector: 'app-bulk-communication-logs',
  templateUrl: './bulk-communication-logs.component.html',
  styleUrls: ['./bulk-communication-logs.component.scss']
})
export class BulkCommunicationLogsComponent implements OnInit {
  patientList = [];
  tempPatientCommunicationDto = new TempPatientCommunication();
  rows = new Array<TempPatientCommunicationLogs>()
  tempPatientCommunicationStatusEnum = TempPatientCommunicationStatus;
  facilityId: number;
  isLoadingBulkComLogs= false;
  daterange: any = {};
  firstLoad= true;
  filterPatientId: number;
  searchWatch = new Subject<string>();
  searchPatientsDto = new SearchPatient();
  isSearchingPatient= false;
  patientData = new PatientDetailsForAdmin();
  campaignId: number;
  constructor(private bulkCommunicationService: BulkCommunicationService, private toaster: ToastService, private securityService: SecurityService,
    private datePipe: DatePipe, private patientsService: PatientsService,private route: ActivatedRoute,
  ) { }
  public options: LocaleConfig = { format: 'MM/DD/YYYY',
  cancelLabel: 'Clear',
// displayFormat: 'DD-MM-YYYY'
  } 
  ngOnInit(): void {
    this.campaignId = +this.route.snapshot.queryParamMap.get('campaignId');
    this.setLastFourWeeksDateRange();
    this.searchObserver();
    this.facilityId = +this.securityService.getClaim('FacilityId')?.claimValue;
    if (!this.facilityId) {
      this.facilityId = 0
    }
    if (this.campaignId) {
      this.tempPatientCommunicationDto = new  TempPatientCommunication()
      this.tempPatientCommunicationDto.campaignId = this.campaignId
    }
    this.getTempPatientCommunication();
  }
  setLastFourWeeksDateRange(): void {
    
    const eDate = moment().format('MM-DD-YYYY'); // current date
    const sDate = moment(eDate, 'MM-DD-YYYY').subtract(4, 'weeks').format('MM-DD-YYYY');

    this.daterange = {startDate: sDate , endDate: eDate}
    this.tempPatientCommunicationDto.startDate = sDate;
      this.tempPatientCommunicationDto.endDate = eDate;

  }
  getTempPatientCommunication(){
    this.isLoadingBulkComLogs = true;
    if(this.facilityId != 0){
      this.tempPatientCommunicationDto.facilityId = this.facilityId;
    }
    this.tempPatientCommunicationDto.status = 0;
    this.bulkCommunicationService.getTempPatientCommunication(this.tempPatientCommunicationDto).subscribe((res: any) => {
      this.rows = res;
      if(res.length){
        res.forEach(element => {
          element.timeStamp = moment.utc(element.timeStamp).local().format();
        });
      }
      this.isLoadingBulkComLogs = false;
      this.firstLoad = false;
    }, (err: HttpResError) => {
      this.toaster.error(err.error);
      this.isLoadingBulkComLogs = false;
    })
  }
  clearDate() {
      this.daterange = {};
      this.setLastFourWeeksDateRange();
      this.getTempPatientCommunication();
    // }
  }
  selectedDate(value: TimePeriod, datepicker?: any) {
    if (value?.startDate && value?.endDate) {
      this.tempPatientCommunicationDto.startDate = value.startDate.format("MM/DD/YYYY");
      this.tempPatientCommunicationDto.endDate = value.endDate.format("MM/DD/YYYY");
    }
  }
  fillPatientId(filterPatientId) {
    if(filterPatientId){
      this.tempPatientCommunicationDto.patientId = filterPatientId;
    }
  }
  searchObserver() {
    this.searchWatch.pipe(debounceTime(1000)).subscribe((x) => {
      if (!x) {
        this.patientList = []
        return;
      }
        this.searchPatientsDto.fullName = x;
      
      this.searchPatient();
    });
  }
  searchPatient() {
    this.isSearchingPatient = true;

    if (this.securityService.securityObject.userType == UserType.FacilityUser) {
      this.searchPatientsDto.facilityId = +this.securityService.getClaim('FacilityId')?.claimValue;
    }
    this.patientsService
      .SearchPatients(this.searchPatientsDto)
      .subscribe((res: any) => {
        this.patientList = res;
        this.isSearchingPatient = false;
      }),
      (error: HttpResError) => {
        this.toaster.error(error.message);
        this.isSearchingPatient = false;
      };
  }
  resetFilters(){
    this.filterPatientId = null;
    this.tempPatientCommunicationDto = new TempPatientCommunication();
    this.tempPatientCommunicationDto.facilityId = this.facilityId;
    this.setLastFourWeeksDateRange()
    this.getTempPatientCommunication();
  }
}
