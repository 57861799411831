import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { ToastService } from 'ng-uikit-pro-standard';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { TimePeriod } from 'ngx-daterangepicker-material/daterangepicker.component';
import { BulkCommunicationService } from 'src/app/communication/bulk-communication.service';
import { AppUiService } from 'src/app/core/app-ui.service';
import { ClonerService } from 'src/app/core/cloner.service';
import { CommunicationService } from 'src/app/core/communication.service';
import { CustomFormsService } from 'src/app/core/custom-forms.service';
import { DataFilterService } from 'src/app/core/data-filter.service';
import { EmitEvent, EventBusService, EventTypes } from 'src/app/core/event-bus.service';
import { FacilityDepartmentService } from 'src/app/core/facility-department.service';
import { FacilityService } from 'src/app/core/facility/facility.service';
import { HealthScoreService } from 'src/app/core/health-score.service';
import { PatientsService } from 'src/app/core/Patient/patients.service';
import { RingCentralService } from 'src/app/core/RingCentral/ring-central.service';
import { SecurityService } from 'src/app/core/security/security.service';
import { HttpResError } from 'src/app/model/common/http-response-error';
import { FacilityDto } from 'src/app/model/Facility/facility.model';
import { BulkCommCampaigns, TempPatientCommunication } from 'src/app/model/PatientEngagement/bulk-communication.model';
import { CommunicationMethod } from 'src/app/model/PatientEngagement/communication.model';

@Component({
  selector: 'app-bulk-campaigns',
  templateUrl: './bulk-campaigns.component.html',
  styleUrls: ['./bulk-campaigns.component.scss']
})
export class BulkCampaignsComponent implements OnInit {
  daterange: { startDate: string; endDate: string; };
  isLoadingBulkComLogs: boolean;
  facilityId: number;
  rows: BulkCommCampaigns[] = [];
  ContactMethodEnum = CommunicationMethod
  tempPatientCommunicationDto = new TempPatientCommunication();
  public options: LocaleConfig = { format: 'MM/DD/YYYY',
    cancelLabel: 'Clear',
  // displayFormat: 'DD-MM-YYYY'
    } 
    
  facilityList = new Array<FacilityDto>();
  constructor(@Inject(ToastService) private toaster: ToastService, private commService: CommunicationService, private dataService: DataFilterService,
    private healthScoreService: HealthScoreService,
    private eventBus: EventBusService, private router: Router,private bulkCommunication: BulkCommunicationService, private route: ActivatedRoute,
    private cdr: ChangeDetectorRef, public rcService: RingCentralService, private cloner: ClonerService, private appUi: AppUiService,
    private securityService: SecurityService, private patientsService: PatientsService, private facilityService: FacilityService,
    private customFormsService: CustomFormsService, private facilityDepartment: FacilityDepartmentService) { }

  ngOnInit(): void {
    // this.searchObserver();
    this.facilityId = +this.securityService.getClaim('FacilityId')?.claimValue;
    
    this.getFacilityList();
    this.setLastFourWeeksDateRange();
    this.getTempPatientCommunication();
  }

  setLastFourWeeksDateRange(): void {
    
    const eDate = moment().format('MM-DD-YYYY'); // current date
    const sDate = moment(eDate, 'MM-DD-YYYY').subtract(4, 'weeks').format('MM-DD-YYYY');

    this.daterange = {startDate: sDate , endDate: eDate}
   
  }
  getTempPatientCommunication(){
    this.isLoadingBulkComLogs = true;
    if(this.facilityId != 0){
    }
    this.rows = []
    this.bulkCommunication.GetCommunicationCampaigns(this.facilityId ,this.daterange.startDate, this.daterange.endDate).subscribe((res: any) => {
      if(res?.length){
        this.rows = res;
        res.forEach(element => {
          element.timeStamp = moment.utc(element.timeStamp).local().format();
        });
      }
      this.isLoadingBulkComLogs = false;
    }, (err: HttpResError) => {
      this.toaster.error(err.error);
      this.isLoadingBulkComLogs = false;
    })
  }
  clearDate() {
      this.daterange = null;
      this.setLastFourWeeksDateRange();
      this.getTempPatientCommunication();
    // }
  }
  selectedDate(value: TimePeriod, datepicker?: any) {
    if (value?.startDate && value?.endDate) {
      this.daterange.startDate = value.startDate.format("MM/DD/YYYY");
      this.daterange.endDate = value.endDate.format("MM/DD/YYYY");
    }
  }
  resetFilters(){
    this.facilityId = +this.securityService.getClaim('FacilityId')?.claimValue || 0;
    this.setLastFourWeeksDateRange()
    this.getTempPatientCommunication();
  }
  OpenCampaignDetail(row: BulkCommCampaigns) {
    const emitObj = new EmitEvent();
    emitObj.name = EventTypes.TriggerGlobalIframe;
    emitObj.value = `/communicationModal/bulk-communication-logs?campaignId=${row.id}`;
    this.eventBus.emit(emitObj);
  }
  getFacilityList() {
    this.facilityService.getFacilityList().subscribe(
      (res: any) => {
        this.facilityList = res;
      },
      (error: HttpResError) => {
        this.toaster.error(error.error, error.message);
      }
    );
  }
}
