import { TwoCModulesEnum } from "../AppModels/app.model";

export class FilterPreferenceDto {
    id: number;
    filter: string;
    name: string;
    service: TwoCModulesEnum;
    facilityUserId: number;
    is2cDefault: boolean;
  }
  