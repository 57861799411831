import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";
import { TwoCTimezoneService } from "src/app/core/two-ctime-zone.service";
import dayjs from 'dayjs';

@Pipe({
  name: "dateFormatPipe",
})
export class DateMaskPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value) {
      var formatStr = "MMM DD, YYYY";
      if (args.length) {
        args.forEach((arg) => {
          if (arg == "time") {
            formatStr = "MMM DD, YY, hh:mm A";
          }
        });
      }
      return dayjs(value).format(formatStr);
    } else{
      return '';
    }
  }
}
