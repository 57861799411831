import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject,NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TwoCHeaderFooterComponent } from '../public-shared/two-cheader-footer/two-cheader-footer.component';
import { PdfViewComponent } from './pdf-view/pdf-view.component';


const routes: Routes = [
  // { path: '', component:  TwoCHeaderFooterComponent,
  // children: [
  //     { path: 'pdf', component:  PdfViewComponent }
  //   ]

  // }
];

@NgModule({
schemas: [CUSTOM_ELEMENTS_SCHEMA],

  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentViewerRoutingModule { }
