import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
schemas: [CUSTOM_ELEMENTS_SCHEMA],

  declarations: [],
  imports: [
    CommonModule
  ]
})
export class CoreModule { }
