import moment from "moment";
import { SortOrder } from "src/app/Enums/filterPatient.enum";
import {
  HESEncounterTimeEnum,
  HESMonthlyStatusEnum,
  HESStatusEnum,
  HESTypeEnum,
} from "src/app/Enums/hes.enum";
import { PagingData } from "../AppModels/app.model";

export class HESQualityCheckMOdalDto {
  patientId: number;
  isPrDashboard = false;
}

export class GetHESPatientsParamsDto {
  pageNumber = 1;
  searchParam = "";
  pageSize = 10;
  showAll = false;
  communicationConsent = 0;
  HESStatus: HESStatusEnum[] = ([-1]) as any
  hesMonthlyStatus: HESMonthlyStatusEnum[] = ([-1]) as any
  HESEncounterTime: HESEncounterTimeEnum[] = ([-1]) as any; // Comma separated HESEncounterTimeEnum values
  HESEncounterType: HESTypeEnum = (-1) as any; // Comma separated HESEncounterTimeEnum values
  Assigned = 0;
  customListId = 0;
  billingProviderId = 0;
  facilityUserId: number;
  facilityId: number;
  serviceMonth = moment().month() + 1;
  serviceYear = moment().year();
  dateAssignedFrom = "";
  dateAssignedTo = "";
  sortBy = "";
  sortOrder: SortOrder = 0;
  departmentIds: string[] = [""];
  hesCareCoordinatorId: number = 0;
  hesBillingProviderId: number = 0;
}
export class HESPatientListResponseDto {
  pagingData: PagingData;
  hesPatientsList: HESPatientListDto[];
}
export class HESPatientListDto {
  id: number;
  patientEmrId: string;
  departmentId: number;
  departmentName: string;
  departmentCode: string;
  departmentColorCode: string;
  dateAssigned: string;
  lastHESDate: string;
  lastHESMinutes: string;
  profileStatus: boolean;
  isHESConsentTaken: boolean;
  statusChangedBy: string;
  lastStatusChangeDate: string;
  isActiveMobileUser: boolean; // extended
  lastAppLaunchDate: string | any;
  lastHES: string;
  hesStatus: HESStatusEnum[];
  hesMonthlyStatus: number;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  city: string;
  telephonyCommunication: boolean;
  unAssociatedCommunication: number;
  primaryPhoneNumber: string;
  secondaryPhoneNumber: string;
  emergencyContactName: string;
  dateOfBirth: string;
  emergencyContactRelationship: string;
  emergencyContactPrimaryPhoneNo: string;
  emergencyContactSecondaryPhoneNo: string;
  insurancePlanName: string;
  insuranceType: number;
  secondaryInsurancePlanName: string;
  secondaryInsuranceType: number;
  billingProviderId: number;
  billingProviderName: string;
  billingProviderAbbreviation: string;
  msQualityChecked: boolean;
  msQualityCheckedByName: string;
  msQualityCheckedDate: string;
  msQualityCheckedByNameAbbreviation: string;
  currentMonthCompletedTime: number;
  currentMonthCompletedTimeString: string;
  facilityId: number;
  isHESRevoked: boolean;
  qualityCheckStatus: number;
  hesFlagged: boolean;
  hesSubmittedDate: string
  hesFormId: number
  
  hesCareCoordinators: HESCareCoordinatorForDisplay[];
  hesCareFacilitator: HESCareCoordinatorForDisplay;
}
export class HESCareCoordinatorForDisplay {
  id: number;
  fullName: string;
  nameAbbreviation: string;
}
export class HESDashboardParamsDto {
  monthId: number;
  yearId: number;
  facilityId: number;
  careCoordinatorId = 0;
  billingProviderId = 0;
  // careFacilitatorId: number;
  // HESStatus: HESStatusEnum = (-1) as any;
  HESEncounterType: HESTypeEnum | number = (-1) as any;
}
export class HESDasgboardDataDto {
  activePatientsCount: number;
  completedPatientsCount: number;
  eligiblePatientsCount: number;
  inEligiblePatientsCount: number;
  declinedPatientsCount: number;
  defferedPatientsCount: number;
  msNotStartedCount: number;
  msPartiallyCompletedCount: number;
  msCompletedCount: number;
  msCallNotAnsweredCount: number;
  chiTime_1_59: number;
  // chiTime60: number;
  chiTime_60_89: number;
  chiTime_90: number;
  pinTime_1_59: number;
  // pinTime60: number;
  pinTime_60_89: number;
  pinTime_90: number;
  pinpTime_1_59: number;
  // pinpTime60: number;
  pinpTime_60_89: number;
  pinpTime_90: number;
}

export class HesStatusChangeDto {
  patientId: number;
  hesStatus: number;
  reason: string;
}
export class HesEncounterListDto {
  billingProviderName: string;
  clinicalCoordinatorName: string;
  clinicalCoordinatorId: number;
  claimGenerated: boolean;
  createdBy: string;
  createdByName: string;
  duration: string;
  encounterDate: string;
  endTime: string;
  hesId: number;
  hesType: number;
  id: number;
  note: string;
  patientId: number;
  qualityChecked: boolean;
  qualityCheckedByName: string;
  qualityCheckedDate: string;
  startTime: string;
  billingProviderId: number;
  hesMonthlyStatus: number;
}
export class HesEncounterDto {
  id = 0;
  patientId: number;
  startTime: string;
  endTime: string;
  duration: string;
  durationTicks: number;
  encounterDate: string;
  note: string;
  hesType = HESTypeEnum.CHI
  monthlyStatus: number;
  clinicalCoordinatorId: number;
  billingProviderId: number;
  patientCommunicationIds: number[];
}

export class HESAssignRemovecareCoordinatorsDto {
  patientIds: number[];
  careCoordinatorIdsToAssign: number[] = [];
  careCoordinatorIdsToRemove: number[] = [];
  careFacilitatorId: number;
  // specialistId: number;
  dateAssigned: string;
  facilityId: number;
}
export class HesStatusHistoryDto {
  id: number;
  status: number;
  updatedBy: string;
  updatedDateTime: string;
  reason: string;
}
export class HesPdfDownloadFilterDto {
  facilityId: number;
  monthId: number;
  yearId: number;
  patientIds: number[];
  includeCarePlan: boolean;
  includeLogsHistory: boolean;
  isCompleted: boolean;
  isActive: boolean;
}
export class HESUploadDocDto {
  title: string;
  note: string;
  dateCreated: string | Date;
}