<div mdbModal #globalIframeModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel" [config]="{backdrop: true, ignoreBackdropClick: true}" aria-hidden="true"
  (close)="iframeSrc=''" style="background-color: transparent;">
  <div class="modal-dialog modal-xxl" role="document" style="background-color: transparent;width: 100%;border-right: 10px solid transparent;border-left: 10px solid transparent;">
    <div class="modal-content" style="background-color: transparent;box-shadow: none;">
      <div class="modal-body" style="height:calc(100vh - 60px); padding: 0px;background-color: transparent;">
        <button type="button" class="close pull-right document-close" aria-label="Close" (click)="GlobalIframeClosed();globalIframeModal.hide();">
          <span aria-hidden="true">×</span>
        </button>
        <iframe style="    border: 0;
        border-radius: 20px;
        background: transparent;
        box-shadow: none;" *ngIf="iframeSrc" class="w-100 h-100" [src]="iframeSrc"></iframe>
      </div>
    </div>
  </div>
</div>
