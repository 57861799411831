
<!-- <p class="text-left">Hello World.</p> -->
<div class="row">
  <div class="col" >
    <div class="form-group">
      <!-- <textarea [(ngModel)]="speechContent" class="form-control h-100" rows="10"></textarea> -->
      <editor #speechTextEditor style="width: 100%;" (onPostRender)="loadingEditor=false" [(ngModel)]="speechContent"
          apiKey="gpl"
          [init]="editorConfObject"></editor>
    </div>
  </div>
</div>
