<div mdbModal #customListModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel" aria-hidden="true" [config]="{backdrop: false}">
  <div class="modal-dialog modal-dialog-centered modal-lg flat-modal modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="customListModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" *ngIf="addEditCustomListDto.id == 0">Add Custom List</h4>
        <h4 class="modal-title w-100" *ngIf="addEditCustomListDto.id > 0">Edit Custom List</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <div class="d-flex justify-content-between align-items-center">
                <label for="listName">List Name<small class="text-danger">*</small></label>
                <div class="custom-control custom-checkbox custom-control-inline">
                  <input type="checkbox" class="custom-control-input" [(ngModel)]="addEditCustomListDto.isPublic" id="DailyLiving1" name="DailyLiving1">
                  <label class="custom-control-label" for="DailyLiving1">Share List</label>
                </div>
              </div>
              <div class="d-flex align-items-center flex-grow-1">
                <input type="text" class="flex-grow-1 form-control form-control-sm"
                  [(ngModel)]="addEditCustomListDto.name" id="listName" placeholder="List Name">
                <!-- <div mdbDropdown>
                  <button mdbDropdownToggle type="button" mdbBtn class="btn btn-dynamic-2c btn-sm btn btn-dynamic-2c btn-icon">
                    <i class="las la-user"></i>
                  </button>

                  <div class="dropdown-menu dropdown-primary dropdown-menu-right">
                    <div class="icon-container">
                      <ul>
                        <li><i class="las la-list"></i></li>
                        <li><i class="las la-group"></i></li>
                        <li><i class="las la-heart"></i></li>
                        <li><i class="las la-heartbeat"></i></li>
                        <li><i class="las la-adversal"></i></li>
                        <li><i class="las la-mars"></i></li>
                        <li><i class="las la-building"></i></li>
                        <li><i class="las la-ban"></i></li>
                        <li><i class="las la-user-md"></i></li>
                      </ul>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <!-- <div class="col-sm-6" style="margin-top: 30px;">
            <div class="form-group">
              <div class="custom-control custom-checkbox custom-control-inline">
                  <input type="checkbox" class="custom-control-input" [(ngModel)]="addEditCustomListDto.isPublic" id="DailyLiving1" name="DailyLiving1">
                  <label class="custom-control-label" for="DailyLiving1">Share List</label>
                </div>
            </div>
          </div> -->
          <div class="col-sm-6">
            <div class="header-height position-relative">
              <label for="searchBy">Search Patient</label>
              <div class="d-flex align-items-center justify-content-start">
                <input type="text" autocomplete="off"  #searchPatient [(ngModel)]="filterPatientDto.searchParam"
                   class="form-control form-control-sm box-shadow w-100" placeholder="Search User" />
                <span *ngIf="isLoading" class="spinner-border spinner-border-sm position-absolute"
                  style="right: 1.5rem;"></span>
              </div>
  
              <div *ngIf="(rows && rows.length > 0)" (appIsOutside)="rows=[]" class="userList searchable-list"
                style="height: 300px;">
                <!-- <div appCcmLoader class="loader-wrap" *ngIf="searchingChatUsers">
                  <div class="spinner-border text-dynamic-secondary-2c ccm-loader" role="status">
                  </div>
                </div> -->
                <div style="height: 100%;overflow-y: auto;">
                  <ul *ngIf="!isLoading">
                    <li *ngFor="let item of rows" (click)="ItemSelected(item)">
                      <p>{{item.fullName}}</p>
                      <!-- <mdb-checkbox [default]="true"></mdb-checkbox> -->
                    </li>
                  </ul>
                </div>
                
              </div>
            </div>
          </div>
          <!-- <div class="col-lg-12 col-md-12 col-sm-12">
            <label for="searchBy">Search Patient</label>
            <div class="d-flex align-items-center justify-content-start">
              <input [hidden]="filterPatientDto.filterBy === 2" type="text" #searchPatient [(ngModel)]="filterPatientDto.SearchParam" autocomplete="off"
                class="form-control form-control-sm box-shadow w-100" id="searchBy"
                placeholder="{{ filterPatientDto.filterBy === 1 ? 'Name, Emr Id' : filterPatientDto.filterBy === 2 ? 'Date of Birth e.g MM/DD/YYYY': 'Phone No'}}" />
              <input [hidden]="filterPatientDto.filterBy !== 2" id="searchBy" type="text" class="bg-white form-control form-control-sm box-shadow w-100" #dateDPicker="dpDayPicker" autocomplete="off"
              [dpDayPicker]="datePickerConfig2" placeholder="MM/DD/YYYY" [(ngModel)]="filterPatientDto.SearchParam" (ngModelChange)="filterPatientDto.SearchParam.length == 10 ? getFilterPatientsList2(): ''"
              [theme]="'dp-material ccm-date-picker'" [mode]="'day'" appDateTimeValidator="date" [appendTo]="'body'" />
            </div>
          </div> -->
          <!-- <div class="d-flex align-items-end col-lg-6 col-md-6 col-sm-6" >
            <div class="flex-grow-1">
              <label for="namea">Filter By</label>
              <ng-select class="ng-select-small"
                [(ngModel)]="filterPatientDto.filterBy" (ngModelChange)="filterPatientDto.SearchParam = '';getFilterPatientsList2()" [closeOnSelect]="true"
                [multiple]="false" [searchable]="true" placeholder="Filter By">
                <ng-option [value]="1">Name, Emr Id </ng-option>
                <ng-option [value]="2">Date of Birth </ng-option>
                <ng-option [value]="3">Phone No </ng-option>
              </ng-select>
            </div>
          </div> -->
          <!-- <div class="d-flex justify-content-center text-center loadingrow"> -->

          <!-- </div> -->
          <div class="panel w-100">

            <div class="panel-body" style="height:275px;">
              <mdb-progress-bar class="progress primary-color-dark-s mb-0" *ngIf="loadingListData" mode="indeterminate"></mdb-progress-bar>
              <div class="table-responsive text-nowrap" style="height:100%;overflow-y: auto;">
                <table class="table table-striped btn-table table-sm" >
                  <thead>
                    <tr>
                      <th>#</th>
                      <!-- <th class="text-center">

                        <div class="custom-control custom-checkbox">
                          <input class="custom-control-input" type="checkbox" id="selectAlla" [(ngModel)]="gridCheckAll"
                            (change)="gridAllRowsCheckBoxChecked($event)">
                          <label class="custom-control-label" for="selectAlla"></label>
                        </div>
                      </th> -->
                      <th>Emr id</th>
                      <th>Name</th>
                      <th>Action</th>
                      <!-- <th>Email</th>
                      <th>Address</th>
                      <th>Action</th> -->
                    </tr>
                  </thead>

                  <tbody>
                    <tr *ngFor="let row of selected; let i= index;">
                      <th scope="row">{{ i + 1}}</th>
                      <!-- <td>{{row.checked}}</td> -->
                      <!-- <td>
                        <div class="text-center">
                          <div class="custom-control custom-checkbox">
                            <input class="custom-control-input" type="checkbox" id="{{(i + 1) }}a" [(ngModel)]="row.checked"
                              (click)="rowCheckBoxChecked($event, row)">
                            <label class="custom-control-label" for="{{(i + 1)}}a"></label>
                          </div>
                        </div>
                      </td> -->
                      <td>{{row.patientEmrId}}</td>
                      <td>{{row.fullName}}</td>
                      <td>
                        <ul class="status-list custom-column-list delete-row-list">
                          <li [class.active]="row.checked" (click)="row.checked=!row.checked;rowCheckBoxChecked()" class=" m-0 mb-1">
                            <a class="link">Delete</a>
                          </li>
                        </ul>
                      </td>
                      <!-- <td>{{item.address}}</td> -->

                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
          <div class="col-sm-12 mb-3">
            <label>Show Columns:<small class="text-danger">*</small></label>
            <div class="py-3 border">
              <ul class="status-list custom-column-list">
                <li class="active col-sm-4 m-0 mb-1" *ngFor="let column of listOfColumns" [class.active]="column.check">
                  <a class="link" (click)="selectColumns(column)">{{column.name}}</a>
                </li>
                <!-- <li class="col-sm-4 m-0 mb-1">
                  <a class="link">CMS MS</a>
                </li>
                <li class="col-sm-4 m-0 mb-1">
                  <a class="link">BHI Status</a>
                </li> -->
              </ul>

              <!-- <ul class="status-list">
                <li class="col-sm-4 m-0 mb-1">
                  <mdb-checkbox class="text-checkable" [default]="true">Column Name </mdb-checkbox>
                </li>
              </ul> -->

            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group d-flex align-items-center">
              <label class="flex-shrink-0" for="descrp" style="width:100px">Description</label>
              <div class="d-flex align-items-center flex-grow-1">
                <input type="text" class="flex-grow-1 form-control form-control-sm ml-3"
                  [(ngModel)]="addEditCustomListDto.description" id="descrp" placeholder="Description">
              </div>
            </div>
          </div>

          <div class="col-sm-12">
            <label class="mr-3">Include these actions:</label>
            <div class="form-group d-flex align-items-center flex-wrap">
              <!-- <mdb-checkbox [inline]="true" [default]="true">Dua Gap</mdb-checkbox> -->
              <mdb-checkbox [inline]="true" [default]="true" [(ngModel)]="addEditCustomListDto.showNotes" >Notes</mdb-checkbox>
              <!-- <mdb-checkbox [inline]="true" [default]="true">Quick Encounter</mdb-checkbox> -->
              <mdb-checkbox [inline]="true" [default]="true" [(ngModel)]="addEditCustomListDto.showTasks" >Task</mdb-checkbox>
            </div>
          </div>
          <!-- <div class="col-sm-12">
            <div class="d-flex align-items-center">
              <mdb-checkbox [inline]="true" [default]="true">Make this list available for other users</mdb-checkbox>
            </div>
          </div> -->
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-between">
        <div class="" >
          <div *ngIf="removeAblePatientsCount" class="alert alert-warning alert-dismissible animated fade show mb-0 py-2" role="alert">
            On save <strong>{{removeAblePatientsCount}} Patient(s)</strong> will be removed!
          </div>
          <p *ngIf="addEditCustomListDto.id > 0" class="mb-0">Created By: {{addEditCustomListDto.facilityUserName}}</p>
        </div>
        <div>
          <small class="text-danger" *ngIf="columnsName.length < 1">*Minimum one column is required.</small>
          <button type="submit" class="btn btn-dark btn-sm" (click)="customListModal.hide()">Close</button>
          <button type="submit" class="btn btn-dynamic-2c btn-sm" (click)="addEditCustomList();"
            [disabled]="isAddListLoading || !addEditCustomListDto.name || columnsName.length < 1">
            <span *ngIf="isAddListLoading" class="mr-1 spinner-border spinner-border-sm " role="status"
              aria-hidden="true"></span>Save</button>
        </div>
      </div>
    </div>
  </div>
</div>
