import { Directive, ElementRef, HostListener, Injector, Input, Optional, Self } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors, NgModel, NgControl } from '@angular/forms';
import * as moment from 'moment';

@Directive({
  selector: '[appDateTimeValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DateTimeValidatorDirective, multi: true }]
})
export class DateTimeValidatorDirective implements Validator {


  @Input('appDateTimeValidator') mode: 'date' | 'datetime' | 'time';
  // Define mode-specific regex patterns
  private patterns = {
    date: /^(0?[1-9]|1[012])\/(0?[1-9]|[12]\d|3[01])\/(\d{4})$/,
    datetime: /^(0?[1-9]|1[012])\/(0?[1-9]|[12]\d|3[01])\/(\d{4})\s(0?[1-9]|1[0-2]):([0-5]\d)\s(AM|PM)$/,
    time: /^([01]?[0-9]|2[0-3]):([0-5][0-9])\s?(AM|PM)$/,
  };
  constructor(private el: ElementRef, private _injector: Injector) {
    // Delay execution to ensure ngModel value is set
    setTimeout(() => this.formatInitialValue());
   }

   private formatInitialValue() {
    const inputElement: HTMLInputElement = this.el.nativeElement;
    const currentValue = inputElement.value;
    if (currentValue) {
      let formattedValue = ''
      if (this.mode == 'date' && !moment(currentValue, this.getDateFormat(), true).isValid()) {
        formattedValue = moment(currentValue).format('MM/DD/YYYY')
      } else if(this.mode == 'datetime' && !moment(currentValue, this.getDateFormat(), true).isValid()) {
        formattedValue = moment(currentValue).format('MM/DD/YYYY hh:mm A')
      } else if(this.mode == 'time' && !moment(currentValue, this.getDateFormat(), true).isValid()) {
        formattedValue = moment(currentValue).format('hh:mm A')
      }
      if (formattedValue && formattedValue !== currentValue) {
        inputElement.value = formattedValue;
      }
    }
  }
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    
  }

  @HostListener('input', ['$event']) onInputChange(event: InputEvent) {
    // console.log(event.inputType)
    if (event.inputType !== 'insertText' && event.inputType !== 'insertFromPaste') {
      return;
    }
    const inputElement: HTMLInputElement = this.el.nativeElement;
    const currentValue = inputElement.value;
    let newValue = this.applyFormatBasedOnMode(currentValue, this.mode);;

    if (newValue !== currentValue) {
      this.el.nativeElement.value = newValue;
      // To avoid cursor jumping, calculate and set the new cursor position
      // const cursorPosition = this.calculateCursorPosition(event, this.previousValue, newValue);
      // inputElement.setSelectionRange(cursorPosition, cursorPosition);
      const result = this._injector.get(NgControl)
      result.control
      if (result) {
        result?.control.setValue(newValue, { emitEvent: false });
      }
      event.preventDefault();
    }
    
  }

  validate(control: AbstractControl): ValidationErrors | null {
    let pattern = this.patterns[this.mode];
    let controlValue = control.value as string
    if (controlValue) {
      controlValue = controlValue.trim()
    }
    const isRegexValid = pattern.test(controlValue)
    const isValid = ((isRegexValid && moment(controlValue, this.getDateFormat(), true).isValid()) || !controlValue) ;
    const inputElement: HTMLInputElement = this.el.nativeElement;
    if (control?.touched && control?.value && !isValid && inputElement.nodeName == 'INPUT' || (!controlValue && inputElement.required)) {
      inputElement.classList.add('border-danger')
    }
    if (controlValue && isValid && inputElement.nodeName == 'INPUT') {
      inputElement.classList.remove('border-danger')
    }
    return isValid ? null : { invalidFormat: true }
  }

  private calculateCursorPosition(event: any, previousValue: string, newValue: string): number {
    // Basic cursor position calculation after deletion
    if (event.key === 'Backspace') {
      let cursorPos = event.target.selectionStart;
      return cursorPos + (newValue.length - previousValue.length); // Adjust based on the change in length
    }
    return newValue.length; // Default to end if not a backspace or handling is not specific
  }

  private applyFormatBasedOnMode(value: string, mode: 'date' | 'datetime' | 'time'): string {
    let numericValue = ''
    if (mode === 'date') {
      numericValue = value.replace(/[^\d]/g, ''); // Keep only digits to simplify processing
    } else {
      numericValue = value.replace(/[^\dAPM]/gi, '') // Keep only digits to simplify processing
    }
    let formattedValue = '';

    if (mode === 'date') {
      formattedValue = this.formatDate(numericValue);
    } else if (mode === 'datetime') {
      formattedValue = this.formatDateTime(numericValue);
    } else if (mode === 'time') {
      formattedValue = this.formatTime(numericValue);
    }

    return formattedValue;
  }

  private formatDate(numericValue: string): string {

    if (numericValue.length > 4) {
      let month = Math.min(parseInt(numericValue.substring(0, 2), 10), 12);
      let year = parseInt(numericValue.substring(4, 8), 10);
      let day = Math.min(parseInt(numericValue.substring(2, 4), 10), moment(`${year}-${month}`, "YYYY-MM").daysInMonth());
      return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${numericValue.substring(4, 8)}`;
    } else if (numericValue.length == 4) {
      let month = Math.min(parseInt(numericValue.substring(0, 2), 10), 12);
      let year = parseInt(numericValue.substring(4, 8), 10);
      let day = Math.min(parseInt(numericValue.substring(2, 4), 10), moment(`${year}-${month}`, "YYYY-MM").daysInMonth());
      return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}`;
    } else if (numericValue.length == 2) {
      let month = Math.min(parseInt(numericValue.substring(0, 2), 10), 12);
      return `${month.toString().padStart(2, '0')}`;
    } else if (numericValue.length > 2) {
      let month = Math.min(parseInt(numericValue.substring(0, 2), 10), 12);
      return `${month.toString().padStart(2, '0')}/${numericValue.substring(2)}`;
    }
    return numericValue;
  }

  private formatDateTime(numericValue: string): string {
    // Date part
    let datePart = this.formatDate(numericValue);
    // Time part (for simplicity, assuming correct time input)
    if (numericValue.length == 8) {
      return `${datePart}`
    }
    if (numericValue.length == 9) {
      return `${datePart} ${numericValue[8]}`
    }
    if (numericValue.length == 10) {
      let hour = Math.min(parseInt(numericValue.substring(8, 10), 10),12);
      return `${datePart} ${hour?.toString().padStart(2, '0')}`
    }
    if (numericValue.length == 11) {
      let hour = Math.min(parseInt(numericValue.substring(8, 10), 10),12);
      return `${datePart} ${hour?.toString().padStart(2, '0')}:${numericValue[10]}`
    }
    if (numericValue.length == 12) {
      let hour = Math.min(parseInt(numericValue.substring(8, 10), 10), 12);
      let minute = Math.min(parseInt(numericValue.substring(10, 12), 10), 59);
      // let amPm = hour >= 12 ? 'PM' : 'AM';
      return `${datePart} ${hour?.toString().padStart(2, '0')}:${minute?.toString().padStart(2, '0')}`;
    }
    if (numericValue.length == 13) {
      let hour = Math.min(parseInt(numericValue.substring(8, 10), 10), 12);
      let minute = Math.min(parseInt(numericValue.substring(10, 12), 10), 59);
      // let amPm = hour >= 12 ? 'PM' : 'AM';
      let APValue = ''
      if (numericValue[12].toUpperCase() == 'A' || numericValue[12].toUpperCase() == 'P') {
        APValue = numericValue[12].toUpperCase();
      }
      return `${datePart} ${hour?.toString().padStart(2, '0')}:${minute?.toString().padStart(2, '0')} ${APValue}`;
    }
    if (numericValue.length > 13) {
      let hour = Math.min(parseInt(numericValue.substring(8, 10), 10), 12);
      let minute = Math.min(parseInt(numericValue.substring(10, 12), 10), 59);
      // let amPm = hour >= 12 ? 'PM' : 'AM';
      let APValue = ''
      let MValue = ''
      if (numericValue[12].toUpperCase() == 'A' || numericValue[12].toUpperCase() == 'P') {
        APValue = numericValue[12].toUpperCase();
      }
      if (numericValue[13].toUpperCase() == 'M') {
        MValue = numericValue[13].toUpperCase();
      }
      return `${datePart} ${hour?.toString().padStart(2, '0')}:${minute?.toString().padStart(2, '0')} ${APValue}${MValue}`;
    }
    return datePart;
  }

  private formatTime(numericValue: string): string {
    if (numericValue.length == 1) {
      return `${numericValue[0]}`
    }
    if (numericValue.length == 2) {
      let hour = Math.min(parseInt(numericValue.substring(0, 2), 10),12);
      return `${hour?.toString().padStart(2, '0')}`
    }
    if (numericValue.length == 3) {
      let hour = Math.min(parseInt(numericValue.substring(0, 2), 10), 12);
      // let amPm = hour >= 12 ? 'PM' : 'AM';
      return `${hour?.toString().padStart(2, '0')}:${numericValue[2]}`;
    }
    if (numericValue.length == 4) {
      let hour = Math.min(parseInt(numericValue.substring(0, 2), 10), 12);
      let minute = Math.min(parseInt(numericValue.substring(2, 4), 10), 59);
      // let amPm = hour >= 12 ? 'PM' : 'AM';
      return `${hour?.toString().padStart(2, '0')}:${minute?.toString().padStart(2, '0')}`;
    }
    if (numericValue.length == 5) {
      let hour = Math.min(parseInt(numericValue.substring(0, 2), 10), 12);
      let minute = Math.min(parseInt(numericValue.substring(2, 4), 10), 59);
      // let amPm = hour >= 12 ? 'PM' : 'AM';
      let APValue = ''
      if (numericValue[4].toUpperCase() == 'A' || numericValue[4].toUpperCase() == 'P') {
        APValue = numericValue[4].toUpperCase();
      }
      return `${hour?.toString().padStart(2, '0')}:${minute?.toString().padStart(2, '0')} ${APValue}`;
    }
    if (numericValue.length == 6) {
      let hour = Math.min(parseInt(numericValue.substring(0, 2), 10), 12);
      let minute = Math.min(parseInt(numericValue.substring(2, 4), 10), 59);
      // let amPm = hour >= 12 ? 'PM' : 'AM';
      let APValue = ''
      let MValue = ''
      if (numericValue[4].toUpperCase() == 'A' || numericValue[4].toUpperCase() == 'P') {
        APValue = numericValue[4].toUpperCase();
      }
      if (numericValue[5].toUpperCase() == 'M') {
        MValue = numericValue[5].toUpperCase();
      }
      return `${hour?.toString().padStart(2, '0')}:${minute?.toString().padStart(2, '0')} ${APValue}${MValue}`;
    }

    return numericValue;
  }


  private getDateFormat(): string {
    switch (this.mode) {
      case 'date':
        return 'MM/DD/YYYY';
      case 'datetime':
        return 'MM/DD/YYYY hh:mm A';
      case 'time':
        return 'hh:mm A';
      default:
        return '';
    }
  }

}
