import { AppDataService } from 'src/app/core/app-data.service';
import { InvoiceForListDto } from './../../model/Accounts/accounts.model';
import { Component, Inject,OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { AppUiService } from 'src/app/core/app-ui.service';
import { BillingService } from 'src/app/core/billing.service';
import { DataFilterService } from 'src/app/core/data-filter.service';
import { FacilityService } from 'src/app/core/facility/facility.service';
import { SecurityService } from 'src/app/core/security/security.service';
import { UserType } from 'src/app/Enums/UserType.enum';
import { EncounterClaimsdto, InvoicePreviewDto, InvoicePreviewFilteredDto } from 'src/app/model/Accounts/invoice.model';
import { LazyModalDto, TwoCModulesEnum } from 'src/app/model/AppModels/app.model';
import { CCMBillDto, EncounterClaimType } from 'src/app/model/bills.model';
import { HttpResError } from 'src/app/model/common/http-response-error';
import { SubSink } from 'src/app/SubSink';
import { Location } from '@angular/common';
import { GetInvoiceDetailByDevice, GetInvoiceDetailByDeviceDto, MonthlyServicesInvoiceList } from 'src/app/model/Accounts/billing.model';
import { InvoiceCategoryByDevice } from 'src/app/Enums/billing.enum';
import moment from 'moment';
import dayjs from 'dayjs';
import { AICallLogsDto, AICallLogsList } from 'src/app/model/cura-ai-generator.model';
import { CuraAiService } from 'src/app/core/cura-ai.service';
import { TwoCTimezoneService } from 'src/app/core/two-ctime-zone.service';
import { NeurocognitiveService } from 'src/app/core/neurocognitive.service';
import { AccessoryInvoiceList } from 'src/app/model/accessories.model';

@Component({
  selector: 'app-invoice-preview',
  templateUrl: './invoice-preview.component.html',
  styleUrls: ['./invoice-preview.component.scss']
})
export class InvoicePreviewComponent implements OnInit, OnDestroy {
  @ViewChild("invoiceDetailsByDevice") invoiceDetailsByDevice: ModalDirective;
  @ViewChild("inventoryCountModal") inventoryCountModal: ModalDirective;
  invoicePreviewData = new InvoicePreviewDto();
  invoiceDetailByDeviceDto = new GetInvoiceDetailByDeviceDto();
  invoiceDetailsList = new Array<GetInvoiceDetailByDevice>();
  invoiceCategoryByDeviceEnum = InvoiceCategoryByDevice;
  facilityId: number;
  isLoadingPreview: boolean;
  private subs = new SubSink();
  EncounterClaimTypeEnum = EncounterClaimType;
  displayInvoiceObj: InvoicePreviewFilteredDto[] = [];
  generatingInvoice: boolean;
  displayInvoiceObj1: InvoicePreviewFilteredDto[];
  isLoadinginvoicesList: boolean;
  invoicesList: InvoiceForListDto[];
  loadingInvoicesDetails: boolean;
  selectedInvoiceId: number;
  invoiceEncounterClaimsList: CCMBillDto[];
  listOfYears = [];
  monthId = new Date().getMonth() + 1;
  yearId = new Date().getFullYear();
  activeViewType = 0;
  disableGenerateInvoice = true;
  startDate= '';
  endDate = '';
  selectedInvoice= new InvoiceForListDto();
  aiCallLogsList = new Array<AICallLogsList>();
  loadingCallLogs= false;
  aiCallLogsDto = new AICallLogsDto();
  twoCModulesEnum = TwoCModulesEnum;
  cognitiveSessionList= [];
  isLoadingCognitiveSessions= false;
  accessoryInvoiceList = new Array <AccessoryInvoiceList>();
  monthlyServicesInvoiceList = new Array <MonthlyServicesInvoiceList>();
  isLoadingAccessoriesList: boolean;
  isLoadingMonthlyServicesList: boolean;
  invoiceId: number;
  constructor(private billingService: BillingService, private filterData: DataFilterService, private route: ActivatedRoute, private appUi: AppUiService,
    private location: Location, private appDataService: AppDataService,
    private router: Router, @Inject(ToastService) private toaster: ToastService, private securityService: SecurityService, private facilityService: FacilityService, 
    private curaAiService: CuraAiService, private ncaService: NeurocognitiveService) { }

  ngOnInit() {
    this.listOfYears = this.appDataService.listOfYears;
    this.facilityId = +this.route.snapshot.paramMap.get('facilityId');
    this.startDate = this.route.snapshot.queryParamMap.get('startDate');
    this.endDate = this.route.snapshot.queryParamMap.get('endDate');
    this.facilityId = +this.route.snapshot.queryParamMap.get('facilityId');
    this.invoiceId = +this.route.snapshot.queryParamMap.get('invoiceId');
    if (!this.facilityId) {
      this.facilityId = +this.securityService.getClaim('FacilityId').claimValue;
    }
    if (!this.invoiceId) {
      this.PreviewInvoiceByFacilityId();
    }
    this.getInvoicesList(this.invoiceId);
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
  GenerateInvoice() {
    this.generatingInvoice = true;
    this.billingService.GenerateInvoiceByFacilityId(this.facilityId, this.monthId, this.yearId).subscribe(
      (res: any) => {
        this.generatingInvoice = false;
        this.toaster.success('Invoice generated successfully');
      },
      (error: HttpResError) => {
        this.generatingInvoice = false;
        this.toaster.error(error.error, error.message);
      }
    );
  }

  openConfirmModal() {
    const modalDto = new LazyModalDto();
    modalDto.Title = "Generate Invoice";
    modalDto.Text = "Are you sure to generate Invoice?";
    modalDto.callBack = this.callBack;
    // modalDto.data = data;
    this.appUi.openLazyConfrimModal(modalDto);
  }
  callBack = (data: any) => {
    this.GenerateInvoice();
  }
  navigateBack() {
    this.location.back();
  }
  invoiceSelectionChanged() {
    if (this.selectedInvoiceId === -1) {
      this.PreviewInvoiceByFacilityId();
    } else {
      this.GetEncounterClaimsByInvoiceId();
    }
  }

  PreviewInvoiceByFacilityId() {
    if (!this.facilityId) {
      this.facilityId = 0;
      // return;
    }
    if (!this.monthId || !this.yearId) {
      return;
    }
    // this.pagingData.pageNumber = pageInfo.offset;
    this.isLoadingPreview = true;
    this.activeViewType = 1;
    this.displayInvoiceObj1 = [];
    this.displayInvoiceObj = [];
    this.disableGenerateInvoice = true;
    this.selectedInvoiceId = null;
    this.subs.sink = this.billingService.PreviewInvoiceByFacilityId(this.facilityId, this.monthId, this.yearId)
    .subscribe((res: InvoicePreviewDto) => {
      this.invoicePreviewData = res;
      this.GenerateWidgetsData();
      this.isLoadingPreview = false;
      this.disableGenerateInvoice = false;
    }, (err: HttpResError) => {
      this.isLoadingPreview = false;
      this.toaster.error(err.error || err.error, err.message);
    });
  }
  getInvoicesList(invoiceId?: number) {
    if (!this.facilityId) {
      this.facilityId = 0;
      // return;
    }
    // this.pagingData.pageNumber = pageInfo.offset;
    this.isLoadinginvoicesList = true;
    this.startDate = moment(this.startDate, 'MMM YYYY').format('MM/DD/YYYY');
    this.endDate = moment(this.endDate, 'MMM YYYY').format('MM/DD/YYYY');
    let facilityIds = [];
    if(this.facilityId){
      facilityIds = [this.facilityId]
    }
    this.subs.sink = this.billingService.getInvoicesList(facilityIds, 0, this.startDate, this.endDate)
    .subscribe((res: any) => {
      this.invoicesList = res;
      // this.pagingData.elementsCount = res.length;
      // this.pagingData = res.pagingData;
      // this.pagingData.pageSize = 10;
      // this.pagingData.pageNumber = res.pagingData.pageNumber - 1;
      const selectedInvoice = this.invoicesList.find((invoice: any)=> invoice.id === invoiceId);
      if(selectedInvoice && selectedInvoice.id){
        this.yearId = selectedInvoice.yearId;
        this.monthId = selectedInvoice.monthId;
      }
      this.isLoadinginvoicesList = false;
      if (invoiceId) {
        this.selectedInvoiceId = invoiceId;
        this.GetEncounterClaimsByInvoiceId();
      }
    }, (err: HttpResError) => {
      this.isLoadinginvoicesList = false;
      this.toaster.error(err.error || err.error, err.message);
    });
  }
  GetEncounterClaimsByInvoiceId() {
    if (!this.selectedInvoiceId) {
      return;
    }
    this.loadingInvoicesDetails = true;
    this.displayInvoiceObj1 = [];
    this.displayInvoiceObj = [];
    this.activeViewType = 2;
    this.monthId = null;
    this.subs.sink = this.billingService
      .GetEncounterClaimsByInvoiceId(this.selectedInvoiceId)
      .subscribe(
        (res: any) => {
          this.invoiceEncounterClaimsList = res;
          const selectedInvoices = this.invoicesList.find(x => x.id === this.selectedInvoiceId);
          this.invoicePreviewData.encounterClaimsDto = this.invoiceEncounterClaimsList as any;
          this.invoicePreviewData.invoiceTotal = selectedInvoices.invoiceTotal;
          this.invoicePreviewData.installmentsCount = selectedInvoices.installmentsCount;
          this.invoicePreviewData.installmentsAmount = selectedInvoices.installmentsAmount;
          this.invoicePreviewData.transmissionChargesCount = selectedInvoices.transmissionChargesCount;
          this.invoicePreviewData.transmissionCharges = selectedInvoices.transmissionCharges;
          this.invoicePreviewData.reActivatedDevicesCount = selectedInvoices.reActivatedDevicesCount;
          this.invoicePreviewData.reActivationCharges = selectedInvoices.reActivationCharges;
          this.invoicePreviewData.shipmentsCount = selectedInvoices.shipmentsCount;
          this.invoicePreviewData.shipmentsAmount = selectedInvoices.shipmentsAmount;

          this.invoicePreviewData.fixedMonthlyCharge = selectedInvoices.fixedMonthlyCharge;
          this.GenerateWidgetsData();
          this.loadingInvoicesDetails = false;
          // this.filterPdfData();
        },
        (err: HttpResError) => {
          this.loadingInvoicesDetails = false;
          this.toaster.error(err.error || err.error, err.message);
        }
      );
  }
  GenerateWidgetsData() {
    this.invoicePreviewData?.encounterClaimsDto?.forEach((element) => {
      if (!element.category) {
        element.category = 'Others';
      }
    });
    this.displayInvoiceObj = this.filterData.groupByProp(this.invoicePreviewData?.encounterClaimsDto, 'category') as InvoicePreviewFilteredDto[];
    this.displayInvoiceObj?.forEach(element => {
      let amount = 0;
      element.value.forEach(x => {
        amount = (x.facilityPaymentAmount * x.units) + amount;
      });
      element.moduleTotal = amount;
    });
    this.displayInvoiceObj1 = this.filterData.groupByProp(this.invoicePreviewData?.encounterClaimsDto, 'cptCode') as InvoicePreviewFilteredDto[];
    this.displayInvoiceObj1?.forEach(element => {
      let amount = 0;
      element.value?.forEach(x => {
        amount = (x.facilityPaymentAmount * x.units) + amount;
        element['unitPrice'] = x.facilityPaymentAmount;
      });
      element.moduleTotal = amount;
    });
    if(this.selectedInvoiceId){
      const invoice = this.invoicesList.find((invoice: InvoiceForListDto) => invoice.id == this.selectedInvoiceId)
         this.monthId = invoice.monthId;
    }
  }
  getInvoiceDetailByDevice(){
    // var slt = this.invoicesList.filter((invoice:InvoiceForListDto) => invoice.id === this.selectedInvoiceId);
    //     this.selectedInvoice = slt[0];
    this.invoiceDetailByDeviceDto.month = this.monthId;
    this.invoiceDetailByDeviceDto.year = this.yearId;
    if(this.invoiceDetailByDeviceDto.month && this.invoiceDetailByDeviceDto.year){
      if(this.facilityId == 0){
        const selectedInv = this.invoicesList.filter((inv: InvoiceForListDto) =>   inv.id == this.selectedInvoiceId);
        this.invoiceDetailByDeviceDto.facilityId = selectedInv[0].facilityId;
      }else{
        this.invoiceDetailByDeviceDto.facilityId = this.facilityId;
      }
      this.billingService.getInvoiceDetailByDevice(this.invoiceDetailByDeviceDto).subscribe((res: any)=> {
        this.invoiceDetailsList = res;
        this.invoiceDetailsByDevice.show();
      },(err:HttpResError) => {
        this.toaster.error(err.error);
      })
    }
    }
    getInvoiceDetailByAccessory(){
      this.isLoadingAccessoriesList = true;
      this.invoiceDetailByDeviceDto.month = this.monthId;
      this.invoiceDetailByDeviceDto.year = this.yearId;
      if(this.invoiceDetailByDeviceDto.month && this.invoiceDetailByDeviceDto.year){
        if(this.facilityId == 0){
          const selectedInv = this.invoicesList.filter((inv: InvoiceForListDto) =>   inv.id == this.selectedInvoiceId);
          this.invoiceDetailByDeviceDto.facilityId = selectedInv[0].facilityId;
        }else{
          this.invoiceDetailByDeviceDto.facilityId = this.facilityId;
        }
        this.billingService.getAccessoryChargesByInvoice(this.invoiceDetailByDeviceDto).subscribe((res: any)=> {
          this.accessoryInvoiceList = res;
          this.isLoadingAccessoriesList = false;
          this.inventoryCountModal.show();
        },(err:HttpResError) => {
          this.toaster.error(err.error);
          this.isLoadingAccessoriesList = false;
        })
      }
      }
      getMonthlyServicesByInvoiceIdPreview(){
        this.isLoadingMonthlyServicesList = true;
        this.invoiceDetailByDeviceDto.month = this.monthId;
        this.invoiceDetailByDeviceDto.year = this.yearId;
        if(this.invoiceDetailByDeviceDto.month && this.invoiceDetailByDeviceDto.year){
          if(this.facilityId == 0){
            const selectedInv = this.invoicesList.filter((inv: InvoiceForListDto) =>   inv.id == this.selectedInvoiceId);
            this.invoiceDetailByDeviceDto.facilityId = selectedInv[0].facilityId;
          }else{
            this.invoiceDetailByDeviceDto.facilityId = this.facilityId;
          }
          this.billingService.getMonthlyServicesByInvoiceIdPreview(this.invoiceDetailByDeviceDto).subscribe((res: any)=> {
            console.log(res)
            this.monthlyServicesInvoiceList = res;
            this.isLoadingMonthlyServicesList = false;
          },(err:HttpResError) => {
            this.toaster.error(err.error);
            this.isLoadingMonthlyServicesList = false;
          })
        }
        }
        getMonthlyServicesByInvoiceId(){
          this.isLoadingMonthlyServicesList = true;
          this.billingService.getMonthlyServicesByInvoiceId(this.invoiceId).subscribe((res: any) =>  {
            this.monthlyServicesInvoiceList = res;
            this.isLoadingMonthlyServicesList = false;
          }, (err: HttpResError) => {
            this.toaster.error(err.error)
          this.isLoadingMonthlyServicesList = false;
          })
        }
    getAICallLogs(){
      this.loadingCallLogs = true;
      const { startDate, endDate } = this.getStartAndEndDateByMonthAndYear(this.monthId, this.yearId);
      this.aiCallLogsDto.from = moment(startDate).format('MM/DD/YYYY');
      this.aiCallLogsDto.to = moment(endDate).format('MM/DD/YYYY');
      this.aiCallLogsDto.facilityId = this.facilityId;
      this.curaAiService.getAICallLogs(this.aiCallLogsDto).subscribe((res: any) => {
        this.aiCallLogsList = res;
        if(this.aiCallLogsList.length){
          this.aiCallLogsList.forEach((call) => {
           call.logDate = moment.utc(call.logDate).tz(TwoCTimezoneService?.currentTimeZone || moment.tz.guess()).format("MM/DD/YYYY hh:mm A")
          })
          this.aiCallLogsList = this.aiCallLogsList.sort((a, b) => {
            const dateA = new Date(a.logDate).getTime();
            const dateB = new Date(b.logDate).getTime();
            return dateB - dateA;
          });
        }
        this.loadingCallLogs = false;
      }, (err: HttpResError) => { 
        this.toaster.error(err.error);
        this.loadingCallLogs = false;
      })
    }
    getStartAndEndDateByMonthAndYear(monthId: number, yearId: number): { startDate: Date, endDate: Date } {
      // Ensure monthId is within valid range (1 to 12)
      if (monthId < 1 || monthId > 12) {
        throw new Error('Invalid monthId. MonthId should be between 1 and 12.');
      }
      // Create a new date object with the given year and month
      const startDate = new Date(yearId, monthId - 1, 1); // Note: Month is zero-based in JavaScript Date object
      const endDate = new Date(yearId, monthId, 0); // Get the last day of the month
      return { startDate, endDate };
    }
    clearCuraLogsList(){
      this.aiCallLogsList = new Array<AICallLogsList>();
    }
    sumOfUnits(value){
      let sum = 0;
      for (let item of value) {
        sum += item.units;
      }
      return sum;
    }
    getCognitiveSessionById(){
      this.isLoadingCognitiveSessions = true;
      this.ncaService.getCognitiveSessionByFacilityId(this.facilityId, this.monthId, this.yearId).subscribe((res: any) => {
        this.cognitiveSessionList = res;
        this.isLoadingCognitiveSessions = false;
      }, (err: HttpResError) => {
        this.toaster.error(err.error);
        this.isLoadingCognitiveSessions = false;
      })
      }
}
