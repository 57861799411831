import { Injectable } from '@angular/core';
import { AppUserAuth } from '../model/security/app-user.auth';
import { UserType } from '../Enums/UserType.enum';
import { environment } from 'src/environments/environment';
import { SecurityService } from './security/security.service';

declare var $zoho;

@Injectable({
  providedIn: 'root'
})
export class SalesIqService {
  private isEnabled = true;
  private shouldLoadWidget = true;
  constructor() { }
  loadsSalesIqWidget(securityObject: AppUserAuth): void {
    if (this.shouldWidgetLoad()) {
      return;
    if (environment.production == true) {
    }
    // setInterval(() => {
    //   const name = $zoho.salesiq.visitor.name();
    //   const email = $zoho.salesiq.visitor.email();
    //   const id = $zoho.salesiq.visitor.id();
    // }, 4000);
    const widgetElement = document.querySelector('[data-id="zsalesiq"]') as HTMLElement;

    // // Check if the widget element exists before attempting to remove it
    // if (widgetElement) {
    //   widgetElement.style.display = 'block';
    //   return
    // }
    if (widgetElement && $zoho) {
      $zoho.salesiq.reset();
      // $zoho.salesiq.floatbutton.visible("show");
      // $zoho.salesiq.floatwindow.visible("show");
      this.AttachUserInfo(securityObject)
      return;
    }
    // Create a script element
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'zsiqchat'; // Add the same ID as in your script
    script.innerHTML = `
      var $zoho = $zoho || {};
      $zoho.salesiq = $zoho.salesiq || { widgetcode: "siqbd78b4aecdfa07e036a365d7a7be3745dd64067be70c266171693da64c1143c24b9d20ab4b436ec27a48096af2809bf1", values: {}, ready: function () { } };
      var d = document;
      s = d.createElement("script");
      s.type = "text/javascript";
      s.id = "zsiqscript";
      s.defer = true;
      s.src = "https://salesiq.zohopublic.com/widget";
      t = d.getElementsByTagName("script")[0];
      t.parentNode.insertBefore(s, t);

    `;

    // Append the script element to the body
    document.body.appendChild(script);
    $zoho.salesiq.ready= () =>
    {
      this.AttachUserInfo(securityObject)
    }
  }
  }

  RemoveSalesIqWidget () {
    const scriptElement = document.querySelector('[data-id="zsalesiq"]') as HTMLElement;
    if (scriptElement) {
      scriptElement.parentNode.removeChild(scriptElement);
    }
  }
  AttachUserInfo(securityObject: AppUserAuth) {
    $zoho.salesiq.visitor.name(securityObject.fullName);
    $zoho.salesiq.visitor.email(securityObject.email || securityObject.userName);
    $zoho.salesiq.visitor.id(securityObject.appUserId);

    $zoho.salesiq.visitor.info({
      "name" : securityObject.fullName,
      "email" : securityObject.email,
      "appUserId" : securityObject.appUserId,
      "id" : securityObject.appUserId,
      "userName" : securityObject.userName
    });
  }
  enableWidget(): void {
    this.isEnabled = true;
    const securityObjectstring = localStorage.getItem('securityData');
    var securityObject = JSON.parse(securityObjectstring);
    this.loadsSalesIqWidget(securityObject);
  }

  disableWidget(): void {
    this.isEnabled = false;
    this.shouldLoadWidget = false;
    this.RemoveSalesIqWidget();
  }
  shouldWidgetLoad(): boolean {
    return this.isEnabled && this.shouldLoadWidget;
  }
}
