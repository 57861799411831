<div mdbModal #monthlyReview="mdbModal"  [config]="{backdrop: false, ignoreBackdropClick: true}" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="mymonthlyReviewLabel" aria-hidden="true" (close)="resetEncountersList()">
  <div class="modal-dialog modal-dialog-scrollable modal-xl" role="document">
    <div class="modal-content ">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="monthlyReview.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">HES Monthly Review</h4>
      </div>
      <div class="modal-body" *ngIf="monthlyReview.isShown" #mrHtmlCOntent>
        <ng-container>
          <p>Total Time: <mdb-badge success="true">{{completedTime}}</mdb-badge></p>
          <div class="panel z-depth-0 border">
            <div class="panel-header border-bottom">
              <h3>Notes</h3>
            </div>
            <div class="panel-body">
              <div appCcmLoader>
                <div class="loader-wrap" *ngIf="IsEncounterLoading">
                  <div class="text-dynamic-2c la-ball-pulse ccm-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
               <ng-container *ngIf="!IsEncounterLoading">
                <div *ngFor="let item of hesEncountersList" class="border p-2 mb-2">
                  <p class="mb-3"><strong class="d-inline-block" style="width: 78px;">HES Type:</strong>
                    {{hesTypeEnum[item.hesType]}}</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <p class="mr-2 mb-1"><span class="text-unique-color">Created By :</span> <strong
                        class="text-dynamic-2c">
                        {{item.clinicalCoordinatorName}}</strong></p>

                    <p class="font-400 mb-1"><span class="text-unique-color"><strong>Date:</strong></span>
                      {{item.encounterDate| dateFormatPipe}}</p>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                    <p class="text-unique-color mb-1"><strong>Start Time:</strong>
                      {{'6/14/19 ' + item.startTime | date: 'shortTime' }}</p>
                    <p class="text-unique-color mb-1 "><strong>End Time:</strong>
                      {{'6/14/19 ' + item.endTime | date: 'shortTime' }}</p>
                    <p class="text-unique-color mb-1"><strong>Duration:</strong> {{item.duration}}</p>
                  </div>
                  <p class="mb-1"><strong class="d-inline-block" style="width: 100px;">Note: </strong> {{item.note}}</p>
                  <div class="d-flex w-100 justify-content-between align-items-center">
                    <div>
                      <small *ngIf="item.qualityCheckedByName" class=" mr-3"><strong class="mr-2">Quality Checked By:</strong> {{item.qualityCheckedByName}}</small>
                      <small *ngIf="item.qualityCheckedDate"><strong class="mr-2">Quality Checked Date:</strong> {{item.qualityCheckedDate | dateFormatPipe}}</small>
                    </div>
                    <ng-container  *ngIf="!this.isPrDashboardQC" >
                    <button [disabled]="isCheckingBhiQualityCheck" type="button" *ngIf="!item?.qualityChecked" title="Quality Check"
                        class="waves-light btn btn-danger btn-sm ml-0" aria-label="Close"
                        (click)="setHesQualityChecked(item)" mdbWavesEffect> 
                        <i class="las la-times fa-lg"></i> Quality Checked
                    </button>
                    <button [disabled]="item?.qualityChecked" type="button" *ngIf="item?.qualityChecked" title="Quality Check"
                      size="sm" mdbBtn class="btn btn-dynamic-2c btn-sm waves-light ml-0" aria-label="Close" mdbWavesEffect> 
                      <i class="las la-check fa-lg"></i> Quality Checked
                    </button>
                  </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="modal-footer">
          <button [disabled]="!hesEncountersList?.length" type="button" title="copy" (click)="copyHesData()"
            class="waves-light btn btn-dynamic-2c btn-sm" aria-label="Close" mdbWavesEffect> <i
              class="las la-copy fa-lg"></i> </button>
          <button type="button" class="waves-light btn btn-sm btn-dynamic-secondary-2c" aria-label="Close"
            (click)="monthlyReview.hide()" mdbWavesEffect>Close</button>
      </div>
    </div>
  </div>
</div>