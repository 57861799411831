<div mdbModal #previousCommunicationModal="mdbModal" [config]="{ignoreBackdropClick: true, backdrop: false}" (close)="resetMessageLists()" class="modal fade"
  tabindex="-1" role="dialog" aria-labelledby="myroleManagementModalLabel" aria-hidden="true" >
  <div class="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="previousCommunicationModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">Message Selection</h4>
      </div>

      <div class="modal-body">  
        <div class="col-12 py-3 text-center d-flex justify-content-center align-items-center h-100" *ngIf="isLoadingUnusedMessages">
          <div class="loader"></div> 
        </div>
        <!-- <p *ngIf="!previousMonthMessages?.length && currentMonthMessages?.length" >No Data Found</p> -->
        <div *ngIf="previousMonthMessages?.length">
          <h6 style="font-weight: 500;">Previous Months</h6>
          <ngx-datatable class="material" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="40"
            [externalPaging]="false" [scrollbarV]="false" [scrollbarH]="false" [rows]="previousMonthMessages"
            [limit]="5">
            <ngx-datatable-column name="#" [width]=50>
              <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                {{rowIndex + 1}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Date">
              <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                {{ row?.timeStamp | dateFormatPipe }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Message">
              <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                <span [title]="row?.message">
                  {{row?.message ? row?.message.substring(0, 20) + (row?.message.length > 20 ? '...' : '') : ''}}
                </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Type">
              <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                {{communicationDirectionEnum[row?.direction]}}
                <i *ngIf="row.ringCentralTelephonySessionId" class="las la-phone" ></i>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Archive">
              <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                <div class="custom-control custom-checkbox">
                  <input (change)="onPreviousArchiveCheckboxChange(row, previousArchiveCheckbox.checked)" [(ngModel)]="row.isArchive" [name]="'pm-1'+ row.id" type="checkbox" class="custom-control-input" mdbInput [id]="'pm-11'+row.id" #previousArchiveCheckbox>
                  <label class="custom-control-label" [for]="'pm-11'+row.id"></label>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Encounter">
              <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                <div class="custom-control custom-checkbox form-check-inline">
                  <input (change)="onPreviousEncounterCheckboxChange(row, previousEncounterCheckbox.checked)" [(ngModel)]="row.isEncounter" [name]="'pm-2' + row.id" type="checkbox" class="custom-control-input" mdbInput [id]="'pm-12'+row.id" #previousEncounterCheckbox>
                  <label class="custom-control-label" [for]="'pm-12'+row.id"></label>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
            <!-- <table class="table table-bordered table-hover mb-0">
                <thead class="bg-dynamic-2c text-white">
                    <tr>
                        <th>Date</th>
                        <th>Message</th>
                        <th>Type</th>
                        <th>Archive</th>
                        <th>Encounter</th>
                    </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of previousMonthMessages" >
                    <td>{{item?.timeStamp | dateFormatPipe}}</td>
                    <td>{{item?.message ? item?.message.substring(0, 20) + (item?.message.length > 20 ? '...' : '') : ''}}</td>
                    <td>{{communicationDirectionEnum[item?.direction]}}</td>
                    <td>
                      <div class="custom-control custom-checkbox">
                        <input (change)="onPreviousArchiveCheckboxChange(item, previousArchiveCheckbox.checked)" [(ngModel)]="item.isArchive" [name]="'pm-1'+ item.id" type="checkbox" class="custom-control-input" mdbInput [id]="'pm-11'+item.id" #previousArchiveCheckbox>
                        <label class="custom-control-label" [for]="'pm-11'+item.id"></label>
                      </div>
                    </td>
                    <td>
                      <div class="custom-control custom-checkbox form-check-inline">
                        <input (change)="onPreviousEncounterCheckboxChange(item, previousEncounterCheckbox.checked)" [(ngModel)]="item.isEncounter" [name]="'pm-2' + item.id" type="checkbox" class="custom-control-input" mdbInput [id]="'pm-12'+item.id" #previousEncounterCheckbox>
                        <label class="custom-control-label" [for]="'pm-12'+item.id"></label>
                      </div>
                    </td>
                  </tr>
                </tbody>
            </table> -->
          </div>
          <div *ngIf="currentMonthMessages?.length">
            <h6 style="font-weight: 500;">Current Month</h6>
            <div class="table-responsive">
              <ngx-datatable class="material" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="40"
                [externalPaging]="false" [scrollbarV]="false" [scrollbarH]="false" [rows]="currentMonthMessages"
                [limit]="5">
                <ngx-datatable-column name="#" [width]=50>
                  <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                    {{rowIndex + 1}}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Date">
                  <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                    {{ row?.timeStamp | dateFormatPipe }}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Message">
                  <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                    <span [title]="row?.message">
                      {{row?.message ? row?.message.substring(0, 20) + (row?.message.length > 20 ? '...' : '') : ''}}
                    </span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Type">
                  <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                    {{communicationDirectionEnum[row?.direction]}}
                    <i *ngIf="row.ringCentralTelephonySessionId" class="las la-phone" ></i>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Archive">
                  <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                    <div class="custom-control custom-checkbox">
                      <input (change)="onPreviousArchiveCheckboxChange(row, previousArchiveCheckbox.checked)" [(ngModel)]="row.isArchive" [name]="'pm-1'+ row.id" type="checkbox" class="custom-control-input" mdbInput [id]="'pm-11'+row.id" #previousArchiveCheckbox>
                      <label class="custom-control-label" [for]="'pm-11'+row.id"></label>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Encounter">
                  <ng-template ngx-datatable-cell-template let-value="value" let-row="row" let-rowIndex="rowIndex">
                    <div class="custom-control custom-checkbox form-check-inline">
                      <input (change)="onPreviousEncounterCheckboxChange(row, previousEncounterCheckbox.checked)" [(ngModel)]="row.isEncounter" [name]="'pm-2' + row.id" type="checkbox" class="custom-control-input" mdbInput [id]="'pm-12'+row.id" #previousEncounterCheckbox>
                      <label class="custom-control-label" [for]="'pm-12'+row.id"></label>
                    </div>
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>
              <!-- <table class="table table-bordered table-hover mb-0">
                <thead class="bg-dynamic-2c text-white">
                    <tr>
                        <th>Date</th>
                        <th>Message</th>
                        <th>Type</th>
                        <th>Archive</th>
                        <th>Encounter</th>
                    </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of currentMonthMessages">
                    <td>{{item?.timeStamp | dateFormatPipe}}</td>
                    <td>{{item?.message ? item?.message.substring(0, 20) + (item?.message.length > 20 ? '...' : '') : ''}}</td>
                    <td>{{communicationDirectionEnum[item?.direction]}}</td>
                    <td>
                      <div class="custom-control custom-checkbox">
                        <input (change)="onPreviousArchiveCheckboxChange(item, currentArchiveCheckbox.checked)" [(ngModel)]="item.isArchive" [name]="'pm-1'+ item.id" type="checkbox" class="custom-control-input" mdbInput [id]="'pm-11'+item.id" #currentArchiveCheckbox>
                        <label class="custom-control-label" [for]="'pm-11'+item.id"></label>
                      </div>
                    </td>
                    <td>
                      <div class="custom-control custom-checkbox form-check-inline">
                        <input (change)="onPreviousEncounterCheckboxChange(item, currentEncounterCheckbox.checked)" [(ngModel)]="item.isEncounter" [name]="'pm-2' + item.id" type="checkbox" class="custom-control-input" mdbInput [id]="'pm-12'+item.id" #currentEncounterCheckbox>
                        <label class="custom-control-label" [for]="'pm-12'+item.id"></label>
                      </div>
                    </td>
                  </tr>
                </tbody>
            </table> -->
          </div>
          </div>
         
      </div>
      <div class="modal-footer d-block ">
        <div class="justify-content-end d-flex align-items-center"> 
          <button (click)="previousCommunicationModal.hide()" type="button" class="waves-light btn-sm btn btn-dynamic-secondary-2c" aria-label="Close">Cancel</button>
          <button [disabled]="isMarkingAsArchived || isLoadingUnusedMessages" type="button"class="relative waves-light btn btn-sm btn-dynamic-2c" mdbWavesEffect (click)="proceedToMarkAsArchived();proceedToMarkAsEncounter()">
            <span *ngIf="isMarkingAsArchived" class="spinner-border spinner-border-sm" role="status" 
              aria-hidden="true"></span>Proceed</button>
        </div>
      </div>
    </div>
  </div>
</div>
