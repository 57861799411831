import { Component, OnInit, ViewChild } from '@angular/core';
import moment from 'moment';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { HESTypeEnum } from 'src/app/Enums/hes.enum';
import { SubSink } from 'src/app/SubSink';
import { PatientsService } from 'src/app/core/Patient/patients.service';
import { AppDataService } from 'src/app/core/app-data.service';
import { AppUiService } from 'src/app/core/app-ui.service';
import { HesService } from 'src/app/core/hes.service';
import { SecurityService } from 'src/app/core/security/security.service';
import { HESQualityCheckMOdalDto, HesEncounterDto, HesEncounterListDto } from 'src/app/model/Hes/hes.model';
import { PatientDto } from 'src/app/model/Patient/patient.model';
import { HttpResError } from 'src/app/model/common/http-response-error';
import { AppUserAuth } from 'src/app/model/security/app-user.auth';

@Component({
  selector: 'app-hes-quality-check',
  templateUrl: './hes-quality-check.component.html',
  styleUrls: ['./hes-quality-check.component.scss']
})
export class HesQualityCheckComponent implements OnInit {

    @ViewChild("monthlyReview") monthlyReview: ModalDirective;
    currentUser: AppUserAuth = null;
    private subs = new SubSink();
    isPrDashboardQC= false;
    selectedPatient = new PatientDto();
    IsEncounterLoading= false;
    hesEncountersList= new Array<HesEncounterListDto>();
    monthId: number;
    yearNum: number;
    completedTime: string;
    hesTypeEnum = HESTypeEnum;
    isCheckingHesQualityCheck= false;
    constructor(private appUi: AppUiService, private patientService: PatientsService, private appData: AppDataService,
      private toaster: ToastService, private securityService: SecurityService, private hesService: HesService) { }
  
    ngOnInit(): void {
      if (
        this.securityService.securityObject &&
        this.securityService.securityObject.isAuthenticated
      ) {
        this.currentUser = this.securityService.securityObject;
      }
      this.monthId = this.appData.currentMonth;
      this.yearNum = this.appData.currentYear;
      this.appUi.openHESQualityCheckModal.subscribe((res: HESQualityCheckMOdalDto) => {
        this.monthlyReview.config = { backdrop: false, ignoreBackdropClick: false };
        this.isPrDashboardQC = res.isPrDashboard;
        this.getPatientById(res.patientId);
        setTimeout(() => {
          this.getHesEncountersByPatientId(res.patientId);
        }, 1000);
        this.monthlyReview.show();
      });
    }
    async getPatientById(patientId){
      this.patientService.getPatientDetail(patientId).subscribe((res: any)=>{
        this.selectedPatient =  res;
      }, (err: HttpResError) => {
        this.toaster.error(err.error);
      })
    }
    getHesEncountersByPatientId(patientId){
      this.IsEncounterLoading = true;
      this.hesEncountersList= new Array<HesEncounterListDto>();
      this.hesService.getHesEncountersByPatientId(patientId, this.monthId, this.yearNum).subscribe((res: any) => {
        this.completedTime = res?.hesTimeCompleted;
        this.hesEncountersList = res?.hesEncountersList;
        this.IsEncounterLoading = false;
      }, (err: HttpResError) => {
        this.IsEncounterLoading = false;
        this.toaster.error(err.error);
      })
    }
    resetEncountersList(){
      this.hesEncountersList= new Array<HesEncounterListDto>();
      this.completedTime = '';
    }
    setHesQualityChecked(encounter: HesEncounterDto){
      this.isCheckingHesQualityCheck = true;
      this.subs.sink = this.hesService.setHesQualityChecked(encounter.id, true)
        .subscribe(
          (res: any) => {
            var qualityCheckedEncounter = this.hesEncountersList.find((en: HesEncounterListDto) => en.id == encounter.id);
            qualityCheckedEncounter.qualityChecked = true;
            qualityCheckedEncounter.qualityCheckedByName = this.currentUser.fullName
            qualityCheckedEncounter.qualityCheckedDate = moment().format("MM/DD/YYYY hh:mm A");
  
            this.updateHesQualityCheckStatus();
            this.isCheckingHesQualityCheck = false;
          },
          (err: HttpResError) => {
            this.isCheckingHesQualityCheck = false;
            this.toaster.error(err.error, err.message);
          }
        );
    }
    updateHesQualityCheckStatus(){
      if(this.hesEncountersList.length){
        const notQualityChecked = this.hesEncountersList.filter(encounter => encounter.qualityChecked == false);
        if(!notQualityChecked.length){
          this.patientService.refreshQualityCheckStatusOfHES.next(0);
        }else{
          const qualityChecked = this.hesEncountersList.filter((encounter) => encounter.qualityChecked == true);
          if(qualityChecked.length == this.hesEncountersList.length){
            this.patientService.refreshQualityCheckStatusOfHES.next(0);
          }else{
            this.patientService.refreshQualityCheckStatusOfHES.next(2);
          }
        }
      }
    }
    copyHesData() {
      let copyStr = "";
      copyStr += `HES Time : ${this.completedTime} \n`;
      copyStr += `Notes \n`;
      this.hesEncountersList.forEach((log) => {
        copyStr += `Service Type : ${this.hesTypeEnum[log['hesType']]}, Created By : ${
          log['clinicalCoordinatorName']
        } , Date : ${moment(log.encounterDate).format('MMM DD, YY, hh:mm A')} , Start Time: ${log.startTime}, End Time : ${
          log.endTime
        } , Duration : ${log.duration} \n`;
        copyStr += `Note: ${log.note} \n`;
      });
      const textArea = document.createElement("textarea");
      textArea.value = copyStr;
      document.body.appendChild(textArea);
      textArea.select();
      textArea.setSelectionRange(0, 99999);
      document.execCommand("copy");
      textArea.remove();
      this.toaster.success("Content Copied");
    }
}
